import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  pageTitle: null as string | null,
  pageDescription: null as string | null,
  pageKeywords: null as string | null,
  pageOgType: null as string | null,
  pageOgBannerImage: null as string | null,
  pageIcon: null as string | null,
  canonicalURL: null as string | null,
  jsonItem: null as any,
};

const mainSlice = createSlice({
  name: 'seo',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setPageTitle(state, action) {
      return {
        ...state,
        pageTitle: action.payload,
      };
    },
    setPageDescription(state, action) {
      return {
        ...state,
        pageDescription: action.payload,
      };
    },
    setPageKeywords(state, action) {
      return {
        ...state,
        pageKeywords: action.payload,
      };
    },
    setPageOgType(state, action) {
      return {
        ...state,
        pageOgType: action.payload,
      };
    },
    setPageOgBannerImage(state, action) {
      return {
        ...state,
        pageOgBannerImage: action.payload,
      };
    },
    setPageIcon(state, action) {
      return {
        ...state,
        pageIcon: action.payload,
      };
    },
    setJsonItem(state, action) {
      return {
        ...state,
        jsonItem: action.payload,
      };
    },
    setCanonicalURL(state, action) {
      return {
        ...state,
        canonicalURL: action.payload,
      };
    },
  },
});

export const {
  setPageTitle,
  setPageDescription,
  setPageKeywords,
  setPageOgType,
  setPageOgBannerImage,
  setPageIcon,
  setJsonItem,
  setCanonicalURL,
} = mainSlice.actions;

export default mainSlice.reducer;
