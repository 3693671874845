import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Divider,
  Popover,
  Typography,
  IconButton,
} from '@mui/material';
import { useUser } from '../../../hooks/useUser';

import {
  toggleGlobalLoaderQueue,
  toggleSignIn,
  toggleAddress,
} from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import { getAddressesRequest } from '../../../redux/legacy/reducers/ducks/legacy/ProfileDuck';
import OrderAddressInput from '../OrderAddressInput';
import { routeNames } from '../../../util/Constants';
import Notification from '../../layout/Notification';
import errorMessages from '../../../constants/errorMessages';
import useDesktopCheck from '../../../util/hooks/UseDesktopCheck';
import CustomSwipeableDrawer from '../../layout/CustomSwipeableDrawer';
import addressMapIcon from '../../../assets/images/addressMapIcon.svg';
import SignInForm from '../SignInForm';
import ForgotPassword from '../../layout/ForgotPassword';
import { setOrderType } from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';

function OrderAddressFilter({ open, onClose, anchorEl, isModal = false }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDesktop = useDesktopCheck();
  const isHome = routeNames.home === location.pathname;
  const { userData } = useUser();
  const theme = useTheme();

  const {
    type,
    typeOptions,
    order,
    time,
    address,
    selectedRestaurant,
    getAddressesResponse,
    addressCoverageStatusResponse,
  } = useSelector(
    ({
      order: orderObj,
      restaurants: restaurantsObj,
      profile,
      address: selectedAddress,
    }) => ({
      order: orderObj,
      type: orderObj.type,
      typeOptions: orderObj.typeOptions,
      time: orderObj.time,
      address: selectedAddress.selectedAddress,
      getAddressesResponse: profile.getAddressesResponse,
      selectedRestaurant: restaurantsObj.selectedRestaurant,
      addressCoverageStatusResponse: profile?.getAddressCoverageStatusResponse,
    })
  );

  const toggleSignInModal = () => dispatch(toggleSignIn());

  const [signIn, setSignIn] = useState(true);
  const isGuestUser = useMemo(() => userData === null, [userData]);

  useEffect(() => {
    if (isEmpty(getAddressesResponse) && userData) {
      dispatch(getAddressesRequest());
    }
  }, [dispatch, getAddressesResponse, userData]);

  useEffect(() => {
    if (addressCoverageStatusResponse?.response) {
      if (!addressCoverageStatusResponse.response.covered) {
        Notification.error(errorMessages.ADDRESS_OUTSIDE_DELIVERY_COVERAGE, {
          toastId: 'deliveryOutsideCoverage',
        });

        toggleGlobalLoaderQueue({
          name: 'getAddressCoverageStatus',
          status: false,
        });

        return;
      }

      if (type && time && selectedRestaurant) {
        if (type.value === 'delivery' && !address) {
          Notification.error(errorMessages.ADDRESS_NOT_FOUND, {
            toastId: 'AddressLost',
          });

          return;
        }
      }
    } else if (addressCoverageStatusResponse?.error) {
      if (type.value === 'delivery' && !address) {
        Notification.error(errorMessages.ADDRESS_NOT_FOUND, {
          toastId: 'AddressLost',
        });
      }
    }

    toggleGlobalLoaderQueue({
      name: 'getAddressCoverageStatus',
      status: false,
    });
  }, [
    addressCoverageStatusResponse,
    address,
    type,
    time,
    order,
    selectedRestaurant,
    dispatch,
  ]);

  const backToTakeout = () => {
    const newType = typeOptions.filter((t) => t.value === 'takeout');
    if (newType.length === 1) {
      dispatch(toggleAddress(false));
      dispatch(setOrderType(newType[0]));
    }
  };

  const onSubmit = (ev) => {
    if (ev) {
      if (onClose && address) {
        onClose();
      }
    }
  };

  const onCancel = (ev) => {
    if (ev) {
      backToTakeout();
      if (onClose) {
        onClose();
      }
    }
  };

  const stopPropagation = (ev) => ev.stopPropagation();

  const AddressForm = (
    <form onSubmit={onSubmit} data-test-id="editLocationForm">
      <Box mb={3}>
        <OrderAddressInput onClose={onClose} />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent={isModal ? 'space-between' : 'flex-end'}
      >
        <Button
          size="large"
          variant="outlined"
          aria-label="btn-order-address-cancel"
          className="btn-noshadow btn-simple"
          type="button"
          startIcon={isModal && !isHome && <ArrowBackIcon />}
          onClick={isModal && !isHome ? backToTakeout : onClose}
          data-test-id="filter-cancel-result"
        >
          {isModal && !isHome ? 'Takeout' : 'Cancel'}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={onSubmit}
          aria-label="btn-order-address-update-results"
          data-test-id="filter-update-result"
        >
          Update
        </Button>
      </Stack>
    </form>
  );

  const renderDesktopPopover = () => (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      onClick={stopPropagation}
      data-test-id="order-address-filter"
    >
      <Box
        sx={{ width: 375, padding: 4 }}
        data-test-id="AddressFilterPopover"
        aria-hidden="true"
      >
        <Typography mb={2} variant="subtitle1">
          Enter an Address
        </Typography>
        <div data-test-id="OrderAddressFilter">{AddressForm}</div>
      </Box>
    </Popover>
  );

  const renderModal = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data-test-id="AddressFilterModal"
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'right',
          mr: 3,
          mt: 1,
        }}
      >
        <IconButton
          size="small"
          className="address-modal-close-icon"
          onClick={onCancel}
          data-test-id="closeButton"
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Box>
      <Box
        data-test-id="AddressFilterInner"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        }}
        onClick={stopPropagation}
        aria-hidden="true"
      >
        {isGuestUser && (
          <>
            <Box
              sx={{
                width: '100%',
                ml: 3,
                mr: 3,
              }}
            >
              {signIn ? (
                <SignInForm forgotPassword={() => setSignIn(false)} />
              ) : (
                <ForgotPassword
                  onClose={toggleSignInModal}
                  backToSignIn={() => setSignIn(true)}
                />
              )}
            </Box>
            <Divider
              orientation={isDesktop ? 'vertical' : 'horizontal'}
              flexItem
              sx={{
                m: 1,
                mb: isDesktop ? 0 : 4,
              }}
            />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            ml: 3,
            mr: 3,
            mb: 6,
          }}
        >
          <Box
            component="img"
            src={addressMapIcon}
            sx={{ width: 150, height: 125 }}
          />
          <Typography mb={2} variant="h6">
            Enter Delivery Address
          </Typography>
          <Box data-test-id="OrderAddressFilter" sx={{ mt: 3 }}>
            {AddressForm}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderMobile = () => (
    <CustomSwipeableDrawer
      open={open}
      onClose={onClose}
      title="Enter an Address"
    >
      <Box data-test-id="AddressFilterMobile">
        <Box data-test-id="OrderAddressFilter">{AddressForm}</Box>
      </Box>
    </CustomSwipeableDrawer>
  );

  if (isModal) {
    return renderModal();
  }

  if (isDesktop) {
    return renderDesktopPopover();
  }
  return renderMobile();
}

OrderAddressFilter.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

OrderAddressFilter.defaultProps = {
  open: false,
  onClose: () => null,
  anchorEl: null,
};

export default OrderAddressFilter;
