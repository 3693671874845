import _ from 'lodash';
import { orderStatuses } from './Constants';
import { getConfig } from '../AppConfig';

export const orderHistoryTabMap = {
  viewall: {
    id: 'viewall',
    label: 'View All',
    statusList: [],
  },
  inprogress: {
    id: 'inprogress',
    label: 'In Progress',
    statusList: ['open', 'confirmed', 'driving'],
  },
  completed: {
    id: 'completed',
    label: 'Completed',
    statusList: ['completed'],
  },
};

export const serializeItem = (obj) =>
  obj ? JSON.parse(JSON.stringify(obj)) : '';

export const formatAmount = (amount) =>
  (Math.round(amount * 100) / 100).toFixed(2);

export async function checkImage(cdn, placeholder) {
  try {
    await fetch(`https:${cdn}`, { method: 'HEAD' });
    return cdn;
  } catch (err) {
    return placeholder;
  }
}

export const getImageDescriptor = (profile, key, placeholder) => {
  const descriptor =
    profile && profile.images.find((image) => image.name === key);
  return descriptor ?? placeholder;
};

export const getImage = (profile, key, placeholder) => {
  const cdn = profile && profile.images.find((image) => image.name === key);
  return cdn ? cdn.url : placeholder;
};

export const getText = (profile, key, placeholder) => {
  const locale = profile && profile.locales.find((text) => text.name === key);
  return locale ? locale.text : placeholder;
};

export const filterProperties = (raw, attributes = []) => {
  if (_.isNil(raw) || !_.isObject(raw)) {
    return null;
  }

  return Object.keys(raw)
    .filter((key) => attributes.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: raw[key],
      }),
      {}
    );
};

export const createAddressString = (addressObject) => {
  const { address, city, zip, state } = addressObject;
  const validAddressInfo = [address, city, zip, state].filter(Boolean);
  return validAddressInfo.length > 0
    ? validAddressInfo.join(', ')
    : 'No Address';
};

const sortCartTreeItems = (a, b) => {
  const bothHaveSort = ![a.sort, b.sort].includes(undefined);
  const bothHaveSameSort = bothHaveSort && a.sort === b.sort;

  if (bothHaveSort && !bothHaveSameSort) {
    return a.sort > b.sort ? 1 : -1;
  }

  return a.text > b.text ? 1 : -1;
};

export const sortExtraItems = (items) => {
  const sortedItemsTree = items.sort(sortCartTreeItems);

  return sortedItemsTree;
};

export const normalizeInput = (value, previousValue) => {
  if (!value) return value;

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }

  return currentValue;
};

export const getOrderStatus = (status) => {
  if (
    status === orderStatuses.open ||
    status === orderStatuses.driving ||
    status === orderStatuses.confirmed
  ) {
    return orderStatuses.Progress;
  }
  if (status === orderStatuses.completed) {
    return orderStatuses.Completed;
  }
  return '';
};

export const getSelectedTab = (tabValue, selectedTabValue) => {
  if (selectedTabValue && tabValue) {
    if (tabValue[0] === selectedTabValue[0]) {
      return {
        selectedTab: true,
      };
    }
    if (
      tabValue.length === selectedTabValue.length &&
      selectedTabValue.every(
        (arrayValue, index) => arrayValue === tabValue[index]
      )
    ) {
      return {
        selectedTab: true,
      };
    }
  }
  return {
    selectedTab: false,
  };
};

/**
 * @returns {Array<{
 *   id: number; // menu item id
 *   items: Array<{ // the different selections for
 *     that item (see when a user add it twice with different data)
 *     sort: number; // the add order in the cart
 *     quantity: number; // amount of items
 *     special_instructions: string;
 *     answers: Array<{
 *       id: number; // must be unique across the cart
 *       answer: number; // can have duplicates since is the answer id
 *       amount: number; // amount of items
 *       parent: number; // parent answer instance id generated on
 *          the client to describe the hierarchy
 *     }>;
 *   }>;
 * }>;}
 */
export const formatCartForApi = (storedCart) => {
  const formattedStoreItems = storedCart.map((cartItem) => {
    const answers = cartItem.answers.map((answer, index) => ({
      ...answer,
      id: index + 1,
      answer: answer.id,
    }));

    const item = {
      sort: cartItem.sort,
      quantity: cartItem.quantity,
      menuItemId: cartItem.menuItemId,
      ...(cartItem.comment && { special_instructions: cartItem.comment }),
      answers: answers.map((answer) => {
        const childElement = answers.find(
          ({ child }) => answer.parent === child
        );

        return {
          id: answer.id,
          answer: answer.answer,
          ...(answer.specialInstructions && {
            special_instructions: answer.specialInstructions,
          }),
          ...(answer.quantity > 1 && { amount: answer.quantity }),
          ...(answer.parent && childElement && { parent: childElement.id }),
        };
      }),
    };

    if (!item.answers.length) {
      delete item.answers;
    }

    return item;
  });

  const grouppedItems = _.groupBy(formattedStoreItems, 'menuItemId');
  const cart = Object.values(grouppedItems).map((items) => {
    const formattedItems = items.map((item) => {
      const formattedItem = { ...item };
      delete formattedItem.menuItemId;
      return formattedItem;
    });

    return {
      id: items[0].menuItemId,
      items: formattedItems,
    };
  });

  return cart;
};

export const getRatings = (stars) =>
  ((parseFloat(String(stars)) / 100) * 5).toFixed(2);

export const isRestaurantApp = () => getConfig().appType === 'restaurant';

export const isConsumerApp = () => getConfig().appType === 'business';

export const isProviderApp = () => getConfig().appType === 'provider';

export const getAppType = () => getConfig().appType;
