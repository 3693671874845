import { lazy } from 'react';
import {
  faHome,
  faUser,
  faSpinner,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';

import { routeNames } from '../../../util/Constants';

const Home = lazy(() => import('../../page/Home/Home'));
const Restaurants = lazy(() => import('../../page/Restaurants/Restaurants'));
const AccountDetails = lazy(() =>
  import('../../page/AccountDetails/AccountDetails')
);

const menuLinks = [
  {
    icon: faHome,
    name: 'home',
    title: 'Home',
    component: Home,
    path: routeNames.home,
  },
  {
    icon: faUser,
    role: 'protected',
    name: 'accountDetails',
    title: 'Account Details',
    component: AccountDetails,
    path: routeNames.accountDetailsAccount,
  },
  {
    icon: faUtensils,
    name: 'restaurants',
    title: 'Restaurants',
    component: Restaurants,
    path: routeNames.restaurants,
  },
  {
    icon: faSpinner,
    role: 'protected',
    name: 'trackYourOrder',
    title: 'Track your Orders',
    component: AccountDetails,
    path: routeNames.accountDetailsOrderHistory,
  },
];

export default menuLinks;
