import { React, useCallback, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { notificationTypes } from '../../../util/Constants';

function Notification() {
  const [notificationCount, setNotificationCount] = useState(0);

  const showNotification = useCallback(
    (type, message, data) => {
      if (notificationCount > 0) return;

      toast[type](message, {
        ...data,
        onClose: () => {
          data?.onClose?.();
          setNotificationCount(0);
        },
      });

      setNotificationCount(1);
    },
    [notificationCount]
  );

  useEffect(() => {
    Notification.error = (...props) =>
      showNotification(notificationTypes.ERROR, ...props);

    Notification.success = (...props) =>
      showNotification(notificationTypes.SUCCESS, ...props);

    Notification.warning = (...props) =>
      showNotification(notificationTypes.WARNING, ...props);

    Notification.info = (...props) =>
      showNotification(notificationTypes.INFO, ...props);
  }, [notificationCount]);

  return (
    <ToastContainer
      autoClose={3000}
      draggable
      newestOnTop
      closeButton={false}
      theme="light"
    />
  );
}

export default Notification;
