/* eslint-disable import/prefer-default-export */
import { opensIn } from '../../util/Restaurant';

export const isRestaurantOpenAt = (
  when: Date,
  orderType: string,
  selectedRestaurant: any
): boolean => {
  if (!selectedRestaurant) {
    return false;
  }

  let delay = 0;
  if (orderType === 'delivery' && selectedRestaurant?.delays?.delivery) {
    delay = selectedRestaurant.delays.delivery;
  } else if (orderType === 'takeout' && selectedRestaurant?.delays?.takeout) {
    delay = selectedRestaurant.delays.takeout;
  }

  const willOpenIn = opensIn({
    date: when,
    timezone: selectedRestaurant?.timezone,
    schedules: selectedRestaurant?.schedules[orderType],
  });

  return willOpenIn <= delay;
};
