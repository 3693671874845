export const firstnameRequiredError = 'First name is required';
export const lastnameRequiredError = 'Last name is required';
export const emailRequiredError = 'Email is required';
export const emailInvalidError = 'Invalid email';
export const passwordRequiredError = 'Password is required';
export const passwordInvalidError = 'Password cannot contain any spaces';
export const passwordTooShortError = 'Password is too short';
export const passwordTooLongError = 'Password is too long';
export const passwordNotMatchError = 'Passwords do not match';
export const phoneRequiredError = 'Phone is required';
export const phoneInvalidError = 'Invalid phone';
export const addressRequiredError = 'Address is required';
export const cityRequiredError = 'City is required';
export const stateRequiredError = 'State is required';
export const zipRequiredError = 'ZIP code is required';

export default {
  firstnameRequired: firstnameRequiredError,
  lastnameRequired: lastnameRequiredError,
  emailRequired: emailRequiredError,
  emailInvalid: emailInvalidError,
  passwordRequired: passwordRequiredError,
  passwordInvalid: passwordInvalidError,
  passwordTooShort: passwordTooShortError,
  passwordTooLong: passwordTooLongError,
  phoneRequired: phoneRequiredError,
  phoneInvalid: phoneInvalidError,
  addressRequired: addressRequiredError,
  cityRequired: cityRequiredError,
  stateRequired: stateRequiredError,
  zipRequired: zipRequiredError,
  passwordNotMatch: passwordNotMatchError,
};
