import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import styles from './CartContent.style';
import useTotalCartPrice from '../../../util/hooks/UseCart';
import useClasses from '../../../hooks/legacy/useClasses';

function CartContent({ count, cartAction, variant }) {
  const { feesArray } = useTotalCartPrice();
  const classes = useClasses(styles);
  const price =
    (Array.isArray(feesArray) &&
      feesArray?.reduce((acc, fee) => {
        if (fee.id === 'cartSummaryLines' && count > 0) {
          // eslint-disable-next-line
          acc = fee.value.toFixed(2);
        }
        return acc;
      }, '0.00')) ||
    '0.00';

  return (
    <Button
      variant={variant}
      data-test-id="cart-modal-toggler"
      onClick={cartAction}
      aria-hidden="true"
    >
      <Stack direction="row" spacing={1}>
        <Badge badgeContent={price === 0.0 ? 0 : count} color="secondary">
          <ShoppingCartIcon />
        </Badge>
        <span className={classes.cartPrice}>${price}</span>
      </Stack>
    </Button>
  );
}

CartContent.propTypes = {
  count: PropTypes.number,
  cartAction: PropTypes.func,
  variant: PropTypes.string,
};

CartContent.defaultProps = {
  count: 0,
  cartAction: () => {},
  variant: 'outlined',
};

export default CartContent;
