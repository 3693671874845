import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Stack, MenuItem, Select } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { setOrderType } from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';

function TakeoutOrDeliverySelector({ typeVersion, disabled }) {
  const dispatch = useDispatch();
  const { type, typeOptions } = useSelector(({ order: orderObj }) => ({
    type: orderObj?.type,
    typeOptions: orderObj.typeOptions,
  }));

  const setType = (_, value) => {
    if (value) {
      const newType = typeOptions.filter((t) => t.value === value.props.value);
      if (newType.length === 1) {
        dispatch(setOrderType(newType[0]));
      }
    }
  };

  const setButtonType = (_, value) => {
    if (value) {
      const newType = typeOptions.filter((t) => t.value === value);
      if (newType.length === 1) {
        dispatch(setOrderType(newType[0]));
      }
    }
  };

  const selectedType = useMemo(() => type, [type]);

  if (!selectedType) {
    return <>&nbsp;</>;
  }

  if (typeVersion === 'Select') {
    return (
      <Select
        defaultValue={selectedType.value}
        value={selectedType.value}
        color="secondary"
        sx={{ height: 46 }}
        fullWidth
        IconComponent={KeyboardArrowDownIcon}
        name="type"
        onChange={setType}
        data-test-id="landing-page-toggle"
        disabled={disabled}
      >
        {typeOptions.map((option, index) => (
          <MenuItem
            aria-label={option.value}
            key={option.value}
            value={option.value}
            data-test-id={`landing-page-toggle-option-${index}`}
          >
            <Stack direction="row" spacing={{ sm: 0, lg: 1 }}>
              {option.label === 'Delivery' ? (
                <DirectionsCarIcon />
              ) : (
                <DirectionsWalkIcon />
              )}
              <Typography variant="body1">{option.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    );
  }

  if (typeVersion === 'ToggleGroup') {
    return (
      <ToggleButtonGroup
        defaultValue={selectedType.value}
        value={selectedType.value}
        sx={{ height: 46, backgroundColor: 'white' }}
        exclusive
        color="secondary"
        name="type"
        onChange={setButtonType}
        data-test-id="landing-page-toggle"
      >
        {typeOptions.map((option, index) => (
          <ToggleButton
            aria-label={option.value}
            key={option.value}
            value={option.value}
            data-test-id={`landing-page-toggle-option-${index}`}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }
}

TakeoutOrDeliverySelector.propTypes = {
  typeVersion: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TakeoutOrDeliverySelector.defaultProps = {
  disabled: false,
};

export default TakeoutOrDeliverySelector;
