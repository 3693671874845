import { shade } from 'polished';
import theme from '../Theme';

const styles = () => ({
  container: {
    padding: 40,
    position: 'relative',
    backgroundColor: '#fff',
    color: '#000',
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '16px',
    backgroundColor: '#fff !important',
  },
  tryAgainBtn: {
    color: '#49B782',
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    margin: 0,
    padding: '0px !important',
    outline: 'none',

    '&:hover': {
      color: shade(-0.1, '#49B782'),
    },
  },
  okBtn: {
    marginBottom: '36px',
  },

  closeIcon: {
    color: '#363636',
  },
  title: {
    marginBottom: '10px',
    fontWeight: theme.font.weight.extrabold,
    fontFamily: theme.font.family.main,
    fontSize: theme.font.size.xl2,
    lineHeight: theme.font.lineHeight.xl2,
  },
  subtitle: {
    marginBottom: 24,
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
  },
  backLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  backLink: {
    color: '#6C6C6C',
    textDecoration: 'underline',
    fontWeight: theme.font.weight.medium,
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
  },
});

export default styles;
