import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const plugFunction = () => null;

function CustomSwipeableDrawer({ open, onClose, title, children, ...rest }) {
  const stopPropagation = (ev) => ev.stopPropagation();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      disableSwipeToOpen
      onOpen={plugFunction}
      sx={{ borderRadius: 5 }}
      data-test-id={rest['data-test-id'] || 'swipeableDrawer'}
    >
      <Box data-test-id="swipe-div" onClick={stopPropagation} sx={{ p: 4 }}>
        <Stack spacing={2}>
          <Typography variant="h5">{title}</Typography>
          {children}
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
}

CustomSwipeableDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

CustomSwipeableDrawer.defaultProps = {
  onClose: () => null,
};

export default memo(CustomSwipeableDrawer);
