import { persistCombineReducers, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import expireIn from 'redux-persist-transform-expire-in';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import main from './ducks/legacy/MainDuck';
import authorization from './ducks/legacy/AuthorizationDuck';
import order from './ducks/legacy/OrderDuck';
import profile from './ducks/legacy/ProfileDuck';
import restaurants from './ducks/legacy/RestaurantsDuck';
import stripe from './ducks/legacy/StripeDuck';
import time from './ducks/new/Time/TimeReducer';
import restaurant from './ducks/new/Restaurant/RestaurantReducer';
import address from './ducks/new/Address/AddressReducer';
import seo from './ducks/new/Seo/SeoReducer';

const logoutActionName = 'authorization/logoutResponse';

// https://github.com/sirLisko/redux-persist-transform-expire-in
const expirationDelay = 1 * 60 * 60 * 1000; // expire in 60 minutes
const expirationKey = 'expirationKey';
const appVersion = process.env?.REACT_APP_VERSION ?? '0.0.0';

const persistConfig = {
  key: `rootstore_${appVersion}`,
  storage,
  transforms: [expireIn(expirationDelay, expirationKey, [])],
  blacklist: ['profile', 'main', 'restaurants'],
};

const mainPersistConfig = {
  key: `mainStore_${appVersion}`,
  storage,
  blacklist: ['globalLoaderQueue'],
};

const profilePersistConfig = {
  key: `profileStore_${appVersion}`,
  storage,
  blacklist: ['getAddressesResponse', 'resetPasswordResponse'],
};

const restaurantsPersistConfig = {
  key: `restaurantsStore_${appVersion}`,
  storage,
  whitelist: ['selectedRestaurant'],
};

const authPersistConfig = {
  key: `authStore_${appVersion}`,
  storage: new CookieStorage(Cookies),
  stateReconciler(inboundState, originalState) {
    return originalState;
  },
  blacklist: ['addresses'],
};

const newPersistConfig = {
  key: `newStore_${appVersion}`,
  storage,
  blacklist: ['restaurant', 'seo', 'time'],
};

const reducers = {
  // legacy
  order,
  stripe,
  restaurants: persistReducer(restaurantsPersistConfig, restaurants),
  authorization: persistReducer(authPersistConfig, authorization),
  main: persistReducer(mainPersistConfig, main),
  profile: persistReducer(profilePersistConfig, profile),
  // new
  time: persistReducer(newPersistConfig, time),
  restaurant: persistReducer(newPersistConfig, restaurant),
  address: persistReducer(newPersistConfig, address),
  seo: persistReducer(newPersistConfig, seo),
};

export const rootReducer = persistCombineReducers(persistConfig, reducers);

const appReducer = (state, action) => {
  if (action.type === logoutActionName) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export default appReducer;
