import { createSelector } from 'reselect';

export const selectTimeState = (state: any) => state.time;

export const selectSelectedTime = createSelector(
  selectTimeState,
  (timeState) => timeState.selectedTime
);

export const selectSelectedDate = createSelector(
  selectTimeState,
  (timeState) => timeState.selectedDate
);
