import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { routeNames } from '../../../util/Constants';
import CartFormComponent from '../CartFormComponent';

function CartForm({
  isShowGiftCodeInput,
  closeModal,
  isDesktop,
  showCheckoutButton,
}) {
  const navigate = useNavigate();
  const [onCheckoutReady, setOnCheckoutReady] = useState(false);

  useEffect(() => {
    if (onCheckoutReady) {
      closeModal();
      navigate(routeNames.checkout);
    }
  }, [onCheckoutReady]);

  return (
    <CartFormComponent
      isDesktop={isDesktop}
      isShowGiftCodeInput={isShowGiftCodeInput}
      showCheckoutButton={showCheckoutButton}
      onCheckoutReady={() => setOnCheckoutReady(true)}
    />
  );
}

CartForm.propTypes = {
  isShowGiftCodeInput: PropTypes.bool,
  closeModal: PropTypes.func,
  isDesktop: PropTypes.bool,
  showCheckoutButton: PropTypes.bool,
};

CartForm.defaultProps = {
  isShowGiftCodeInput: false,
  showCheckoutButton: true,
  isDesktop: false,
  closeModal: () => null,
};

export default CartForm;
