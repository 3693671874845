import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import md5 from 'md5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import {
  authorizeUser,
  resetAuthorization,
  updateError,
} from '../../../redux/legacy/reducers/ducks/legacy/AuthorizationDuck';
import {
  toggleSignIn,
  toggleSignUp,
} from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';

import validationErrors from '../../../util/validation/ValidationErrors';
import styles from './SignInForm.style';
import useClasses from '../../../hooks/legacy/useClasses';

const schema = yup.object().shape({
  email: yup
    .string()
    .email(validationErrors.emailInvalid)
    .required(validationErrors.emailRequired),
  password: yup
    .string()
    .min(6, validationErrors.passwordTooShort)
    .max(50, validationErrors.passwordTooLong)
    .matches(/^(?!.*[\s])/, validationErrors.passwordInvalid)
    .required(validationErrors.passwordRequired),
});

function SignInForm({ onSubmit, onClose, onSignup, forgotPassword }) {
  const classes = useClasses(styles);

  const dispatch = useDispatch();
  const { isSuccess, isLoading, error, open } = useSelector(
    ({
      main,
      authorization,
      order: orderObj,
      restaurant,
      address: selectedAddress,
    }) => ({
      isSuccess: authorization.isSuccess,
      isLoading: authorization.isLoading,
      error: authorization.error,
      open: main.isSignInOpen,
      navigateTo: main.navigateTo,
      order: orderObj,
      selectedRestaurant: restaurant?.selectedRestaurantId,
      selectedAddress: selectedAddress.selectedAddress,
    })
  );

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleClose = useCallback(() => {
    dispatch(toggleSignIn(false));
  }, [dispatch]);

  const openSignUp = _.isNil(onSignup)
    ? () => {
        dispatch(toggleSignIn(false));
        dispatch(toggleSignUp(true));
      }
    : onSignup;

  useEffect(() => {
    if (isSuccess && open) {
      handleClose();
      dispatch(resetAuthorization());
    }
  }, [isSuccess, open, handleClose, dispatch]);

  useEffect(() => {
    if (error?.data) {
      const message = error.data?.message;
      setError('email', { message });
      dispatch(updateError(null));
    }
  }, [error, dispatch, setError]);

  const onSubmitCall = _.isNil(onSubmit)
    ? ({ email, password }) => {
        if (isLoading) {
          return;
        }

        dispatch(
          authorizeUser({
            username: email,
            password: md5(password),
          })
        );
      }
    : onSubmit;
  const signInText = 'Sign in';
  return (
    <form
      onSubmit={handleSubmit(onSubmitCall)}
      data-test-id="sign-in-form"
      className={classes.form}
    >
      <div>
        <Typography className={classes.headerTitle} variant="h6">
          {signInText}
        </Typography>

        {onClose && (
          <IconButton
            size="small"
            className={`btn-icon ${classes.closeBtn}`}
            onClick={onClose}
            data-test-id="closeButton"
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
      </div>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="email"
          render={({ formState: { errors }, field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              label="email"
              data-test-id="emailWrapper"
              variant="outlined"
              fullWidth
              error={errors.email?.message}
              helperText={errors.email?.message}
              inputProps={{ 'data-test-id': 'emailInput' }}
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({ formState: { errors }, field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              type="password"
              label="password"
              className={classes.input}
              data-test-id="passwordWrapper"
              variant="outlined"
              fullWidth
              error={errors.password?.message}
              helperText={errors.password?.message}
              inputProps={{ 'data-test-id': 'passwordInput' }}
            />
          )}
        />
      </Stack>

      <Button
        onClick={forgotPassword}
        data-test-id="forgot-password-button"
        variant="text"
        color="info"
      >
        Forgot password?
      </Button>

      <Button
        fullWidth
        variant="contained"
        size="large"
        type="submit"
        className={`btn-noshadow btn-success ${classes.submitButton}`}
        data-test-id="submit"
      >
        Sign in
      </Button>

      <div className={classes.signUpLinkContainer}>
        <span className={classes.signUpText}>Don&apos;t have an account?</span>
        <Button
          variant="text"
          onClick={openSignUp}
          data-test-id="signUpButton"
          color="info"
        >
          Sign Up
        </Button>
      </div>
    </form>
  );
}

SignInForm.propTypes = {
  onClose: PropTypes.func,
  forgotPassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onSignup: PropTypes.func,
};

SignInForm.defaultProps = {
  onClose: null,
  onSubmit: null,
  onSignup: null,
};

export default SignInForm;
