import { createSlice } from '@reduxjs/toolkit';
import { SelectedTime, SelectedDate } from './Time.types';
import { notAHuman } from '../../../../../../util/Seo';

import getOrderFilterOptions from '../../../../../../util/GetOrderFilterOptions';

const helloBot = notAHuman();

const { dateOptions, timeOptions, typeOptions } = getOrderFilterOptions();

const defaultTypeOption = helloBot === true ? typeOptions[0] : typeOptions[1];
const defaultTypeOptionName = defaultTypeOption.value;
const firstDateOption = helloBot === true ? dateOptions[1] : dateOptions[0];
const firstTimeOptionIndex =
  helloBot === true
    ? Math.round(
        (timeOptions[firstDateOption.key][defaultTypeOptionName].length - 1) *
          0.65
      )
    : 0;

const firstTimeOption =
  helloBot === true
    ? timeOptions[firstDateOption.key][defaultTypeOptionName][
        firstTimeOptionIndex
      ]
    : timeOptions[firstDateOption.key][defaultTypeOptionName][
        firstTimeOptionIndex
      ];

export const INITIAL_STATE = {
  selectedTime: firstTimeOption as SelectedTime,
  selectedDate: firstDateOption as SelectedDate,
};

const mainSlice = createSlice({
  name: 'time',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setSelectedTime(state, action) {
      return {
        ...state,
        selectedTime: action.payload,
      };
    },
    setSelectedDate(state, action) {
      return {
        ...state,
        selectedDate: action.payload,
      };
    },
  },
});

export const { setSelectedTime, setSelectedDate } = mainSlice.actions;

export default mainSlice.reducer;
