import { put, call, takeLeading } from 'redux-saga/effects';
import * as actions from '../../../reducers/ducks/new/Restaurant/RestaurantReducer';

import { getRestaurantDetails as apiGetRestaurantDetails } from '../../../../../util/api/RestaurantApiClient';

export function* getRestaurantDetailsSaga({ payload }) {
  const response = yield call(apiGetRestaurantDetails, payload);
  yield put(actions.setSelectedRestaurantData(response));
}

export function* watchRestaurantSagas() {
  yield takeLeading(
    actions.setSelectedRestaurantId.type,
    getRestaurantDetailsSaga
  );
}
