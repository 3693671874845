import theme from '../../layout/Theme';

const styles = () => ({
  btnGroupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  decIncButton: {
    color: theme.colors.mainText,
    backgroundColor: theme.colors.secondaryBackground,
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    marginLeft: '12px',
    '&:disabled': {
      color: theme.colors.border,
      backgroundColor: theme.colors.secondaryBackground,
      borderRadius: '50%',
      width: '28px',
      height: '28px',
    },
  },
  iconButton: {
    fontSize: theme.font.size.xs,
    lineHeight: theme.font.lineHeight.xs,
  },
  count: {
    color: theme.colors.mainText,
    textAlign: 'center',
    margin: '0px 8px',
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    fontWeight: theme.font.weight.medium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    width: '24px',
  },
});

export default styles;
