import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  user: null as any,
  globalLoaderQueue: [] as any[],
  profileConfiguration: null as any,
  isSignInOpen: false as boolean,
  isAddressOpen: false as boolean,
  isSignUpOpen: false as boolean,
  isMenuOpen: false as boolean,
  isSearching: false as boolean,
  searchValue: '' as string,
  isCartModalOpen: false as boolean,
  navigateTo: '' as string,
  selectedPage: 'list' as string,
  restaurantCenter: null as any,
  displaySortTooltip: 'name' as string,
  centerCarousel: 'id' as string,
  googleMapsErrorState: false as boolean,
};

const mainSlice = createSlice({
  name: 'main',
  initialState: INITIAL_STATE,
  reducers: {
    resetUser(state) {
      return {
        ...state,
        user: null,
      };
    },
    updateUser(state, { payload }) {
      return {
        ...state,
        user: payload,
      };
    },
    toggleGlobalLoaderQueue(state, { payload }) {
      const { name, status } = payload;
      const globalLoaderQueue = [...state.globalLoaderQueue];
      const loadingIndex = globalLoaderQueue.indexOf(name);

      if (status) {
        globalLoaderQueue.push(name);
      } else if (loadingIndex !== -1) {
        globalLoaderQueue.splice(loadingIndex, 1);
      }

      return {
        ...state,
        globalLoaderQueue,
      };
    },
    authorizeUser: (state) => state,
    stopRefreshingUserToken: (state) => state,
    toggleSignIn(state, { payload }) {
      return {
        ...state,
        isSignInOpen:
          typeof payload === 'boolean' ? payload : !state.isSignInOpen,
      };
    },
    toggleAddress(state, { payload }) {
      return {
        ...state,
        isAddressOpen:
          typeof payload === 'boolean' ? payload : !state.isAddressOpen,
      };
    },
    toggleSignUp(state, { payload }) {
      return {
        ...state,
        isSignUpOpen:
          typeof payload === 'boolean' ? payload : !state.isSignUpOpen,
      };
    },
    toggleMenu(state, { payload }) {
      return {
        ...state,
        isMenuOpen: typeof payload === 'boolean' ? payload : !state.isMenuOpen,
      };
    },
    changeSearch(state, { payload }) {
      return {
        ...state,
        isSearching:
          typeof payload === 'boolean' ? payload : !state.isSearching,
      };
    },
    changeSearchValue(state, { payload }) {
      return {
        ...state,
        searchValue: payload,
      };
    },
    toggleCartModal(state, { payload }) {
      return {
        ...state,
        isCartModalOpen:
          typeof payload === 'boolean' ? payload : !state.isCartModalOpen,
      };
    },
    setProfileConfiguration(state, { payload }) {
      return {
        ...state,
        profileConfiguration: payload,
      };
    },
    setNavigateTo(state, { payload }) {
      return {
        ...state,
        navigateTo: payload,
      };
    },
    setSelectedPage: (state, { payload }) => ({
      ...state,
      selectedPage: payload,
    }),
    setRestaurantCenter: (state, { payload }) => ({
      ...state,
      restaurantCenter: payload,
    }),
    setDisplaySortTooltip: (state, { payload }) => ({
      ...state,
      displaySortTooltip: payload,
    }),
    setCenterOnCarousel: (state, { payload }) => ({
      ...state,
      centerCarousel: payload,
    }),
    googleMapsErrorState: (state, { payload }) => ({
      ...state,
      googleMapsErrorState: payload,
    }),
  },
});

export const {
  resetUser,
  updateUser,
  authorizeUser,
  toggleSignIn,
  toggleAddress,
  toggleSignUp,
  toggleMenu,
  changeSearch,
  toggleCartModal,
  stopRefreshingUserToken,
  changeSearchValue,
  toggleGlobalLoaderQueue,
  setProfileConfiguration,
  setNavigateTo,
  setSelectedPage,
  setRestaurantCenter,
  setDisplaySortTooltip,
  setCenterOnCarousel,
  googleMapsErrorState,
} = mainSlice.actions;

export default mainSlice.reducer;
