import axios from './Axios';

const path = '/restaurant/customer';

export const getRestaurantDetails = (id) =>
  axios.get(`${path}/details`, { params: { client: id } });

/**
 * @param {"target" | "asap"} type
 * @param {{
 *  client: string;
 *  mode: string;
 *  start: number;
 *  end: number;
 *  lat: number;
 *  lng: number;
 * }} params
 */
export const getRestaurantMenu = (type, params) =>
  axios.get(`${path}/menu/${type}`, { params });

/**
 * @param {{
 *  client: string;
 * }} params
 */
export const getRestaurantAllMenu = (params) =>
  axios.get(`${path}/menu/all`, { params });

const checkoutPath = '/checkout';

/**
 * @param {{
 *  client: string;
 *  data: {
 *    type: "target" | "asap";
 *    mode: "delivery" | "takeout" | "catering";
 *    start: number;
 *    end: number;
 *    phone: number;
 *    cart: Array<CartObjectType>
 *  };
 * }} data
 */
export const checkoutStart = (data) =>
  axios.post(`${path}${checkoutPath}/start`, data);

/**
 * @param {{
 *  client: string;
 *  checkout: string;
 *  payment: "stripe";
 *  data: object;
 * }} data
 */
export const checkoutEnd = (data) =>
  axios.post(`${path}${checkoutPath}/end`, data);

export const setCheckoutEnd = async (data) => {
  try {
    const result = await axios.post(`${path}${checkoutPath}/end`, data);
    return result;
  } catch (err) {
    return {};
  }
};

/**
 * @param {{
 *  client: string;
 *  checkout: string;
 *  data: {
 *    phone: number;
 *    notes: string;
 *    fees: Array<{
 *      id: number;
 *      amount: number;
 *    }>;
 *    coupons: Array<{
 *      code: string;
 *    }>;
 *  };
 * }} data
 */
export const checkoutAdjust = (data) =>
  axios.post(`${path}${checkoutPath}/adjust`, data);

const cartPath = '/cart';

export const getOrderCart = async (restaurantProfile) => {
  try {
    const result = await axios.get(`${path}${cartPath}`, {
      params: {
        client: restaurantProfile,
      },
    });

    return result;
  } catch (err) {
    return {};
  }
};

/**
 * @param {{
 *  client: string;
 *  data: {
 *    end: number;
 *    mode: string;
 *    start: number;
 *    type: string;
 *    cart: Array<{
 *      id: number;
 *      items: Array<{
 *        sort: number;
 *        special_instructions: string;
 *        answers: Array<{
 *          answer: number;
 *          id: number;
 *          amount?: number;
 *        }>;
 *      }>;
 *    }>;
 *  };
 * }} data
 */
export const setOrderCart = (data) => axios.put(`${path}${cartPath}/set`, data);

export const resetOrderCart = (restaurantProfile) =>
  axios.put(`${path}${cartPath}/reset`, { client: restaurantProfile });

export const trackOrder = (id) => axios.get(`${path}/order/tracking/${id}`);

/**
 * @param {number} lat
 * @param {number} lng
 */
export const getAddressCoverageStatus = (lat, lng, restaurantProfile) =>
  axios.get(`${path}/coverage`, {
    params: {
      latitude: lat,
      longitude: lng,
      client: restaurantProfile,
    },
  });

export default {
  getRestaurantDetails,
  getRestaurantMenu,
  checkoutStart,
  checkoutEnd,
  setCheckoutEnd,
  checkoutAdjust,
  getOrderCart,
  setOrderCart,
  resetOrderCart,
  trackOrder,
  getAddressCoverageStatus,
};
