import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { compose, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import saga from '../middleware/sagas/RootSaga';
import RootReducer from '../reducers/RootReducer';
import { isEnvMatch, envTypes } from '../../../AppConfig';

const devMode = isEnvMatch(envTypes.local) || isEnvMatch(envTypes.dev);

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    immutableCheck: false,
    serializableCheck: false,
  }),
  sagaMiddleware,
];

const mdw = devMode
  ? composeWithDevTools(applyMiddleware(...middleware))
  : compose(applyMiddleware(...middleware));

const configureStore = (preloadedState = RootReducer(undefined, {})) => {
  const store = createStore(RootReducer, preloadedState, mdw);

  sagaMiddleware.run(saga);

  return store;
};

export default configureStore();
