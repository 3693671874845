import theme from '../Theme';

const styles = (materialTheme) => ({
  root: {
    padding: '70px 100px 20px',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 24,
    color: materialTheme.palette.common.white,
    backgroundColor: materialTheme.palette.primary.main,
    [materialTheme.breakpoints.down('lg')]: {
      padding: '20px 18px',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    [theme.screenSize.desktop]: {
      flexDirection: 'row',
    },
  },
  logoWrapper: {
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '300px',
    marginRight: '65px',
  },
  logo: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoDescription: {
    marginBottom: '20px',
  },
  footerData: {
    display: 'flex',
    flexGrow: 1,
    [theme.screenSize.mobile]: {
      flexDirection: 'column',
    },
  },
  navigationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '&:not(:last-child)': {
      flexBasis: '33%',
    },
    [theme.screenSize.mobile]: {
      flexBasis: '100%',
      marginBottom: '32px',
      '& > hr': {
        display: 'none',
      },
    },
  },
  navigation: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > h5': {
      color: materialTheme.palette.grey400.main,
      marginBottom: '10px',
    },
    '& > ul > li': {
      marginTop: '10px',
      '& > a': {
        color: theme.colors.whiteGrey,
      },
    },
  },
  foodNavigation: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > li': {
      marginTop: '10px',
      flex: 1,
      flexBasis: 'calc(50% - 20px)',
      boxSizing: 'border-box',
    },
  },
  devider: {
    margin: '0 30px',
    border: `1px solid ${theme.colors.blue120}`,
    borderTop: 'none',
    opacity: 0.5,
  },
  deviderVertical: {
    margin: '25px 0',
    border: `1px solid ${theme.colors.blue120}`,
    borderTop: 'none',
    opacity: 0.5,
  },
  footerLinks: {
    columnGap: '14px',
    columnCount: 3,
    padding: '0 15px',
  },
  social: {
    marginBottom: '40px',
    '& > a:not(:first-child)': {
      marginLeft: '20px',
      cursor: 'pointer',
    },
  },
  allRightsReserved: {
    color: materialTheme.palette.grey400.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.screenSize.mobile]: {
      margin: '0 32px 32px',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& > h4': {
        marginBottom: '20px',
        textAlign: 'center',
      },
      '& > div': {
        marginBottom: '20px',
      },
    },
  },
});

export default styles;
