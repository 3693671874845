import theme from '../Theme';

const styles = () => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.secondaryBackground,
    borderRadius: '8px 8px 0 0',
    boxShadow: '0px -3px 10px #0000000D',
    height: '90px',
    zIndex: 25,
  },
  dialog: {
    maxHeight: 500,
    background: '#fff',
    overflowY: 'auto',
    color: '#000',
    [theme.screenSize.desktop]: {
      width: 400,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
  },
  headerTitle: {
    color: 'black',
    flex: 1,
    fontWeight: theme.font.weight.boldest,
    fontSize: theme.font.size.xl,
    lineHeight: theme.font.lineHeight.xl,
  },
  cartIconContainer: {
    background: theme.colors.lightGray2,
    padding: '6px 10px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cartIcon: {
    marginRight: '10px',
    position: 'relative',
    cursor: 'pointer',
    '& svg': {
      color: theme.colors.border,
    },
    '& div': {
      position: 'absolute',
      width: '18px',
      height: '18px',
      borderRadius: '6px',
      top: '-10px',
      right: '-10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.font.size.xs,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.contrastText,
      backgroundColor: theme.colors.success.main,
    },
  },
  cartPrice: {
    fontFamily: theme.font.family.secondary,
    fontWeight: theme.font.weight.bold,
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    userSelect: 'none',
  },
  checkout: {
    '&&&': {
      borderRadius: '24px',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    padding: '10px 16px',
    boxShadow: 'none',
  },
  checkoutArrow: {
    marginLeft: '6px',
  },
  checkoutArrowIcon: {
    color: theme.colors.contrastText,
  },
  closeIcon: {
    color: theme.colors.semiDarkText,
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
  },
});

export default styles;
