/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';

import Root from './Root';
import {
  setupInterceptors,
  requestInterceptor,
  responseSuccessInterceptor,
  responseFailInterceptor,
} from './util/api/setupInterceptors';
import store from './redux/legacy/stores/GlobalStore';
import * as serviceWorker from './serviceWorker';
import './globalStyles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

setupInterceptors(
  store,
  responseSuccessInterceptor,
  responseFailInterceptor,
  requestInterceptor
);
