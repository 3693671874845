import theme from '../Theme/Theme';

const styles = () => ({
  routeRow: {
    margin: '10px',
    borderRadius: 8,
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.semiDarkText,
  },
  activeRow: {
    margin: '10px',
    borderRadius: 8,
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.secondary.light,
    color: theme.colors.contrastBackgroud,
  },
});

export default styles;
