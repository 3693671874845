import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { timeNow } from '../../../util/GetOrderFilterOptions';
import styles from './FilterLabel.style';
import useClasses from '../../../hooks/legacy/useClasses';

const DEFAULT_WINDOW_VALUE = 30;

/*
This functional component is used only to show the current time selection of the user, it should just read the value selected, no logic duplication should be applied here
TO BE REFACORED SOON
*/

function minuteFormat(delays, type, windows) {
  if (!delays) {
    return '';
  }

  const valueWindows = isNil(windows[type?.value])
    ? DEFAULT_WINDOW_VALUE * 60
    : windows[type?.value];

  return `${delays[type?.value] / 60} - ${
    (delays[type?.value] + valueWindows) / 60
  } minutes`;
}

function RealTimeLabel({ delay, windows = 0, updateTime = true }) {
  const [hour, setHour] = useState(timeNow((delay + windows) / 60));
  const hourRef = useRef(null);
  useEffect(() => {
    if (updateTime) {
      hourRef.current = setInterval(() => {
        setHour(timeNow((delay + windows) / 60));
      }, 1000);
    }
    return () => clearInterval(hourRef.current);
  });
  return hour;
}

function FilterLabel({
  time,
  date,
  delays,
  type,
  pathname,
  windows,
  labelTime,
}) {
  const classes = useClasses(styles);

  const page = pathname?.split('/')[1] || '';

  if (!(delays && delays[type?.value]))
    return time?.label ? `${date.label}: ${time.label}` : <span>ASAP</span>;

  const isRestaurantsListPage = ['restaurants'].includes(page);
  const isRestaurantPage = ['restaurant'].includes(page);
  const isCheckoutPage = ['checkout'].includes(page);
  const isOrderSuccessPage = ['order-success'].includes(page);

  if (isRestaurantsListPage) {
    return (
      <span className={classes.spanStyle}>
        {type?.label && `${type?.label} | `}
        {time.label ? (
          `${date.label}: ${time.label}`
        ) : (
          <span>
            {/* Here the windows is being passed as {} */}
            {`ASAP: ${minuteFormat(delays, type, windows)}`}
          </span>
        )}
      </span>
    );
  }

  if (isRestaurantPage || isCheckoutPage || isOrderSuccessPage) {
    return (
      <span className={classes.spanStyle}>
        {isRestaurantPage && type?.label && `${type?.label} | `}
        {time.label ? (
          `${date.label}: ${time.label}`
        ) : (
          <span>
            {`${date.label}: `}
            <RealTimeLabel
              delay={delays ? delays[type?.value] : 0}
              updateTime={!isOrderSuccessPage}
            />
            {' - '}
            <RealTimeLabel
              delay={delays ? delays[type?.value] : 0}
              windows={windows && windows[type?.value]}
              updateTime={!isOrderSuccessPage}
            />
          </span>
        )}
      </span>
    );
  }

  return (
    <span className={classes.spanStyle}>
      {time.label ? (
        `${date.label}: ${time.label}`
      ) : (
        <span>
          ASAP
          {labelTime}
        </span>
      )}
    </span>
  );
}

FilterLabel.propTypes = {
  time: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.shape({
        start: PropTypes.number,
        end: PropTypes.number,
      }),
      PropTypes.string,
    ]),
    type: PropTypes.string,
    label: PropTypes.string,
  }),
  date: PropTypes.shape({
    fullName: PropTypes.string,
    key: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  delays: PropTypes.shape({
    delivery: PropTypes.number,
    takeout: PropTypes.number,
  }),
  type: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  windows: PropTypes.arrayOf(
    PropTypes.shape({
      delivery: PropTypes.number,
      takeout: PropTypes.number,
      catering: PropTypes.number,
    })
  ),
  pathname: PropTypes.string,
  labelTime: PropTypes.string,
};

FilterLabel.defaultProps = {
  time: {},
  date: {},
  delays: {},
  type: {},
  windows: [],
  pathname: '',
  labelTime: '',
};

export default memo(FilterLabel);
