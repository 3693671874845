import axios from './Axios';
import { getConfig } from '../../AppConfig';
import { RATING_FORM_ID } from '../Constants';

const path = '/consumer/customer';
const config = getConfig();

export const getOrdersHistory = (params) =>
  axios.get(`${path}/order/history`, {
    params: {
      ...params,
      client: config.profileKey,
    },
  });

/**
 * @param {{
 *  mode: string;
 *  type: string;
 *  start: number;
 *  end: number;
 *  lat: number;
 *  lng: number;
 * }} params
 */
export const getRestaurants = (params) =>
  axios.get(`${path}/restaurants`, {
    params: {
      ...params,
      client: config.profileKey,
    },
  });

export const getAllRestaurants = (params) =>
  axios.get(`${path}/restaurants/all`, {
    params: {
      ...params,
      client: config.profileKey,
    },
  });

export const setFavorite = (params) => axios.post(`${path}/favorite`, params);
export const getAllDeals = (params) =>
  axios.get(`${path}/deals`, {
    params: {
      ...params,
      client: config.profileKey,
    },
  });

export const getRateOrderConfig = (orderId) =>
  axios.get(`${path}/order/rate`, {
    params: {
      order: orderId,
      form: RATING_FORM_ID,
    },
  });

export const setOrderRate = (data) =>
  axios.post(`${path}/order/rate`, {
    ...data,
    form: RATING_FORM_ID,
  });

export default {
  getOrdersHistory,
  getRestaurants,
  getAllRestaurants,
  setFavorite,
  getAllDeals,
  getRateOrderConfig,
  setOrderRate,
};
