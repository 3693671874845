import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { hotjar } from 'react-hotjar';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';

import App from './App';
import { getConfig } from './AppConfig';
import store from './redux/legacy/stores/GlobalStore';
import ThemeProvider from './components/layout/Theme/ThemeProvider';
import SeoProvider from './components/providers/SeoProvider/SeoProvider';

const persistor = persistStore(store);

const config = getConfig();

Bugsnag.start({
  apiKey: config?.bugsnagKey || 'bogus_key',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
});
const hotjarId = config?.hotjarId || 'bogus_hotjar_id';
const hotjarSnippetVersion =
  config?.hotjarSnippetVersion || 'bogus_hotjar_snippet_version';

hotjar.initialize(hotjarId, hotjarSnippetVersion);

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function Root() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider>
                <App />
              </ThemeProvider>
            </StyledEngineProvider>
            <SeoProvider />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}
export default Root;
