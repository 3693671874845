import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  setSelectedRestaurantId as storeSelectedRestaurantId,
  setSelectedRestaurantData as storeSelectedRestaurantData,
  setSelectedRestaurantMenu as storeSelectedRestaurantMenu,
} from '../redux/legacy/reducers/ducks/new/Restaurant/RestaurantReducer';
import {
  selectSelectedRestaurantData,
  selectSelectedRestaurantMenu,
} from '../redux/legacy/reducers/ducks/new/Restaurant/RestaurantSelector';
import { isRestaurantOpenAt } from './helpers/RestaurantHelper';
import { routeNames } from '../util/Constants';

export function useRestaurant() {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedRestaurantData = useSelector(selectSelectedRestaurantData);
  const selectedRestaurantMenuData = useSelector(selectSelectedRestaurantMenu);

  // TODO: workaround, we shouldn't hardcode the url path
  const onSelectedRestaurantPage = useMemo(
    () =>
      location.pathname === routeNames.restaurantMenu ||
      location.pathname === routeNames.restaurantOrder ||
      location.pathname.includes('restaurant/'),
    [location]
  );

  const isSelectedRestaurantOpenNow = (orderType: string) => {
    const now = new Date();
    return isRestaurantOpenAt(now, orderType, selectedRestaurantData);
  };
  const isSelectedRestaurantOpenAt = (orderType: string, at: Date) =>
    isRestaurantOpenAt(at, orderType, selectedRestaurantData);

  const setSelectedRestaurant = (restaurant: string) => {
    dispatch(storeSelectedRestaurantId(restaurant));
    dispatch(storeSelectedRestaurantData(null));
    dispatch(storeSelectedRestaurantMenu(null));
  };

  const resetSelectedRestaurant = () => {
    dispatch(storeSelectedRestaurantId(null));
    dispatch(storeSelectedRestaurantData(null));
    dispatch(storeSelectedRestaurantMenu(null));
  };

  const resetSelectedRestaurantMenu = () => {
    dispatch(storeSelectedRestaurantMenu(null));
  };

  const selectedRestaurant = useMemo(
    () => selectedRestaurantData,
    [selectedRestaurantData]
  );

  const selectedRestaurantMenu = useMemo(
    () => selectedRestaurantMenuData,
    [selectedRestaurantMenuData]
  );

  return {
    setSelectedRestaurant,
    resetSelectedRestaurant,
    resetSelectedRestaurantMenu,
    selectedRestaurant,
    selectedRestaurantMenu,
    onSelectedRestaurantPage,
    isSelectedRestaurantOpenNow,
    isSelectedRestaurantOpenAt,
  };
}

export default useRestaurant;
