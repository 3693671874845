import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@emotion/react';
import { Link as ScrollLink } from 'react-scroll';

import CartPanel from '../CartPanel';
import { getConfig } from '../../../AppConfig';
import { routeNames } from '../../../util/Constants';
import useClasses from '../../../hooks/legacy/useClasses';

import { ReactComponent as Twitter } from '../../../assets/images/social/twitter_second.svg';
import { ReactComponent as Facebook } from '../../../assets/images/social/facebook_second.svg';
import { ReactComponent as Instagram } from '../../../assets/images/social/instagram_second.svg';

import styles from './RestaurantFooter.style';
import useDesktopCheck from '../../../util/hooks/UseDesktopCheck';

const config = getConfig();

const {
  consumer_footer_twitter_link: twitterLink,
  consumer_footer_facebook_link: facebookLink,
  consumer_footer_instagram_link: instagramLink,
} = config.locales?.reduce((acc, locale) => {
  acc[locale.name] = locale.text;

  return acc;
}, {}) ?? {};

function RestaurantFooter() {
  const classes = useClasses(styles);
  const isDesktop = useDesktopCheck();
  const location = useLocation();
  const theme = useTheme();
  const isHomePage =
    location.pathname === routeNames.home ||
    location.pathname === routeNames.contact;

  const logoImage = useMemo(() => {
    let result = [];
    if (config?.images?.length) {
      result = config.images.filter(
        (img) => img.name === 'site_logo' && img.version === 1
      );
    }

    return result?.length && result[0]?.url ? result[0].url : '';
  }, [config.images]);

  const visitData = useMemo(() => {
    const newData = [];

    if (config?.locales?.length) {
      const data = config.locales;
      for (let i = 1; i <= 6; i++) {
        const titleKey = `field_title_0${i}`;
        const textKey = `field_text_0${i}`;
        const titleData = data.find((item) => item.name === titleKey);
        const textData = data.find((item) => item.name === textKey);

        if (titleData && textData) {
          newData.push({
            name: titleData.text,
            description: textData.text,
          });
        }
      }
    }
    return newData;
  }, [config.locales]);

  const cartPanelShouldAppearIn = [
    routeNames.menu,
    routeNames.restaurantMenu,
    routeNames.restaurantOrder,
  ];

  const shouldShowCartPanel =
    !isDesktop &&
    (cartPanelShouldAppearIn.includes(location.pathname) ||
      location.pathname.includes('/restaurant/'));

  function footer() {
    return (
      <AppBar position="static" elevation={1} color="default">
        <Toolbar
          sx={{
            padding: '8px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          data-test-id="default-header"
        >
          <Stack
            sx={{ mx: 1 }}
            direction={isDesktop ? 'row' : 'column'}
            spacing={1.5}
            justifyContent="center"
            alignItems="center"
            mb={4}
          >
            {isHomePage ? (
              <ScrollLink to="topOfHomePage" smooth duration={500}>
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  Home
                </Button>
              </ScrollLink>
            ) : (
              <Link to={routeNames.home}>
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  Home
                </Button>
              </Link>
            )}
            {isHomePage && (
              <ScrollLink to="aboutUs" smooth duration={500}>
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  About Us
                </Button>
              </ScrollLink>
            )}
            <Link to="/menu">
              <Button
                color="primary"
                size="medium"
                variant="text"
                data-test-id="login-button"
                aria-hidden="true"
              >
                Menu
              </Button>
            </Link>
            {isHomePage && (
              <ScrollLink to="hours" smooth duration={500}>
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  Opening Hours
                </Button>
              </ScrollLink>
            )}
            {isHomePage && (
              <ScrollLink to="contact" smooth duration={500}>
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  Contact
                </Button>
              </ScrollLink>
            )}
            <Link to="/menu">
              <Button
                color="primary"
                size="medium"
                variant="text"
                data-test-id="login-button"
                aria-hidden="true"
              >
                Order
              </Button>
            </Link>
          </Stack>
          <Stack
            sx={{ mx: 1 }}
            direction={isDesktop ? 'row' : 'column'}
            spacing={10}
            justifyContent="center"
            alignItems="center"
            mb={4}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                [theme.breakpoints.down('sm')]: {
                  alignItems: 'center',
                },
              }}
            >
              {logoImage ? (
                <Box
                  component="img"
                  src={
                    logoImage?.includes('https')
                      ? logoImage
                      : `https:${logoImage}`
                  }
                  maxHeight="200px"
                  width="auto"
                  sx={{ borderRadius: 2 }}
                />
              ) : (
                <Skeleton variant="rectangular" width="400px" height="400px" />
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                [theme.breakpoints.down('sm')]: {
                  alignItems: 'center',
                },
              }}
            >
              <Typography variant="h6" color="black" textAlign="left">
                Visit Us Today!
              </Typography>
              {visitData?.length ? (
                visitData.map((list) =>
                  isDesktop ? (
                    <Typography
                      variant="body1"
                      color="black"
                      textAlign="left"
                      fontWeight={400}
                    >
                      {`${list?.name} ${list?.description}`}
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        color="black"
                        textAlign="left"
                        fontWeight={400}
                      >
                        {list?.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="black"
                        textAlign="left"
                        fontWeight={400}
                      >
                        {list?.description}
                      </Typography>
                    </>
                  )
                )
              ) : (
                <>
                  <Typography
                    variant="body1"
                    color="black"
                    textAlign="left"
                    fontWeight={400}
                  >
                    Address: 150 Main Street, Old Saybrook, CT 06475
                  </Typography>
                  <Typography
                    variant="body1"
                    color="black"
                    textAlign="left"
                    fontWeight={400}
                  >
                    Phone: (860) 388-9646
                  </Typography>
                  <Typography
                    variant="body1"
                    color="black"
                    textAlign="left"
                    fontWeight={400}
                  >
                    Monday - Saturday: open 11:30 am - 9:00 pm
                  </Typography>
                  <Typography
                    variant="body1"
                    color="black"
                    textAlign="left"
                    fontWeight={400}
                  >
                    Sunday: open 11:30 am - 8:00 pm
                  </Typography>
                </>
              )}
            </Box>
          </Stack>
          <Stack
            sx={{ mx: 1 }}
            direction="column"
            spacing={3.5}
            justifyContent="center"
            alignItems="center"
            mb={3}
          >
            <Typography
              variant="grayText"
              color="black"
              textAlign="left"
              fontWeight={600}
            >
              Stay Connected!
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link
                target="_blank"
                href={facebookLink}
                data-test-id="footer-facebook-link"
                className={classes.socialButton}
              >
                <Facebook />
              </Link>
              <Link
                target="_blank"
                href={instagramLink}
                data-test-id="footer-instagram-link"
                mx={3}
                className={classes.socialButton}
              >
                <Instagram />
              </Link>
              <Link
                target="_blank"
                href={twitterLink}
                data-test-id="footer-twitter-link"
                className={classes.socialButton}
              >
                <Twitter />
              </Link>
            </Box>
            <Typography
              variant="grayText"
              color="black"
              textAlign="left"
              fontWeight={400}
            >
              v{config.version}:{config.runtimeProvider.toLowerCase()} | ©{' '}
              {new Date().getFullYear()} Eatzy LLC | All rights reserved.
            </Typography>
            {shouldShowCartPanel && <CartPanel />}
          </Stack>
        </Toolbar>
      </AppBar>
    );
  }

  return footer();
}

export default RestaurantFooter;
