import theme from '../Theme';

export const loaderSize = 60;

const styles = () => ({
  root: {
    width: `${loaderSize}px`,
    height: `${loaderSize}px`,
    position: 'fixed',
    top: `calc(50% - ${loaderSize / 2}px)`,
    left: `calc(50% - ${loaderSize / 2}px)`,
    zIndex: 500,
    color: theme.colors.primary,
  },
});

export default styles;
