import { formatInTimeZone } from 'date-fns-tz';
import _ from 'lodash';
import { getConfig } from '../AppConfig';

import getOrderFilterOptions, {
  toBusinessTimezone,
} from './GetOrderFilterOptions';

const config = getConfig();

const getNearestTime = (date, time, orderType, restaurantDetails = null) => {
  const { timeOptions } = getOrderFilterOptions(restaurantDetails);

  if (!orderType) {
    // console.error('orderType not specified');
    return null;
  }

  const isASAP = !_.isObject(time) || time?.type === 'asap';
  const oType = orderType.value;

  let result = null;

  if (isASAP === true) {
    const now = toBusinessTimezone();
    const monthDayNumber = parseInt(
      formatInTimeZone(now, config.timezone, 'd'),
      10
    );

    const intervals = timeOptions[monthDayNumber][oType];

    result = intervals[0] ?? null;
  } else {
    const intervals = timeOptions[date.dayOfMonth]
      ? timeOptions[date.dayOfMonth][oType]
      : [];

    // first search for one interval containing the previous selection
    for (let index = 0; index < intervals.length; index++) {
      const interval = intervals[index];
      if (
        interval.value.start <= time.value.start &&
        interval.value.end > time.value.start
      ) {
        result = interval;
        break;
      }
    }

    // if no interval contains it, then select the first one available after that
    if (result === null) {
      let diff = null;
      for (let index = 0; index < intervals.length; index++) {
        const interval = intervals[index];
        if (time.value.start <= interval.value.start) {
          const localDiff = interval.value.start - time.value.start;
          if (diff === null) {
            result = interval;
            diff = localDiff;
          } else if (localDiff < diff) {
            result = interval;
            diff = localDiff;
          }
        }
      }
    }
  }

  return result;
};

export default getNearestTime;
