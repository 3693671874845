import theme from '../../layout/Theme';

const styles = () => ({
  input: {
    marginBottom: '16px',
  },
  label: {
    fontWeight: theme.font.weight.semibold,
    fontSize: theme.font.size.xs,
    lineHeight: theme.font.lineHeight.xs,

    color: '#6C6C6C',
  },
  submitButton: {
    marginTop: '30px',
    marginBottom: '36px',
  },
  successMessage: {
    textAlign: 'center',
  },
  successIcon: {
    color: theme.colors.success.main,
    fontFamily: theme.font.family.main,
    fontSize: theme.font.size.xl4,
    lineHeight: theme.font.lineHeight.xl4,
  },
  apiMessage: {
    color: theme.colors.success.main,
    marginBottom: '20px',

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
});

export default styles;
