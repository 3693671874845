import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  phones: [] as any[],
  trackingOrder: null as any,
  phoneFormStatus: {} as any, // Can have fields: isLoading isSuccess error
  orders: null as any[] | null,
  addressFormStatus: {} as any, // Can have fields: isLoading isSuccess error
  getAddressesResponse: {} as any,
  changePasswordResponse: {} as any, // Can have fields: isLoading isSuccess error
  resetPasswordResponse: {} as any,
  saveFavoriteError: '' as string,
  ratingConfigItems: [] as any[],
  orderRatingResponse: {} as any,
  addressUpdateRequest: null as any,
  addressUpdateResponse: null as any,
};

const mainSlice = createSlice({
  name: 'order',
  initialState: { ...INITIAL_STATE },
  reducers: {
    resetAll() {
      return { ...INITIAL_STATE };
    },
    getAddressesRequest: (state) => state,
    getAddressesResponse(state, { payload }) {
      return {
        ...state,
        getAddressesResponse: payload,
      };
    },
    updatePhones(state, { payload }) {
      return {
        ...state,
        phones: payload,
      };
    },
    getPhones: (state) => state,
    deletePhoneRequest: (state) => ({
      ...state,
      deletePhoneResponse: null,
    }),
    deletePhoneResponse(state, { payload }) {
      return {
        ...state,
        deletePhoneResponse: payload,
      };
    },
    changePasswordRequest: (state) => state,
    resetPasswordRequest: (state) => ({
      ...state,
      resetPasswordResponse: null,
    }),
    resetPasswordResponse(state, { payload }) {
      return {
        ...state,
        resetPasswordResponse: payload,
      };
    },
    forgotPasswordRequest: (state) => ({
      ...state,
      forgotPasswordResponse: null,
    }),
    forgotPasswordResponse(state, { payload }) {
      return {
        ...state,
        forgotPasswordResponse: payload,
      };
    },
    changePasswordResponse(state, { payload }) {
      return {
        ...state,
        changePasswordResponse: payload,
      };
    },
    submitAddressForm: (state) => state,
    updateAddressFormStatus(state, { payload }) {
      return {
        ...state,
        addressFormStatus: payload,
      };
    },
    deleteAddressRequest: (state) => ({
      ...state,
      deleteAddressResponse: null,
    }),
    deleteAddressResponse(state, { payload }) {
      return {
        ...state,
        deleteAddressResponse: payload,
      };
    },
    getAddressCoverageStatusRequest: (state) => ({
      ...state,
      getAddressCoverageStatusResponse: null,
    }),
    getAddressCoverageStatusResponse(state, { payload }) {
      return {
        ...state,
        getAddressCoverageStatusResponse: payload,
      };
    },
    submitPhoneForm: (state) => state,
    updatePhoneFormStatus(state, { payload }) {
      return {
        ...state,
        phoneFormStatus: payload,
      };
    },
    getOrdersHistory: (state) => ({
      ...state,
      trackingOrder: null,
    }),
    updateOrdersHistory(state, { payload }) {
      return {
        ...state,
        orders: payload,
      };
    },
    getTrackingOrderRequest(state) {
      return {
        ...state,
        trackingOrderResponse: {},
      };
    },
    getTrackingOrderResponse(state, { payload }) {
      return {
        ...state,
        trackingOrderResponse: payload,
      };
    },
    setFavoriteRequest(state) {
      return state;
    },
    setOrderFavoriteResponse(
      state,
      { payload }: PayloadAction<{ id: string | number; favorite: boolean }>
    ) {
      return {
        ...state,
        orders:
          state.orders &&
          state.orders.map((order) =>
            order.id === payload.id
              ? { ...order, favorite: payload.favorite }
              : order
          ),
        saveFavoriteError: '',
      };
    },
    setSaveFavoriteError(state, { payload }) {
      return {
        ...state,
        saveFavoriteError: payload,
      };
    },
    getRatingConfigItemsRequest(state) {
      return {
        ...state,
        ratingConfigItems: [],
      };
    },
    getRatingConfigItemsResponse(state, { payload }) {
      if (payload?.error) {
        return {
          ...state,
          ratingConfigItems: payload,
        };
      }
      return {
        ...state,
        ratingConfigItems: payload,
      };
    },
    resetRatingConfigItemsRequest(state) {
      return {
        ...state,
        ratingConfigItems: [],
        orderRatingResponse: {},
      };
    },
    setOrderRateRequest: (state) => ({
      ...state,
      orderRatingResponse: {},
    }),
    setOrderRateResponse: (state, { payload }) => ({
      ...state,
      orderRatingResponse: payload,
    }),
    updateAddressRequest: (state, { payload }) => ({
      ...state,
      addressUpdateRequest: payload,
      addressUpdateResponse: null,
    }),
    updateAddressResponse: (state, { payload }) => ({
      ...state,
      addressUpdateRequest: null,
      addressUpdateResponse: payload,
    }),
  },
});

export const {
  getAddressesRequest,
  getAddressesResponse,
  updatePhones,
  getPhones,
  changePasswordRequest,
  changePasswordResponse,
  submitAddressForm,
  updateAddressFormStatus,
  getOrdersHistory,
  updateOrdersHistory,
  submitPhoneForm,
  updatePhoneFormStatus,
  getTrackingOrderRequest,
  getTrackingOrderResponse,
  deletePhoneRequest,
  deletePhoneResponse,
  deleteAddressRequest,
  resetPasswordRequest,
  resetPasswordResponse,
  forgotPasswordRequest,
  forgotPasswordResponse,
  deleteAddressResponse,
  getAddressCoverageStatusRequest,
  getAddressCoverageStatusResponse,
  resetAll,
  setFavoriteRequest,
  setOrderFavoriteResponse,
  setSaveFavoriteError,
  getRatingConfigItemsRequest,
  getRatingConfigItemsResponse,
  resetRatingConfigItemsRequest,
  setOrderRateRequest,
  setOrderRateResponse,
  updateAddressRequest,
  updateAddressResponse,
} = mainSlice.actions;

export default mainSlice.reducer;
