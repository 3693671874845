import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import { materialTheme } from './Theme';

function MaterialProvider({ children }) {
  return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>;
}

MaterialProvider.propTypes = {
  children: PropTypes.node,
};

MaterialProvider.defaultProps = {
  children: null,
};

export default memo(MaterialProvider);
