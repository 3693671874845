import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {};

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: INITIAL_STATE,
  reducers: {
    getStripeRequest: (state) => state,
    getStripeResponse(state, { payload }) {
      return {
        ...state,
        stripeResponse: payload,
      };
    },
    resetStripe: (state) => ({
      ...state,
      stripeResponse: {},
    }),
  },
});

export const { getStripeRequest, getStripeResponse, resetStripe } =
  stripeSlice.actions;

export default stripeSlice.reducer;
