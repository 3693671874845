import { createSelector } from 'reselect';

export const selectRestaurantState = (state: any) => state.restaurant;

export const selectSelectedRestaurantId = createSelector(
  selectRestaurantState,
  (restaurantState) => restaurantState?.selectedRestaurantId
);

export const selectSelectedRestaurantData = createSelector(
  selectRestaurantState,
  (restaurantState) => restaurantState?.selectedRestaurantData
);

export const selectSelectedRestaurantMenu = createSelector(
  selectRestaurantState,
  (restaurantState) => restaurantState?.selectedRestaurantMenu
);
