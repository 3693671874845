import { createSlice } from '@reduxjs/toolkit';

import { isRestaurantApp } from '../../../../../../util/Helpers';
import { getConfig } from '../../../../../../AppConfig';

const config = getConfig();

const defaultSelectedRestaurantValue = isRestaurantApp()
  ? config?.profileKey
  : null;

export const INITIAL_STATE = {
  selectedRestaurantId: defaultSelectedRestaurantValue as string | null,
  selectedRestaurantData: null as any,
  selectedRestaurantMenu: null as any,
};

const mainSlice = createSlice({
  name: 'restaurant',
  initialState: { ...INITIAL_STATE },
  reducers: {
    setSelectedRestaurantId(state, action) {
      return {
        ...state,
        selectedRestaurantId: action.payload,
      };
    },
    setSelectedRestaurantData(state, action) {
      return {
        ...state,
        selectedRestaurantData: action.payload,
      };
    },
    setSelectedRestaurantMenu(state, action) {
      return {
        ...state,
        selectedRestaurantMenu: action.payload,
      };
    },
  },
});

export const {
  setSelectedRestaurantId,
  setSelectedRestaurantData,
  setSelectedRestaurantMenu,
} = mainSlice.actions;

export default mainSlice.reducer;
