import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _isInteger from 'lodash/isInteger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { IconButton } from '@mui/material';
import styles from './IncDecBtnGroup.style';
import useClasses from '../../../hooks/legacy/useClasses';

function IncDecBtnGroup({
  min,
  max,
  value,
  onChange,
  updateInProcess,
  weight,
  maxTotal,
  selectedTotal,
}) {
  const classes = useClasses(styles);
  const [inputValue, setInputValue] = useState(value);
  const edgeMinValue = useMemo(() => inputValue === min, [min, inputValue]);
  const edgeMaxValue = useMemo(() => inputValue === max, [max, inputValue]);

  const limitExceeded =
    (value + 1) * weight + (selectedTotal - value * weight) > maxTotal;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleIncrement = () => {
    if (value >= max || limitExceeded) {
      return;
    }
    onChange(value + 1);
  };

  const handleDecrement = () => {
    if (value <= min) {
      return;
    }
    onChange(value - 1);
  };

  const submitInputValue = () => {
    const newValue = +inputValue || min;
    setInputValue(newValue);
    onChange(+inputValue || min);
  };

  const onInputChange = (ev) => {
    const newValueString = ev.target.value;
    const newValue = +newValueString;

    if (_isEmpty(newValueString) || !_isInteger(newValue)) {
      setInputValue('');
      return;
    }
    if (newValue < min) {
      setInputValue(min);
      return;
    }

    if (
      newValue * weight + (selectedTotal - value * weight) > maxTotal &&
      weight > 1
    ) {
      setInputValue(value);
      return;
    }
    if (newValue > max) {
      setInputValue(max);
      return;
    }

    setInputValue(newValue);
  };

  const onInputKeyDown = (ev) => {
    switch (ev.key) {
      case 'Enter':
        submitInputValue();
        return;

      case 'Escape':
        setInputValue(value);
        return;

      case 'ArrowUp':
        if (inputValue >= max) {
          setInputValue(max);
          return;
        }
        setInputValue(inputValue + 1);
        return;

      case 'ArrowDown':
        if (inputValue <= min) {
          setInputValue(min);
          return;
        }
        setInputValue(inputValue - 1);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.btnGroupContainer}>
      <div className={classes.buttonGroup}>
        <IconButton
          onClick={handleDecrement}
          className={classes.decIncButton}
          data-test-id="decrement-button"
          disabled={edgeMinValue || updateInProcess}
          size="large"
        >
          <FontAwesomeIcon icon={faMinus} className={classes.iconButton} />
        </IconButton>

        <input
          data-test-id="counter-input"
          className={classes.count}
          value={inputValue}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onBlur={submitInputValue}
        />

        <IconButton
          onClick={handleIncrement}
          className={classes.decIncButton}
          data-test-id="increment-button"
          disabled={
            edgeMaxValue || updateInProcess || (maxTotal && limitExceeded)
          }
          size="large"
        >
          <FontAwesomeIcon icon={faPlus} className={classes.iconButton} />
        </IconButton>
      </div>
    </div>
  );
}

IncDecBtnGroup.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  weight: PropTypes.number,
  maxTotal: PropTypes.number,
  selectedTotal: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  updateInProcess: PropTypes.bool,
};

IncDecBtnGroup.defaultProps = {
  min: 0,
  max: 50,
  weight: 1,
  maxTotal: undefined,
  selectedTotal: 1,
  updateInProcess: false,
};

export default IncDecBtnGroup;
