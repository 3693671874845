import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useNavigate, Link, useLocation, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@mui/material/Dialog';
import { MenuItem, Menu as StyledMenu } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react';
import PersonIcon from '@mui/icons-material/Person';

import Image from '../Image';
import { logoutRequest } from '../../../redux/legacy/reducers/ducks/legacy/AuthorizationDuck';
import {
  toggleSignIn,
  toggleAddress,
  toggleSignUp,
  toggleMenu,
  toggleCartModal as toggleCart,
} from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import Menu from '../Menu';
import CartContent from '../CartContent';
import SignInForm from '../../input/SignInForm';
import CartForm from '../../input/CartForm';
import OrderAddressFilter from '../../input/OrderAddressFilter';
import SignUpForm from '../../input/SignUpForm';
import ForgotPassword from '../ForgotPassword';
import { routeNames } from '../../../util/Constants';
import FilterLabel from '../FilterLabel/FilterLabel';
import EditTimeForm from '../../input/EditTimeForm';
import useDesktopCheck from '../../../util/hooks/UseDesktopCheck';
import * as orderActions from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';
import { getImage, getText, isRestaurantApp } from '../../../util/Helpers';
import { getConfig } from '../../../AppConfig';
import TakeoutOrDeliverySelector from '../../input/TakeoutOrDeliverySelector/TakeoutOrDeliverySelector';
import { clearSelectedRestaurant } from '../../../redux/legacy/reducers/ducks/legacy/RestaurantsDuck';
import { updatePhones } from '../../../redux/legacy/reducers/ducks/legacy/ProfileDuck';
import {
  setIsCheckout,
  setOrderAddress,
  setOrderTime,
  setOrderType,
} from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';
import useClasses from '../../../hooks/legacy/useClasses';
import { useUser } from '../../../hooks/useUser';
import { useWhen } from '../../../hooks/useWhen';
import { useWhere } from '../../../hooks/useWhere';
import getNearestTime from '../../../util/GetNearestTime';
import Notification from '../Notification';

import styles from './Navbar.style';

const config = getConfig();

const isRestaurant = isRestaurantApp();

// const validAddressPathRegex = /^\/restaurant(s)?(\/[a-zA-Z0-9]+)?$/g;
const validAddressPathRegex = /^\/restaurants?(\/)?$/g;
const validMenuPathRegex = /^\/restaurant\/[a-zA-Z0-9]+$/g;

const isAddressValidPage = (location) => {
  if (location.pathname.match(validAddressPathRegex)) {
    return true;
  }

  return false;
};

const inRestaurantMenuPage = (location) => {
  if (location.pathname.match(validMenuPathRegex)) {
    return true;
  }

  return false;
};

// TEMP WORKAROUND: This component should be the only one responsible for default selection of type, date and time in case one value is missing
function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setSelectedTime } = useWhen();
  const { setSelectedAddress } = useWhere();
  const isDesktop = useDesktopCheck();
  const classes = useClasses(styles);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [signIn, setSignIn] = useState(true);
  const inRestaurantes = routeNames.restaurants === location.pathname;
  const inCheckout = routeNames.checkout === location.pathname;
  const inRestaurant = location.pathname.includes(
    routeNames.restaurant.replace(':id', '')
  );

  const visibleSelectors =
    inRestaurantes ||
    inRestaurant ||
    routeNames.restaurantMenu === location.pathname ||
    routeNames.restaurantOrder === location.pathname;

  const logo = useMemo(() => {
    if (isRestaurant) {
      let result = [];
      if (config?.images?.length) {
        result = config.images.filter(
          (img) => img.name === 'site_logo' && img.version === 1
        );
      }

      return result?.length && result[0]?.url ? result[0].url : '';
    }
    return getImage(config, 'consumer_logo');
  }, [config]);

  const {
    cartItems,
    checkoutData,
    orderType,
    orderTypeOptions,
    isSignInOpen,
    isAddressOpen,
    userAddress,
    addresses,
    isSignUpOpen,
    isMenuOpen,
    isCartModalOpen,
    date,
    time,
    delays,
    selectedRestaurant,
    restaurantDetails,
    restaurantMenu,
  } = useSelector(
    ({
      main,
      order,
      profile,
      restaurant,
      address: selectedAddress,
      time: selectedTime,
    }) => ({
      cartItems: order.cartItems,
      checkoutData: order.checkoutData,
      orderType: order?.type,
      orderTypeOptions: order?.typeOptions,
      isSignInOpen: main.isSignInOpen,
      isAddressOpen: main.isAddressOpen,
      userAddress: selectedAddress.selectedAddress,
      addresses: profile.getAddressesResponse?.response,
      isSignUpOpen: main.isSignUpOpen,
      isMenuOpen: main.isMenuOpen,
      isCartModalOpen: main.isCartModalOpen,
      date: selectedTime.selectedDate,
      time: selectedTime.selectedTime,
      delays: restaurant?.selectedRestaurantMenu?.delays,
      selectedRestaurant: restaurant?.selectedRestaurantId,
      restaurantDetails: restaurant?.selectedRestaurantData,
      restaurantMenu: restaurant?.selectedRestaurantMenu,
    })
  );

  const { isLoggedIn } = useUser();

  const toggleSignInModal = () => dispatch(toggleSignIn());
  const toggleAddressModal = () => dispatch(toggleAddress());
  const toggleSignUpModal = () => dispatch(toggleSignUp());
  const toggleMenuModal = () => dispatch(toggleMenu());
  const toggleCartModal = () => dispatch(toggleCart());

  const [showOrderAddressFilter, setShowOrderAddressFilter] = useState(false);
  const OrderAddressFilterRef = useRef(null);

  const [showOrderTimeFilter, setShowOrderTimeFilter] = useState(false);
  const OrderTimeFilterRef = useRef(null);

  useEffect(() => {
    dispatch(setIsCheckout(inCheckout));
  }, [inCheckout, dispatch]);

  // Set default address when user is logged in and current address is missing
  // Avoids to do that when the address modal is open
  useEffect(() => {
    if (isAddressOpen) {
      return;
    }

    if (!userAddress && addresses && addresses.length > 0) {
      const targetAddress = addresses.find((i) => i.primary) ?? addresses[0];
      if (targetAddress) {
        const selectedAddress = {
          entered_address: `${targetAddress.address}, ${targetAddress.city}, ${targetAddress.state} ${targetAddress.zip}`,
          ...targetAddress,
        };
        setSelectedAddress(selectedAddress);
      }
    }
  }, [addresses]);

  useEffect(() => setSignIn(true), [isSignInOpen]);

  useEffect(() => {
    if (orderType) {
      let restoSchedule = null;
      if (isRestaurantApp() || inRestaurantMenuPage(location)) {
        if (!restaurantDetails) {
          return;
        }
        restoSchedule = restaurantDetails;
      }

      // Check if current time is still available otherwise ge the nearest one
      const nearestDateTime = getNearestTime(
        date,
        time,
        orderType,
        restoSchedule
      );

      if (!nearestDateTime) {
        // dispatch(setOrderType(previousOrderType));
        Notification.error(
          `Sorry, ${orderType?.label} not available at selected time`,
          {
            toastId: 'OrderTypeSelection',
          }
        );
        // TODO: we should show the time selector here instead
        return;
      }

      // deprecated use useWhen hook instead
      dispatch(setOrderTime(nearestDateTime));

      // save time using useWhen hook
      setSelectedTime(nearestDateTime);

      // Check if open then address modal
      if (orderType?.value === 'delivery' && !userAddress && !isAddressOpen) {
        if (isAddressValidPage(location)) {
          toggleAddressModal(true);
        }
      }
    } else {
      dispatch(setOrderType(orderTypeOptions[0]));
    }
  }, [orderType, restaurantDetails]);

  const stopPropagation = (ev) => ev.stopPropagation();

  const signOut = () => {
    dispatch(toggleSignIn(false));
    dispatch(orderActions.setIsCheckout(false));
    dispatch(orderActions.resetOrder());
    dispatch(clearSelectedRestaurant());
    dispatch(updatePhones([]));
    navigate(routeNames.home);
    dispatch(setOrderAddress(null));
    dispatch(logoutRequest());
  };

  const handleSignOut = () => {
    signOut();
    setAnchorEl(null);
  };

  const EditTimeFormSelector = (
    <Button
      ref={OrderTimeFilterRef}
      data-test-id="orderTime"
      onClick={() => setShowOrderTimeFilter(!showOrderTimeFilter)}
    >
      <FilterLabel time={time} date={date} delays={delays} />
      <KeyboardArrowDownIcon />
      <EditTimeForm
        data-test-id="edit-time-area"
        onClick={stopPropagation}
        open={showOrderTimeFilter}
        actions
        anchorEl={OrderTimeFilterRef?.current}
        onClose={() => setShowOrderTimeFilter(false)}
      />
    </Button>
  );

  const OrderAddressButtonSelector = (
    <Button
      ref={OrderAddressFilterRef}
      onClick={() => setShowOrderAddressFilter(!showOrderAddressFilter)}
      data-test-id="locationButton"
      role="presentation"
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <LocationOnIcon />
        {userAddress?.address || 'No address'}
        <KeyboardArrowDownIcon />
      </Stack>
      {showOrderAddressFilter && (
        <OrderAddressFilter
          data-test-id="order-address-area"
          onClick={stopPropagation}
          open={showOrderAddressFilter}
          actions
          anchorEl={OrderAddressFilterRef?.current}
          onClose={() => setShowOrderAddressFilter(false)}
        />
      )}
    </Button>
  );

  return (
    <AppBar position="static" elevation={1} color="default">
      <Toolbar
        sx={{
          padding: '8px',
          [theme.breakpoints.up('md')]: {
            padding: '8px 32px',
          },
          [theme.breakpoints.up('lg')]: {
            padding: '8px 100px',
          },
          [theme.breakpoints.up('xxl')]: {
            padding: '8px 20%',
          },
          backgroundColor: 'white',
        }}
        data-test-id="default-header"
      >
        {inCheckout ? (
          <Button
            onClick={() => navigate(`/restaurant/${selectedRestaurant}`)}
            data-test-id="go-back-button"
            label="Go back to menu"
            aria-hidden="true"
            startIcon={<ArrowBackIcon />}
          >
            <Box display={{ xs: 'none', sm: 'flex' }}>Go back to menu</Box>
          </Button>
        ) : (
          <IconButton
            onClick={toggleMenuModal}
            sx={{ color: 'black' }}
            data-test-id="toggle-menu"
            aria-hidden="true"
          >
            <MenuIcon sx={{ fontSize: 30 }} />
          </IconButton>
        )}
        <Link to={isRestaurant ? routeNames.home : routeNames.restaurants}>
          <Stack
            sx={{ mx: 1 }}
            direction="row"
            spacing={0.5}
            justifyContent="center"
            alignItems="center"
          >
            {logo && !isRestaurant && (
              <Image
                alt="Logo"
                src={logo}
                width={32}
                height={32}
                data-test-id="default-header-image"
              />
            )}
            <Typography
              sx={{ fontWeight: 700 }}
              variant="body1"
              data-test-id="default-header-text"
              display="flex"
            >
              {isRestaurant
                ? restaurantDetails?.name
                : getText(
                    config,
                    'consumer_toolbar_title',
                    config.textsPlaceholder.consumer_toolbar_title
                  )}
            </Typography>
          </Stack>
        </Link>
        {isDesktop && visibleSelectors && (
          <Stack spacing={2} direction="row">
            <Divider orientation="vertical" flexItem />
            <TakeoutOrDeliverySelector typeVersion="ToggleGroup" />
            <Divider orientation="vertical" flexItem />
            {EditTimeFormSelector}
            <Divider orientation="vertical" flexItem />
            {OrderAddressButtonSelector}
            <Divider orientation="vertical" flexItem />
          </Stack>
        )}
        <Box sx={{ flexGrow: 1 }} />

        {!isLoggedIn && !inCheckout && (
          <Button
            color="primary"
            size="medium"
            variant="text"
            onClick={toggleSignInModal}
            data-test-id="login-button"
            aria-hidden="true"
          >
            Log in
          </Button>
        )}
        {isLoggedIn && (
          <div>
            <Button
              data-test-id="person-icon"
              style={{
                background: 'transparent',
                color: 'black',
                fontSize: '15px',
                fontWeight: '700',
              }}
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
            >
              <IconButton
                className={classes.buttonIconColor}
                data-test-id="person-icon-button"
              >
                <PersonIcon className={classes.personIconColor} />
              </IconButton>
            </Button>
            <StyledMenu
              elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <NavLink
                data-test-id="header-menue-profile"
                to="/account-details/account"
              >
                <MenuItem onClick={handleClose} data-test-id="go-to-profile">
                  Go to Profile
                </MenuItem>
              </NavLink>
              <MenuItem
                data-test-id="profileAction_signOut"
                onClick={handleSignOut}
              >
                Log out
              </MenuItem>
            </StyledMenu>
          </div>
        )}
        {(inRestaurant || inRestaurantes) && restaurantMenu && (
          <Box sx={{ ml: 1 }} display={{ xs: 'none', md: 'flex' }}>
            <CartContent
              count={checkoutData?.isEnded ? 0 : cartItems.length}
              cartAction={checkoutData?.isEnded ? undefined : toggleCartModal}
            />
          </Box>
        )}
        <Menu
          open={isMenuOpen}
          onClose={toggleMenuModal}
          openLogin={toggleSignInModal}
          openSignUp={toggleSignUpModal}
          isAuthenticated={isLoggedIn}
        />
        <Dialog open={isSignInOpen}>
          {signIn ? (
            <SignInForm
              onClose={toggleSignInModal}
              forgotPassword={() => setSignIn(false)}
            />
          ) : (
            <ForgotPassword
              onClose={toggleSignInModal}
              backToSignIn={() => setSignIn(true)}
            />
          )}
        </Dialog>
        <Dialog open={isSignUpOpen}>
          <SignUpForm onClose={toggleSignUpModal} />
        </Dialog>
        {isAddressOpen && (
          <Dialog open={isAddressOpen} maxWidth="md">
            <OrderAddressFilter
              isModal="true"
              onClose={() => {
                dispatch(toggleAddress(false));
              }}
            />
          </Dialog>
        )}
        <Dialog
          open={isCartModalOpen}
          onClose={toggleCartModal}
          data-test-id="cart-modal"
        >
          <Box>
            <IconButton
              size="small"
              onClick={toggleCartModal}
              data-test-id="closeButton"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            p={3}
            maxWidth="400px"
            minWidth="260px"
            sx={{ fontWeight: 700 }}
          >
            Your Cart
          </Typography>

          <CartForm closeModal={toggleCartModal} />
        </Dialog>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
