import theme from '../../layout/Theme';

const styles = (materialTheme) => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  addressInput: {
    borderRadius: 8,
    width: '100%',
    marginLeft: 1,
    border: '1px solid',
    fontSize: theme.font.size.md,
    height: 44,
    padding: '0 32px',
    color: theme.colors.mainText,
    '&:hover': {
      border: '1px solid',
      borderColor: materialTheme.palette.common.black,
    },
    '&:focus': {
      border: '1px solid',
      borderColor: materialTheme.palette.success.light,
    },
  },
  newAddressInput: {
    borderRadius: 8,
    width: '100%',
    marginLeft: 1,
    border: '1px solid',
    height: 44,
    padding: '0 14px',
    color: materialTheme.palette.primary.dark,
  },
  clearAddress: {
    position: 'relative',
    marginLeft: -35,
    marginTop: 5,
    cursor: 'pointer',
    padding: 5,
    color: theme.colors.placeholder,
  },
  addressPin: {
    position: 'relative',
    marginRight: -35,
    marginTop: 5,
    cursor: 'pointer',
    padding: 5,
    color: theme.colors.placeholder,
  },
});

export default styles;
