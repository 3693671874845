import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Close from '@mui/icons-material/Close';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import PersonPinCircleSharpIcon from '@mui/icons-material/PersonPinCircleSharp';
import {
  TextField,
  List,
  ListItemText,
  ListItemButton,
  Box,
} from '@mui/material';

import { googleMapsErrorState } from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import { getConfig } from '../../../AppConfig';
import useClasses from '../../../hooks/legacy/useClasses';
import { setOrderAddress } from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';
import { useWhere } from '../../../hooks/useWhere';

import styles from './GoogleAutoCompleteInput.style';

const config = getConfig();

const debounceSearchMinLength = 2;
const autocompleteMaxResults = 10;

export default function GoogleAutoCompleteInput({
  showIcon,
  streetAddress,
  setStreetAddress,
  disabled,
}) {
  const dispatch = useDispatch();
  const classes = useClasses(styles);
  const { setSelectedAddress } = useWhere();
  const [onSearch, setOnSearch] = useState(true);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: config.keys.googleAutocomplete,
    options: {
      types: ['address'],
      componentRestrictions: { country: 'usa' },
    },
  });

  const [addressString, setAddressString] = useState('');

  const { address, googleMapsErrorStates } = useSelector(
    ({ address: selectedAddress, main, profile }) => ({
      address: selectedAddress.selectedAddress || null,
      googleMapsErrorStates: main.googleMapsErrorState,
      addresses: profile.getAddressesResponse?.response,
    })
  );

  useEffect(() => {
    if (address) {
      setAddressString(address?.entered_address || '');
      dispatch(googleMapsErrorState(false));
    }
  }, [address]);

  const onInputChange = (evt) => {
    if (!onSearch) {
      setOnSearch(true);
    }

    if (!evt.target.value && Object.keys(streetAddress).length > 0) {
      setStreetAddress({ ...streetAddress, route: '' });
    }
    setAddressString(evt.target.value);

    if (evt.target.value.length > debounceSearchMinLength) {
      getPlacePredictions({ input: evt.target.value });
    }
  };

  const onPlaceSelected = (place) => {
    setOnSearch(false);

    placesService?.getDetails(
      {
        placeId: place.place_id,
      },
      (placeDetails) => {
        const {
          formatted_address: formattedAddress,
          address_components: addressComponents,
          place_id: placeId,
          geometry,
        } = placeDetails;

        if (!geometry || !placeId) {
          return;
        }

        const getAddressItem = (key) =>
          addressComponents?.find((val) => val.types.includes(key))?.short_name;

        const route = getAddressItem('route');
        const number = getAddressItem('street_number');
        const city =
          getAddressItem('locality') ||
          getAddressItem('administrative_area_level_2');

        const state = getAddressItem('administrative_area_level_1');
        const zip = getAddressItem('postal_code');

        const lat =
          typeof geometry.location.lat === 'number'
            ? geometry.location.lat
            : geometry.location.lat();
        const lng =
          typeof geometry.location.lat === 'number'
            ? geometry.location.lng
            : geometry.location.lng();

        // Use the new redux store for address
        const selectedAddress = {
          entered_address: formattedAddress,
          address: `${number ?? ''} ${route}`,
          city,
          state,
          zipcode: zip,
          latitude: lat,
          longitude: lng,
          placeId,
        };
        setSelectedAddress(selectedAddress);

        setAddressString(formattedAddress);

        if (setStreetAddress) {
          setStreetAddress({ route, number, city, state, zip });
        }
      }
    );
  };

  const clearAddress = () => {
    dispatch(setOrderAddress(null));
    setAddressString('');
    setOnSearch(true);
  };

  return (
    <div>
      <Box className={classes.inputContainer}>
        <span className={classes.addressPin} data-test-id="address-pin">
          {!showIcon && (
            <PersonPinCircleSharpIcon
              color={googleMapsErrorStates ? 'error' : 'primary'}
            />
          )}
        </span>
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          placeholder="Enter delivery address"
          style={{
            borderColor: googleMapsErrorStates ? '#EE1411' : '#D1D1D1',
            width: '100%',
            paddingLeft: '32px',
            height: '50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          sx={{
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          className={showIcon ? classes.newAddressInput : classes.addressInput}
          onChange={onInputChange}
          loading={isPlacePredictionsLoading}
          value={addressString}
          data-test-id="autocomplete-search"
          type="text"
          disabled={disabled}
        />
        {addressString && !showIcon && (
          <span
            className={classes.clearAddress}
            onClick={clearAddress}
            data-test-id="clear-address"
            aria-hidden="true"
            disabled={disabled}
          >
            <Close />
          </span>
        )}
      </Box>
      {addressString &&
        addressString.length > debounceSearchMinLength &&
        onSearch &&
        placePredictions !== null &&
        placePredictions.length > 0 &&
        !disabled && (
          <Box>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                overflow: 'auto',
                maxHeight: 120,
              }}
            >
              {placePredictions.map((item, index) => {
                if (index > autocompleteMaxResults) {
                  // max number of results
                  return null;
                }
                return (
                  <Box sx={{ borderRadius: '16px' }}>
                    <ListItemButton
                      data-test-id={`google-autocomplete-item-${item?.place_id}`}
                    >
                      <ListItemText
                        primary={item?.description}
                        primaryTypographyProps={{ fontSize: '14px' }}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'left',
                          alignItems: 'left',
                          textAlign: 'left',
                        }}
                        data-test-id={`google-autocomplete-item-action-${item?.place_id}`}
                        onClick={() => onPlaceSelected(item)}
                      />
                    </ListItemButton>
                  </Box>
                );
              })}
            </List>
          </Box>
        )}
    </div>
  );
}

GoogleAutoCompleteInput.propTypes = {
  setStreetAddress: PropTypes.func,
  showIcon: PropTypes.bool,
  streetAddress: PropTypes.string,
  disabled: PropTypes.bool,
};

GoogleAutoCompleteInput.defaultProps = {
  setStreetAddress: () => null,
  showIcon: false,
  streetAddress: '',
  disabled: false,
};
