import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectedTime,
  SelectedDate,
} from '../redux/legacy/reducers/ducks/new/Time/Time.types';
import {
  setSelectedTime as storeSelectedTime,
  setSelectedDate as storeSelectedDate,
} from '../redux/legacy/reducers/ducks/new/Time/TimeReducer';
import {
  selectSelectedTime,
  selectSelectedDate,
} from '../redux/legacy/reducers/ducks/new/Time/TimeSelector';

export function useWhen() {
  const dispatch = useDispatch();
  const storedTime = useSelector(selectSelectedTime);
  const storedDate = useSelector(selectSelectedDate);

  const setSelectedTime = (time: SelectedTime | null) =>
    dispatch(storeSelectedTime(time));

  const setSelectedDate = (date: SelectedDate | null) =>
    dispatch(storeSelectedDate(date));

  const selectedTime = useMemo(() => {
    if (storedTime) {
      const now = new Date();
      const nowEpoch = Math.round(now.getTime() / 1000);

      if (storedTime.type === 'asap') {
        return storedTime;
      }

      // TODO: this next one should calculate the delay given the business/restaurants page and validate against it
      if (nowEpoch < storedTime.start) {
        return storedTime;
      }
      return null;
    }

    return null;
  }, [storedTime]);

  const selectedDate = useMemo(() => {
    if (storedDate) {
      // Check if the storedDate is in the past
      // TODO

      return storedDate;
    }

    return null;
  }, [storedTime]);

  return {
    selectedTime,
    selectedDate,
    setSelectedTime,
    setSelectedDate,
  };
}

export default useWhen;
