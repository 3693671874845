import theme from '../../layout/Theme';

const styles = (materialTheme) => ({
  form: {
    background: '#FFF',
    padding: 20,
    borderRadius: '20px',
  },
  header: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '16px',
    backgroundColor: '#fff !important',
  },
  headerTitle: {
    color: 'black',
    flex: 1,
    fontWeight: theme.font.weight.boldest,
    fontSize: theme.font.size.xl,
    lineHeight: theme.font.lineHeight.xl,
  },
  buttonGroup: {
    '&:not(:first-child)': {
      borderLeft: '1px solid #D1D1D1',
    },
  },
  formRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  toggleButtonGroup: {
    height: 50,
  },
  timeSelectionNotAvailable: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '14px 0px',
  },
  dateButton: {
    '&&': {
      borderRadius: '1px',
    },
    padding: '14px 0px',
    margin: '-1px 0',

    '&:first-child': {
      marginLeft: '-1px',
      borderTopLeftRadius: '16px',
      borderBottomLeftRadius: '16px',
      flex: 1.25,
    },
    '&:last-child': {
      marginRight: '-1px',
      borderTopRightRadius: '16px',
      borderBottomRightRadius: '16px',
    },
  },
  dateButtonLabel: {
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
    fontWeight: theme.font.weight.bold,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
  },
  updateButton: {
    '&&': {
      boxShadow: 'none',
    },
    marginTop: 40,
    backgroundColor: materialTheme.palette.success.main,
    color: materialTheme.palette.common.white,
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    fontWeight: theme.font.weight.boldest,
    borderRadius: 8,
    marginBottom: 25,
    '&:hover': {
      backgroundColor: materialTheme.palette.success.light,
    },
  },
  cancelButton: {
    '&&, &&:hover': {
      boxShadow: 'none',
    },
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    fontWeight: theme.font.weight.boldest,
    borderRadius: '11px',
    backgroundColor: 'rgba(0,0,0,0.05)',
    padding: '8px 12px',

    '&& svg': {
      color: theme.colors.semiDarkText,
      fontSize: theme.font.size.md,
      lineHeight: theme.font.lineHeight.md,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftButton: {
    marginRight: '10px',
  },
  dayOfMonth: {
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
  },
  relativeFromToday: {
    fontSize: theme.font.size.xs,
    lineHeight: theme.font.lineHeight.xs,
  },
  option: {
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
  },
  selectIcon: {
    '&&': {
      fill: theme.colors.semiDarkText,
    },
  },
  select: {
    borderRadius: 16,
    height: 48,
  },
  selectRoot: {
    padding: '14px 16px',
  },
  selectDateColor: {
    borderRadius: '8px !important',
    color: theme.colors.contrastText,
    backgroundColor: `${materialTheme.palette.secondary.light} !important`,
  },
  UnSelectDateColor: {
    backgroundColor: `${theme.colors.grey110} !important`,
  },
  dialogWidth: {
    width: '390px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.screenSize.mobile]: {
      width: '300px',
      height: '345px',
      overflow: 'hidden',
    },
  },
});

export default styles;
