const errorMessages = {
  DELETE_ADDRESS: 'Delete address error',
  DELETE_PHONE: 'Delete phone error',
  ADDRESS_NOT_FOUND: 'Address not found',
  ADDRESS_OUTSIDE_DELIVERY_COVERAGE: 'Address outside delivery coverage',
  PLEASE_UPDATE_YOUR_DELIVERY_ADDRESS: 'Please update your delivery address',
  ITEM_NOT_AVAILABLE: 'Item not available',
  RESTAURANT_IS_NOT_AVAILABLE:
    'The Restaurant is not available on this date and time',
  PASSWORD_NOT_MATCH: 'passwords does not match',
  CART_DATA_NOT_FOUND: 'cart data not found',
  NEW_PASSWORD_DIFFERENT: 'New passwords are different',
  OLD_PASSWORD_WRONG: 'Old password wrong',
  CART_NOT_SET: 'Cart not set!',
  PHONE_NUMBER_WIDTH: 'Phone number format not valid',
  STRIPE_URL_IS_MISSING: 'Sorry, we cannot process your payment right now',
};

export default errorMessages;
