import React, { memo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { routeNames } from '../../../util/Constants';
import RoleProtector from '../../layout/RoleProtector';

import routes from './routes';

const routesWithProtected = routes.map((route) => {
  const Component = route.component;

  function WrapperComponent() {
    return (
      <RoleProtector
        key={route.path}
        role={route?.role || 'none'}
        redirect={route.redirect || routeNames.home}
      >
        <Component />
      </RoleProtector>
    );
  }

  return {
    ...route,
    component: memo(WrapperComponent),
  };
});

function Router() {
  return (
    <Routes>
      {routesWithProtected.map((route) =>
        route?.redirect ? (
          <Route
            key={route.path}
            path={route.path}
            element={<Navigate to={route.redirect} />}
          />
        ) : (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component />}
          />
        )
      )}
      <Route path="*" element={<Navigate to={routeNames.notfound} />} />
    </Routes>
  );
}

export default Router;
