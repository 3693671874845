import { put, takeLeading, call } from 'redux-saga/effects';

import { getStripe } from '../../../../../util/api/StripeApi';
import {
  getStripeRequest,
  getStripeResponse,
} from '../../../reducers/ducks/legacy/StripeDuck';

export function* getStripeSaga({ payload }) {
  try {
    const { showError, ...stripePayload } = payload;

    const response = yield call(getStripe, stripePayload);

    yield put(
      getStripeResponse({
        response,
      })
    );
  } catch (error) {
    yield put(
      getStripeResponse({
        error,
      })
    );
  }
}

export function* watchStripeSagas() {
  yield takeLeading(getStripeRequest.type, getStripeSaga);
}
