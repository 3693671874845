import { lazy } from 'react';

import { routeNames } from '../../../util/Constants';

const Home = lazy(() => import('../../page/Home/Home'));

const menuLinks = [
  {
    icon: '',
    name: 'home',
    title: 'Home',
    component: Home,
    path: routeNames.home,
  },
];

export default menuLinks;
