import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import 'react-toastify/dist/ReactToastify.min.css';
import _ from 'lodash';
import { JsonLd } from 'react-schemaorg';

import { getConfig } from '../../../AppConfig';
import { getImage, getText, isRestaurantApp } from '../../../util/Helpers';
import useSeo from '../../../hooks/useSeo';

const config = getConfig();
const isRestaurant = isRestaurantApp();

function SeoProvider() {
  const location = useLocation();

  const { restaurantId, restaurantDetails } = useSelector(({ restaurant }) => ({
    restaurantDetails: restaurant?.selectedRestaurantData,
    restaurantId: restaurant?.selectedRestaurantId,
  }));

  const {
    pageTitle: currentPageTitle,
    pageDescription: currentPageDescription,
    pageKeywords: currentPageKeywords,
    jsonItem,
    canonicalURL,
    reset: resetSeoData,
  } = useSeo();

  useEffect(() => {
    resetSeoData();
  }, [location]);

  const mainDomain =
    config?.domains && config?.domains.filter((d) => d?.default === true);
  const defaultCanonicalDomain =
    mainDomain && mainDomain.length > 0 ? mainDomain[0].domain : null;

  const websiteURL = window.location.origin;
  const websiteName = isRestaurant
    ? getText(config, 'site_title', config.textsPlaceholder.consumer_tab_title)
    : getText(
        config,
        'consumer_tab_title',
        config.textsPlaceholder.consumer_tab_title
      );
  const websiteTitle = isRestaurant
    ? getText(config, 'site_title', config.textsPlaceholder.consumer_tab_title)
    : getText(
        config,
        'consumer_tab_title',
        config.textsPlaceholder.consumer_tab_title
      );
  const websiteDescription =
    currentPageDescription ?? isRestaurant
      ? getText(
          config,
          'site_description',
          config.locales.consumer_meta_description
        )
      : getText(
          config,
          'consumer_meta_description',
          config.textsPlaceholder.consumer_meta_description
        );
  const websiteKeywords = isRestaurant
    ? getText(
        config,
        'site_keywords',
        config.textsPlaceholder.consumer_meta_keywords
      )
    : getText(
        config,
        'consumer_meta_keywords',
        config.textsPlaceholder.consumer_meta_keywords
      );
  const websiteOgType = isRestaurant
    ? 'restaurant.restaurant'
    : 'business.business';
  const websiteBanner = isRestaurant
    ? getImage(config, 'first_slide', null)
    : getImage(config, 'consumer_header_main', null);

  const websiteIcon = isRestaurant
    ? getImage(config, 'favicon', null)
    : getImage(config, 'consumer_favicon', null);

  const canonical = canonicalURL || defaultCanonicalDomain;

  return (
    <>
      <Helmet>
        <title>
          {currentPageTitle
            ? `${currentPageTitle} - ${websiteTitle}`
            : websiteTitle}
        </title>
        <meta
          property="og:title"
          content={
            currentPageTitle
              ? `${currentPageTitle} - ${websiteTitle}`
              : websiteTitle
          }
        />
        <meta property="og:site_name" content={websiteName} />
        <meta property="og:url" content={websiteURL} />
        <meta property="og:type" content={websiteOgType} />
        <meta
          name="description"
          content={currentPageDescription ?? websiteDescription}
        />
        <meta
          property="og:description"
          content={currentPageDescription ?? websiteDescription}
        />
        <meta property="og:locale" content="en_US" />
        <meta
          name="keywords"
          content={currentPageKeywords ?? websiteKeywords}
        />
        {!_.isNil(websiteBanner) && !_.isNil(window?.location?.protocol) && (
          <meta
            property="og:image"
            content={`${window.location.protocol}${websiteBanner}`}
          />
        )}
        {!_.isNil(websiteIcon) && !_.isNil(window?.location?.protocol) && (
          <link
            rel="apple-touch-icon"
            href={`${window.location.protocol}${websiteIcon}`}
          />
        )}
        {!_.isNil(websiteIcon) && !_.isNil(window?.location?.protocol) && (
          <link rel="icon" href={`${window.location.protocol}${websiteIcon}`} />
        )}
        <meta name="business_timezone" content={config.timezone} />
        <meta name="app_version" content={config.version} />
        {!_.isNil(canonical) && (
          <link
            rel="canonical"
            href={`https://${canonical}${window.location.pathname}`}
          />
        )}
      </Helmet>
      {isRestaurant && restaurantDetails && restaurantId && (
        <JsonLd
          item={
            jsonItem ?? {
              '@context': 'https://schema.org',
              '@type': 'Restaurant',
              name: websiteName,
              description: websiteDescription,
              keywords: websiteKeywords,
              telephone: restaurantDetails.phone,
              url: `${window.location.origin}`,
              address: {
                '@type': 'PostalAddress',
                streetAddress: restaurantDetails.address,
                addressLocality: restaurantDetails.city,
                addressRegion: restaurantDetails.state,
                postalCode: restaurantDetails.zipcode,
                addressCountry: 'US',
              },
              geo: {
                '@type': 'GeoCoordinates',
                latitude: restaurantDetails.latitude,
                longitude: restaurantDetails.longitude,
              },
              menu: `${window.location.origin}/menu`,
              servesCuisine:
                _.startCase(restaurantDetails?.tags[0]?.name) || 'American',
              image: [`${window.location.protocol}${websiteBanner}`],
              publicAccess: true,
            }
          }
        />
      )}
      {!isRestaurant && (
        <JsonLd
          item={
            jsonItem ?? {
              '@context': 'https://schema.org',
              '@type': 'OnlineBusiness',
              name: websiteName,
              description: websiteDescription,
              keywords: websiteKeywords,
              image: [`${window.location.protocol}${websiteBanner}`],
              url: `${window.location.origin}`,
              logo: websiteIcon,
            }
          }
        />
      )}
    </>
  );
}

export default SeoProvider;
