import axios from 'axios';

import { getConfig } from '../../AppConfig';

const config = getConfig();

const customAxios = axios.create({
  baseURL: config.apiBaseUrl,
  paramsSerializer: {
    indexes: true,
  },
});

export default customAxios;
