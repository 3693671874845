import { all } from 'redux-saga/effects';

// legacy sagas
import { watchAuthorizationSagas as watchAuthorizationSagasLegacy } from './legacy/AuthorizationSaga';
import { watchProfileSagas as watchProfileSagasLegacy } from './legacy/ProfileSaga';
import { watchOrderSagas as watchOrderSagasLegacy } from './legacy/OrderSaga';
import { watchRestaurantsSagas as watchRestaurantsSagasLegacy } from './legacy/RestaurantsSaga';
import { watchStripeSagas as watchStripeSagasLegacy } from './legacy/StripeSaga';

// new sagas
import { watchRestaurantSagas } from './new/RestaurantSaga';

export default function* rootSaga() {
  yield all([
    // legacy
    watchAuthorizationSagasLegacy(),
    watchProfileSagasLegacy(),
    watchOrderSagasLegacy(),
    watchRestaurantsSagasLegacy(),
    watchStripeSagasLegacy(),
    // new
    watchRestaurantSagas(),
  ]);
}
