import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedAddress } from '../redux/legacy/reducers/ducks/new/Address/Address.types';
import { setSelectedAddress as storeSelectedAddress } from '../redux/legacy/reducers/ducks/new/Address/AddressReducer';
import { selectSelectedAddress } from '../redux/legacy/reducers/ducks/new/Address/AddressSelector';

export function useWhere() {
  const dispatch = useDispatch();
  const storedAddress = useSelector(selectSelectedAddress);

  const setSelectedAddress = (address: SelectedAddress | null) =>
    dispatch(storeSelectedAddress(address));

  const selectedAddress = useMemo(
    () =>
      // This should check if the logged in user is the same, if not reset the address user data and update
      // TODO
      storedAddress,
    [storedAddress]
  );

  return {
    selectedAddress,
    setSelectedAddress,
  };
}

export default useWhere;
