import theme from '../../layout/Theme';

const styles = (materialTheme) => ({
  root: {},
  emptyCart: {
    paddingInline: '24px',
  },
  emptyCartLabel: {
    textAlign: 'center',
    marginBottom: '116px',
    color: theme.colors.lightGray3,
    fontWeight: theme.font.weight.extrabold,
  },
  listItemWrapper: {
    paddingInline: '8px',
    marginTop: '20px',

    '& > *:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.mediumGray120}`,
      marginBottom: '16px',
    },
  },
  listItem: {
    '&&': {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
    },

    '&.MuiListItem-gutters': {
      padding: '0 0  18px 0',
    },
  },
  restName: {
    fontWeight: theme.font.weight.boldest,
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    marginBottom: '5px',
  },
  cartItems: {
    color: theme.colors.lightGray3,
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
  },
  comment: {
    display: 'flex',
    maxWidth: '300px',
    wordBreak: 'break-word',
  },
  commentIcon: {
    marginRight: '10px',
    width: '17px',
    height: '17px',
  },
  commentText: {
    color: theme.colors.lightGray3,
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
  },
  specialCommentText: {
    color: theme.colors.lightGray3,
    fontSize: theme.font.size.sm,
    listStyleType: 'circle',
    marginLeft: '15px',
    lineHeight: theme.font.lineHeight.sm,
  },
  cartItemPanel: {
    marginTop: '10px',
    marginRight: '60px',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  trashIcon: {
    width: '12px',
    padding: '5px',

    fontSize: theme.font.size.xs,
    lineHeight: theme.font.lineHeight.xs,

    color: theme.colors.lightGray3,

    '&.MuiButton-root.btn-icon': {
      backgroundColor: '#fff',
    },
  },

  penIcon: {
    width: '12px',
    padding: '0px',

    fontSize: theme.font.size.xs,
    lineHeight: theme.font.lineHeight.xs,

    color: theme.colors.lightGray3,

    '&.MuiButton-root.btn-icon': {
      backgroundColor: '#fff',
    },
  },
  orderPriceDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 23px 0 ',
    borderTop: `1px solid ${theme.colors.mediumGray120}`,

    '& > :last-child:not(.priceNumber)': {
      borderBottom: 'none',
    },
    '& > :not(:first-child):not(:last-child)': {
      paddingBottom: '5px',
      paddingTop: '7px',
    },
  },
  orderPriceDetailsFinalForm: {
    display: 'flex',
    justifyContent: 'center',
  },
  orderPriceItemFinalForm: {
    padding: '5px 0px',
  },
  orderDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  priceTitle: {
    color: theme.colors.lightGray3,
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    marginRight: '4px',
  },
  priceTitleTotal: {
    color: theme.colors.semiDarkText,
    textTransform: 'capitalize',
    paddingTop: '20px',
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
  },
  priceNumberTotal: {
    paddingTop: '20px',
    fontFamily: theme.font.family.main,
    fontSize: theme.font.size.xl2,
    lineHeight: theme.font.lineHeight.xl2,
    color: '#000',
    fontWeight: theme.font.weight.boldest,
  },
  priceTitleColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '10px',
  },
  priceNumber: {
    color: theme.colors.lightGray3,
  },
  total: {
    '& p': {
      fontSize: theme.font.size.md,
      lineHeight: theme.font.lineHeight.md,
      fontWeight: theme.font.weight.boldest,
      color: '#000',
    },
    '&:first-child': {
      textTransform: 'capitalize',
    },
    '&:nth-child(2)': {
      fontWeight: theme.font.weight.boldest,
    },
  },
  incDecreaseCountPanel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  price: {
    color: '#000',
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,

    marginLeft: '24px',
    [theme.screenSize.desktop]: {
      fontSize: theme.font.size.lg,
      lineHeight: theme.font.lineHeight.lg,
    },
  },
  tips: {
    minWidth: '300px',
    alignItems: 'center',
    borderBottom: '1px solid #efefef',
  },
  tipsItem: {
    color: theme.colors.semiDarkText,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    width: '40px',
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
    fontWeight: theme.font.weight.boldest,
  },
  activeTip: {
    '&&': {
      color: theme.colors.contrastText,
      backgroundColor: theme.colors.primary.main,
    },
  },
  tipsGroups: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      borderRadius: '8px 0px 0px 8px',
    },
    '& > :last-child': {
      borderRadius: '0px 8px 8px 0px',
    },
    '& > :not(:last-child)': {
      marginRight: '2px',
    },
  },
  tipPrice: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&&&&': {
      marginLeft: 'auto',
    },
  },
  checkout: {
    textAlign: 'center',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: '14px',
  },
  cartOpenCheckout: {
    textAlign: 'center',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: '14px',
    marginBottom: '24px',
  },
  checkoutButton: {
    marginBottom: '40px',
    [theme.screenSize.desktop]: {
      marginTop: '10px',
      height: '50px',
    },

    '&.MuiButton-contained:hover': {
      backgroundColor: '#a3e6c4',
    },
  },
  checkoutArrowIcon: {
    padding: '3.6px',
    marginLeft: '10px',
  },
  giftCode: {
    display: 'flex',
    padding: '0px 18px 20px',
    height: '304px',
  },
  giftCodeSubmitBtn: {
    padding: '10px 16px',
    height: '48px',
    width: '70px',
    marginTop: 'auto',
    borderRadius: '0 12px 12px 0',

    '&.MuiButton-root.MuiButton-contained': {
      boxShadow: 'none',
    },

    '&.MuiButton-contained:hover': {
      backgroundColor: '#a3e6c4',
    },
  },
  couponInput: {
    borderRadius: '12px',
  },
  couponField: {
    height: '41px',
    marginTop: 'auto',
    borderRadius: '12px 0 0 12px',

    '& .MuiOutlinedInput-input': {
      fontSize: theme.font.size.sm,
      lineHeight: theme.font.lineHeight.sm,

      '&::placeholder': {
        color: `${theme.colors.lightGray3} !important`,
        fontWeight: theme.font.weight.medium,
        fontFamily: `${theme.font.family.main} !important`,
        opacity: '.8 !important',
      },
    },
  },
  minimumOrder: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
    fontWeight: theme.font.weight.bold,
    textTransform: 'uppercase',
    color: theme.colors.orange,
    boxShadow: '1px 0 1px 1px rgba(233, 233, 233, 0.8)',
  },
  successFeesList: {
    paddingBottom: '24px',
    [theme.screenSize.laptop]: {
      paddingBottom: '0px',
    },
  },
  successFeeItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingInline: '23px',
    marginBottom: '14px',

    color: theme.colors.semiDarkText,
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,

    '&:last-child': {
      marginBottom: '0px',
    },
  },
  successFeeName: {
    textAlign: 'right',
    color: theme.colors.lightText2,
    fontWeight: theme.font.weight.medium,
  },
  successFeeTotal: {
    fontWeight: `${theme.font.weight.extrabold} !important`,
    color: '#000 !important',
    opacity: '1',
  },
  successFeeValue: {
    alignSelf: 'flex-end',
    color: theme.colors.lightText2,
    fontWeight: theme.font.weight.medium,
  },
  successFeeValueOldPrice: {
    color: theme.colors.lightGray3,
    textDecoration: 'line-through',
    marginLeft: 'auto',
    marginRight: '10px',
  },
  activeCoupon: {
    padding: '0 23px 40px',
    display: 'flex',
    alignItems: 'center',
  },
  couponIcon: {
    boxSizing: 'border-box',
    backgroundColor: theme.colors.success.main,
    padding: '16px',
    height: '82px',
    width: '64px',
    borderRadius: '8px 0 0 8px',
  },
  couponInfo: {
    boxSizing: 'border-box',
    padding: '14px 14px 14px 24px',
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
    height: '82px',

    display: 'flex',
    alignItems: 'center',
    flex: 1,
    background: '#FAFAFA',
    borderRadius: '0 8px 8px 0',
  },
  couponTitle: {
    color: theme.colors.success.main,
    fontWeight: theme.font.weight.extrabold,
  },
  iconColor: {
    color: materialTheme.palette.common.black,
    [theme.screenSize.mobile]: {
      fontSize: theme.font.size.xl,
    },
  },
  gridColors: {
    color: materialTheme.palette.common.black,
    border: `1px solid ${materialTheme.palette.secondary.dark}`,
    background: '#FFFFFF',
    padding: '10px 12px',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    width: '35%',
    cursor: 'pointer',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    [theme.screenSize.mobile]: {
      fontSize: theme.font.size.xl,
    },
  },
  activeTipColor: {
    color: materialTheme.palette.common.black,
    border: `1px solid ${materialTheme.palette.secondary.dark}`,
    background: theme.colors.white12,
    padding: '10px 12px',
    margin: '5px',
    width: '35%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    [theme.screenSize.mobile]: {
      fontSize: theme.font.size.xl,
    },
  },
  tipParentBox: {
    display: 'flex',
    marginTop: '4px',
    justifyContent: 'space-between',
    '& total': {
      paddingTop: '13px',
    },
  },
  listParent: {
    display: 'flex',
  },
  quantityButton: {
    color: theme.colors.mainText,
    backgroundColor: theme.colors.secondaryBackground,
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    marginLeft: '12px',
    marginTop: '20px',
    fontSize: theme.font.size.sm1,
  },
  orderMaterial: {
    marginLeft: '15px',
  },
  borderTop: {
    borderTop: `1px solid ${theme.colors.mediumGray120}`,
    paddingTop: '10px',
  },
});

export default styles;
