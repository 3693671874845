import { put, call, takeLeading, all, select } from 'redux-saga/effects';

import {
  authorize as apiAuthorize,
  getProfile as apiGetProfile,
  signOut as apiSignOut,
  register as accountApiClientRegister,
} from '../../../../../util/api/AccountApiClient';
import * as mainActions from '../../../reducers/ducks/legacy/MainDuck';
import * as authActions from '../../../reducers/ducks/legacy/AuthorizationDuck';
import * as profileActions from '../../../reducers/ducks/legacy/ProfileDuck';
import {
  toggleGlobalLoaderQueue,
  updateUser,
  setNavigateTo,
} from '../../../reducers/ducks/legacy/MainDuck';
import { serializeItem } from '../../../../../util/Helpers';
import { getPhonesSaga, getAddressesSaga } from './ProfileSaga';

/**
 * Try to authorize user and get his data in case of success
 */
export function* authorize({ payload }) {
  try {
    yield put(toggleGlobalLoaderQueue({ name: 'authorize', status: true }));
    yield put(authActions.updateLoadingStatus(true));
    const authData = yield call(apiAuthorize, {
      username: payload.username,
      password: payload.password,
    });

    yield put(authActions.setAccessToken(authData.access));
    yield put(authActions.setRefreshToken(authData.refresh));

    const user = yield call(apiGetProfile);
    yield all([call(getPhonesSaga), call(getAddressesSaga)]);
    yield put(authActions.updateSuccessStatus(true));
    yield put(updateUser(user));
    yield put(authActions.updateError(null));
    if (payload && payload?.callback) {
      payload.callback();
    }
    yield put(setNavigateTo(''));
  } catch (error) {
    yield put(authActions.updateSuccessStatus(false));
    yield put(authActions.updateError(serializeItem(error)));
    yield put(authActions.setAccessToken(null));
    yield put(authActions.setRefreshToken(null));
  } finally {
    yield put(toggleGlobalLoaderQueue({ name: 'authorize', status: false }));
    yield put(authActions.updateLoadingStatus(false));
  }
}

/**
 * Try to register user
 */
export function* register({ payload }) {
  try {
    yield put(toggleGlobalLoaderQueue({ name: 'register', status: true }));
    yield put(authActions.updateLoadingStatus(true));

    yield call(accountApiClientRegister, payload);
    const authData = yield call(apiAuthorize, {
      username: payload.email,
      password: payload.password,
    });
    yield put(authActions.setRefreshToken(authData.refresh));
    yield put(authActions.setAccessToken(authData.access));

    const user = yield call(apiGetProfile);
    yield all([call(getAddressesSaga)]);
    yield put(authActions.updateSuccessStatus(true));
    yield put(updateUser(user));
    yield put(authActions.updateError(null));
    if (payload && payload?.callback) {
      payload.callback();
    }
    yield put(setNavigateTo(''));
  } catch (error) {
    yield put(authActions.updateSuccessStatus(false));
    yield put(authActions.updateError(serializeItem(error)));
    yield put(authActions.setAccessToken(null));
    yield put(authActions.setRefreshToken(null));
  } finally {
    yield put(toggleGlobalLoaderQueue({ name: 'register', status: false }));
    yield put(authActions.updateLoadingStatus(false));
  }
}

export function* logoutSaga() {
  try {
    const state = yield select();

    const refreshToken = state.authorization?.refreshToken?.token;

    yield put(authActions.resetAll());
    yield put(profileActions.resetAll());
    yield put(mainActions.resetUser());
    yield call(apiSignOut(refreshToken));
  } catch (error) {
    yield put(authActions.updateError(serializeItem(error)));
  }
}

export function* watchAuthorizationSagas() {
  yield takeLeading(authActions.authorizeUser.type, authorize);
  yield takeLeading(authActions.registerUser.type, register);
  yield takeLeading(authActions.logoutRequest.type, logoutSaga);
}
