import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { countMenuItemPrice } from '../Restaurant';

const TOTAL_COST_NAME = 'Total';

function useCart() {
  const {
    fees,
    checkoutData,
    restaurantFees,
    cartItems,
    menuItems,
    orderType,
    dynamicFeesValues,
  } = useSelector(({ order, restaurant }) => ({
    fees: order.fees,
    checkoutData: order.checkoutData,
    restaurantFees: restaurant?.selectedRestaurantMenu?.fees,
    cartItems: order.cartItems,
    menuItems: restaurant?.selectedRestaurantMenu?.menuItems,
    orderType: order?.type,
    dynamicFeesValues: order.dynamicFeesValues,
  }));

  const subtotalPrice = useMemo(() => {
    let price = 0;
    if (!_.isEmpty(menuItems) && !_.isEmpty(cartItems)) {
      price = cartItems.reduce((acc, cartItem) => {
        const itemPrice = countMenuItemPrice({
          menuItem: menuItems[cartItem.menuItemId],
          selectedOptions: cartItem.answers,
          orderType,
          quantity: cartItem.quantity,
        });

        return acc + itemPrice;
      }, 0);
    }
    return price;
  }, [cartItems, menuItems]);

  const feesList = [];

  feesList.push({
    id: 'orderTotal',
    name: 'Subtotal',
    value: subtotalPrice,
    adjustable: false,
  });
  if (!_.isEmpty(fees)) {
    const target = ['subtotal', 'total', 'flat'];
    const targetIds = restaurantFees?.flatMap((fee) =>
      target.includes(fee.target) ? fee.id : []
    );

    const feesMenu = {};

    restaurantFees?.forEach((fee) => {
      feesMenu[fee.id] = fee;
    });

    const feesFilter = fees.filter(
      (fee) => !_.isNil(targetIds) && targetIds.includes(fee.id)
    );

    feesFilter.forEach((fee) => {
      feesList.push({
        ...fee,
        ...feesMenu[fee.id],
      });
    });
  } else {
    restaurantFees?.forEach((fee) => {
      const newFee = {
        ...fee,
        amount:
          fee.amount ||
          subtotalPrice * ((dynamicFeesValues[fee.id] || fee.percent) / 100),
      };
      if (fee.adjustable === false) {
        feesList.push(newFee);
      }
    });
  }

  if (checkoutData?.taxes) {
    feesList.push({
      id: 'checkoutScreenTaxesValue',
      name: 'Taxes',
      target: 'total',
      value: checkoutData.taxes.reduce((acc, tax) => {
        const taxValue = tax.targets.reduce(
          (subAcc, target) => subAcc + (target.quantity || 1) * target.amount,
          0
        );

        return acc + taxValue;
      }, 0),
      adjustable: false,
      isCheckout: true,
    });
  }

  const removedExtraFeesArray = feesList.filter(
    (fee) => fee.is_delivery_fee === true || fee.adjustable === true
  );

  const sumSubtotalFees = feesList
    .filter((fee) => _.isNumber(fee.id) && fee.target === 'subtotal')
    .reduce((acc, f) => acc + (f.value || f.amount || 0), 0);
  const removedExtraSumSubtotalFees = removedExtraFeesArray
    .filter((fee) => _.isNumber(fee.id) && fee.target === 'subtotal')
    .reduce((acc, f) => acc + (f.value || f.amount || 0), 0);

  const newFeesList = feesList.filter((fee) => {
    if (
      Object.prototype.hasOwnProperty.call(fee, 'max_order_size') ||
      Object.prototype.hasOwnProperty.call(fee, 'min_order_size')
    ) {
      const maxOrderSize = fee.max_order_size || 2 ** 53;
      const minOrderSize = fee.min_order_size || 0;

      if (
        maxOrderSize >= subtotalPrice + sumSubtotalFees &&
        minOrderSize <= subtotalPrice + sumSubtotalFees
      ) {
        return true;
      }

      return false;
    }
    return true;
  });
  const removedExtraNewFeesList = removedExtraFeesArray.filter((fee) => {
    if (
      Object.prototype.hasOwnProperty.call(fee, 'max_order_size') ||
      Object.prototype.hasOwnProperty.call(fee, 'min_order_size')
    ) {
      const maxOrderSize = fee.max_order_size || 2 ** 53;
      const minOrderSize = fee.min_order_size || 0;

      if (
        maxOrderSize >= subtotalPrice + removedExtraSumSubtotalFees &&
        minOrderSize <= subtotalPrice + removedExtraSumSubtotalFees
      ) {
        return true;
      }

      return false;
    }
    return true;
  });

  const subtotalFeesPrice = newFeesList
    .filter((fee) => _.isNumber(fee.id) && !fee.adjustable)
    .reduce((acc, i) => acc + (i.value || i.amount || 0), 0);
  const removedExtraSubtotalFeesPrice = removedExtraNewFeesList
    .filter((fee) => _.isNumber(fee.id) && !fee.adjustable)
    .reduce((acc, i) => acc + (i.value || i.amount || 0), 0);

  const subtotal = cartItems.length ? subtotalPrice + subtotalFeesPrice : 0;
  const removedExtraSubtotal = cartItems.length
    ? subtotalPrice + removedExtraSubtotalFeesPrice
    : 0;

  newFeesList.push({
    id: 'orderScreenTotalValue',
    name: TOTAL_COST_NAME,
    value: subtotal,
    adjustable: false,
    isCheckout: false,
  });
  removedExtraNewFeesList.push({
    id: 'orderScreenTotalValue',
    name: TOTAL_COST_NAME,
    value: removedExtraSubtotal,
    adjustable: false,
    isCheckout: false,
  });

  const totalPrice = subtotalPrice + subtotalFeesPrice;
  const removedExtraTotalPrice = subtotalPrice + removedExtraSubtotalFeesPrice;

  newFeesList.push({
    id: 'cartSummaryLines',
    name: TOTAL_COST_NAME,
    value: totalPrice,
    adjustable: false,
    isCheckout: true,
  });
  removedExtraNewFeesList.push({
    id: 'cartSummaryLines',
    name: TOTAL_COST_NAME,
    value: removedExtraTotalPrice,
    adjustable: false,
    isCheckout: true,
  });

  const cartTotal =
    feesList.find((fee) => fee.id === 'orderScreenTotalValue')?.value || 0;

  return {
    feesArray: newFeesList,
    totalPrice: totalPrice.toFixed(2),
    subtotalPrice: subtotalPrice.toFixed(2),
    cartTotal: cartTotal.toFixed(2),
    removedExtraFeesArray: removedExtraNewFeesList,
  };
}

export default useCart;
