import React from 'react';
import { getConfig } from '../../../AppConfig';

const config = getConfig();

// Zoho Sales IQ widget code
function ZohoSalesIQ() {
  if (!window?.$zoho) {
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: config?.zohoSalesIQTrackingId || 'bogus_zoho_tracking_id',
      values: {},
      ready() {},
    };
    const d = document;
    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zoho.com/widget';
    const t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  return <div id="zsiqwidget" />;
}

export default ZohoSalesIQ;
