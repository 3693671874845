import { createSelector } from 'reselect';

export const selectSeoState = (state: any) => state.seo;

export const selectPageTitle = createSelector(
  selectSeoState,
  (seoState) => seoState.pageTitle
);

export const selectPageDescription = createSelector(
  selectSeoState,
  (seoState) => seoState.pageDescription
);

export const selectPageKeywords = createSelector(
  selectSeoState,
  (seoState) => seoState.pageKeywords
);

export const selectJsonItem = createSelector(
  selectSeoState,
  (seoState) => seoState.jsonItem
);

export const selectCanonicalUrl = createSelector(
  selectSeoState,
  (seoState) => seoState.canonicalURL
);
