import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Image({
  src,
  alt,
  width,
  height,
  className,
  'data-test-id': dataTestId,
  ...rest
}) {
  return (
    <LazyLoadImage
      alt={alt}
      width={width}
      height={height}
      threshold={0.01}
      className={className}
      useIntersectionObserver
      data-test-id={dataTestId}
      src={src ? `${src}?width=${width}&height=${height}` : ''}
      {...rest}
    />
  );
}

Image.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  'data-test-id': PropTypes.string.isRequired,
};

Image.defaultProps = {
  width: 100,
  height: 100,
  className: '',
};

export default Image;
