import { shade } from 'polished';

import theme from '../../layout/Theme';

const styles = () => ({
  form: {
    background: '#FFF',
    padding: 40,
    position: 'relative',
    color: '#000',
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '16px',
    backgroundColor: '#fff !important',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 51,
  },
  headerTitle: {
    marginBottom: '24px',
    fontWeight: theme.font.weight.extrabold,
    fontFamily: theme.font.family.main,
    fontSize: theme.font.size.xl2,
    lineHeight: theme.font.lineHeight.xl2,
    textAlign: 'center',
  },
  input: {
    marginBottom: '16px',
  },
  label: {
    fontWeight: theme.font.weight.semibold,
    fontSize: theme.font.size.xs,
    lineHeight: theme.font.lineHeight.xs,

    color: '#6C6C6C',
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  signUpLinkContainer: {
    textAlign: 'center',
    fontWeight: theme.font.weight.bold,
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
  },
  forgotLink: {
    color: '#6C6C6C',
    textDecoration: 'underline',
    fontWeight: theme.font.weight.medium,
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
  },
  signUpText: {
    color: '#666666',
  },
  signUpLink: {
    color: '#49B782',
    fontSize: theme.font.size.md,
    lineHeight: theme.font.lineHeight.md,
    margin: 0,
    padding: '0px !important',
    outline: 'none',

    '&:hover': {
      color: shade(-0.1, '#49B782'),
    },
  },
});

export default styles;
