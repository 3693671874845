import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { isRestaurantApp } from '../../../../../util/Helpers';
import { getConfig } from '../../../../../AppConfig';

const config = getConfig();

const defaultSelectedRestaurantValue = isRestaurantApp()
  ? config?.profileKey
  : null;

export const INITIAL_STATE = {
  selectedRestaurant: defaultSelectedRestaurantValue as string | null,
  delays: null as any,
  restaurants: null as any,
  onSearchRestaurant: false as boolean,
  restaurantMenuResponse: {} as any,
  restaurantDetailsResponse: {} as any,
  favourites: [] as any[],
  promos: [] as any[],
  deals: [] as any[],
};

const restaurantPageSlice = createSlice({
  name: 'restaurantPage',
  initialState: INITIAL_STATE,
  reducers: {
    reset() {
      return { ...INITIAL_STATE };
    },
    setSelectedRestaurant(state, { payload }) {
      // If in RW mode, ignore it
      return isRestaurantApp()
        ? {
            ...state,
          }
        : {
            ...state,
            selectedRestaurant: payload,
            restaurantMenuResponse: null,
            restaurantDetailsResponse: null,
          };
    },
    clearSelectedRestaurant(state) {
      return {
        ...state,
        selectedRestaurant: null,
        restaurantMenuResponse: null,
        restaurantDetailsResponse: null,
      };
    },
    setRestaurants(state, { payload }) {
      return { ...state, onSearchRestaurant: false, restaurants: payload };
    },
    setFavourites(state, { payload }) {
      return { ...state, favourites: payload };
    },
    setPromos(state, { payload }) {
      return { ...state, promos: payload };
    },
    setDelays(state, { payload }) {
      return { ...state, delays: payload };
    },
    searchRestaurants(state) {
      return {
        ...state,
        onSearchRestaurant: true,
        restaurants: null,
      };
    },
    allRestaurants: (state) => state,
    getRestaurantMenuRequest(state, action) {
      const {
        payload: { selectedRestaurant },
      } = action;
      return {
        ...state,
        restaurantMenuResponse: { restaurantId: selectedRestaurant },
      };
    },
    getRestaurantMenuResponse(state, { payload }) {
      return {
        ...state,
        restaurantMenuResponse: payload,
      };
    },
    getRestaurantDetailsRequest(state) {
      return {
        ...state,
      };
    },
    getRestaurantDetailsResponse(state, { payload }) {
      return {
        ...state,
        restaurantDetailsResponse: payload,
      };
    },
    setFavoriteResponse(state, action) {
      const {
        payload: { id, favorite },
      } = action;

      const selectedRestaurant = _.cloneDeep(state.restaurants[id]);
      selectedRestaurant.user_attributes = {
        ...selectedRestaurant.user_attributes,
        favorite,
      };

      return {
        ...state,
        restaurants: {
          ...state.restaurants,
          [id]: selectedRestaurant,
        },
      };
    },
    setMenuItemFavoriteResponse(state, action) {
      const {
        payload: { id, favorite },
      } = action;

      const existingMenuItem =
        state.restaurantMenuResponse?.response?.menuItems?.[id];
      const existingSort = existingMenuItem?.sort ?? 0; // Default to 0 if undefined

      const updatedMenuItem = {
        ...(existingMenuItem || {}),
        id, // Type assertion
        favorite,
        sort: existingSort, // Ensure sort is of type number
      };

      const updatedMenuItems = {
        ...(state.restaurantMenuResponse?.response?.menuItems || {}),
        [id]: updatedMenuItem,
      };

      return {
        ...state,
        restaurantMenuResponse: {
          ...state.restaurantMenuResponse,
          response: {
            ...state.restaurantMenuResponse.response,
            menuItems: updatedMenuItems,
          },
        },
      };
    },
    searchDeals: (state) => state,
    setDeals(state, { payload }) {
      return { ...state, deals: payload };
    },
  },
});

export const {
  reset,
  setSelectedRestaurant,
  clearSelectedRestaurant,
  setRestaurants,
  setFavourites,
  setPromos,
  setDelays,
  searchRestaurants,
  allRestaurants,
  getRestaurantMenuRequest,
  getRestaurantMenuResponse,
  getRestaurantDetailsRequest,
  getRestaurantDetailsResponse,
  setFavoriteResponse,
  setMenuItemFavoriteResponse,
  searchDeals,
  setDeals,
} = restaurantPageSlice.actions;

export default restaurantPageSlice.reducer;
