import React from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Skeleton, Typography } from '@mui/material';
import Link from '@mui/material/Link';

import Image from '../Image';
import CartPanel from '../CartPanel';
import { getConfig } from '../../../AppConfig';
import { routeNames } from '../../../util/Constants';
import { getImage, getText } from '../../../util/Helpers';
import useClasses from '../../../hooks/legacy/useClasses';
import useDesktopCheck from '../../../util/hooks/UseDesktopCheck';

import { ReactComponent as Twitter } from '../../../assets/images/social/twitter.svg';
import { ReactComponent as Facebook } from '../../../assets/images/social/facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/images/social/instagram.svg';

import styles from './Footer.style';

const config = getConfig();

const logo = getImage(config, 'consumer_logo');

const {
  consumer_toolbar_title: toolbarTitle,
  consumer_footer_food: consumerFooterFood,
  consumer_footer_twitter_link: twitterLink,
  consumer_footer_facebook_link: facebookLink,
  consumer_footer_cities: consumerFooterCities,
  consumer_footer_instagram_link: instagramLink,
  consumer_meta_description: consumerFooterMetaDescription,
} = config.locales?.reduce((acc, locale) => {
  acc[locale.name] = locale.text;

  return acc;
}, {}) ?? {};

const cartPanelShouldAppearIn = [
  routeNames.menu,
  routeNames.restaurantMenu,
  routeNames.restaurantOrder,
];

function Footer() {
  const classes = useClasses(styles);
  const location = useLocation();
  const isDesktop = useDesktopCheck();

  const shouldShowCartPanel =
    !isDesktop &&
    (cartPanelShouldAppearIn.includes(location.pathname) ||
      location.pathname.includes('/restaurant/'));

  function footer() {
    return (
      <>
        <footer className={classes.root}>
          <section className={classes.main}>
            <div className={classes.logoWrapper}>
              <div className={classes.logo}>
                {logo ? (
                  <Image
                    alt="Logo"
                    src={logo}
                    width={32}
                    height={32}
                    data-test-id="menu-header-image"
                  />
                ) : (
                  <Skeleton variant="rectangular" width={32} height={32} />
                )}
                <Typography
                  sx={{ fontWeight: 500 }}
                  variant="body1"
                  data-test-id="default-header-text"
                >
                  {getText(
                    config,
                    'consumer_toolbar_title',
                    config.textsPlaceholder.consumer_toolbar_title
                  )}
                </Typography>
              </div>
              <p className={classes.logoDescription}>
                {consumerFooterMetaDescription}
              </p>
              <div className={classes.social}>
                <Link
                  target="_blank"
                  href={twitterLink}
                  data-test-id="footer-twitter-link"
                >
                  <Twitter />
                </Link>
                <Link
                  target="_blank"
                  href={instagramLink}
                  data-test-id="footer-instagram-link"
                >
                  <Instagram />
                </Link>
                <Link
                  target="_blank"
                  href={facebookLink}
                  data-test-id="footer-facebook-link"
                >
                  <Facebook />
                </Link>
              </div>
            </div>
            <div className={classes.footerData}>
              <div className={classes.navigationWrapper}>
                <nav className={classes.navigation}>
                  <h5>Find food</h5>
                  <ul className={classes.foodNavigation}>
                    {consumerFooterFood?.split(',').map((food) => (
                      <li key={food}>
                        <Link
                          underline="none"
                          href="/restaurants"
                          data-test-id={`footer-food-link-${food}`}
                        >
                          {food}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
                <Divider className={classes.devider} />
              </div>
              <div className={classes.navigationWrapper}>
                <nav className={classes.navigation}>
                  <h5>Areas</h5>
                  <ul className={classes.foodNavigation}>
                    {consumerFooterCities?.split(',').map((city) => (
                      <li key={city}>
                        <Link
                          underline="none"
                          href="/restaurants"
                          data-test-id={`footer-city-link-${city}`}
                        >
                          {city}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
                <Divider className={classes.devider} />
              </div>
            </div>
          </section>
          <Divider className={classes.deviderVertical} />
          <section className={classes.allRightsReserved}>
            <h4>
              v{config.version}:{config.runtimeProvider.toLowerCase()} | ©{' '}
              {new Date().getFullYear()} Eatzy | {toolbarTitle}. All rights
              reserved.
            </h4>
          </section>
        </footer>
        {shouldShowCartPanel && <CartPanel />}
      </>
    );
  }

  return footer();
}

export default Footer;
