import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../../hooks/useUser';

function RoleProtector({ role: userRole, redirect, children }) {
  const { isLoggedIn } = useUser();

  if (userRole && userRole === 'protected') {
    if (!isLoggedIn) {
      if (redirect) {
        return <Navigate to={redirect} />;
      }
      return null;
    }
  }

  return children;
}

RoleProtector.propTypes = {
  children: PropTypes.node,
  role: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  redirect: PropTypes.string,
};

RoleProtector.defaultProps = {
  children: null,
  redirect: '',
};

export default RoleProtector;
