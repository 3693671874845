import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Toolbar } from '@mui/material';

import {
  toggleSignIn,
  toggleCartModal,
  toggleAddress,
} from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import { routeNames } from '../../../util/Constants';
import useTotalCartPrice from '../../../util/hooks/UseCart';
import useSetCart from '../../../hooks/legacy/useSetCart';
import { useUser } from '../../../hooks/useUser';
import CartContent from '../CartContent';

import styles from './CartPanel.style';
import useClasses from '../../../hooks/legacy/useClasses';

function CartPanel() {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const { userData } = useUser();

  const dispatch = useDispatch();
  const { subtotalPrice, feesArray } = useTotalCartPrice();
  const [isPurchaseEnabled, setIsPurchaseEnabled] = useState(false);
  const [onCheckoutStart, setOnCheckoutStart] = useState(false);
  const [checkoutReady] = useSetCart(onCheckoutStart);

  const {
    orderType,
    minimums,
    cartItems,
    updatingCartItems,
    menuItems,
    address,
  } = useSelector(({ order, restaurant, address: selectedAddress }) => ({
    orderType: order?.type,
    minimums: order.minimums,
    cartItems: order.cartItems,
    updatingCartItems: order.updatingCartItems,
    menuItems: restaurant?.selectedRestaurantMenu?.menuItems,
    address: selectedAddress?.selectedAddress,
  }));

  const minOrderPrice = useMemo(
    () => minimums?.[orderType.value] || 0,
    [minimums, orderType]
  );
  const isMinOrderPrice = useMemo(
    () => minOrderPrice <= subtotalPrice,
    [minOrderPrice, subtotalPrice]
  );
  const isDelivery = useMemo(
    () => orderType?.value === 'delivery',
    [orderType]
  );

  useEffect(() => {
    const isPurchase =
      !updatingCartItems.length &&
      isMinOrderPrice &&
      cartItems.length &&
      Object.keys(menuItems || {}).length;
    setIsPurchaseEnabled(isPurchase);
  }, [updatingCartItems, isMinOrderPrice, cartItems, menuItems]);

  useEffect(() => {
    if (checkoutReady) {
      navigate(routeNames.checkout);
    }
  }, [checkoutReady]);

  const onCheckoutStartClick = () => {
    if (isDelivery && !address) {
      dispatch(toggleAddress(true));
    } else if (userData) {
      setOnCheckoutStart(true);
    } else {
      dispatch(toggleSignIn(true));
    }
  };

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        top: 'auto',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        height: '70px',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: 'auto',
          pl: 2,
          pr: 3,
        }}
      >
        <Box>
          <CartContent
            variant="outlined"
            priceArray={feesArray}
            count={cartItems.length}
            cartAction={toggleCartModal}
            cartItems={cartItems}
          />
        </Box>
        <Box>
          <Button
            onClick={onCheckoutStartClick}
            variant="contained"
            data-test-id="checkout-link"
            disabled={!isPurchaseEnabled || onCheckoutStart}
            size="large"
          >
            Go to Checkout
            <span className={classes.checkoutArrow}>
              <FontAwesomeIcon
                icon={faArrowRight}
                className={classes.checkoutArrowIcon}
              />
            </span>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default CartPanel;
