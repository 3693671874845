import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { routeNames } from '../../../util/Constants';
import { changeSearchValue } from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';

function HeaderSearch({ closeSearch }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchValue } = useSelector(({ main }) => ({
    searchValue: main.searchValue,
  }));

  const keyPressed = (ev) => {
    ev.stopPropagation();
    if (ev.key === 'Enter') {
      ev.preventDefault();
      closeSearch();
      navigate(routeNames.restaurants);
    }
  };

  const cleanFilter = () => {
    dispatch(changeSearchValue(''));
  };

  const onChange = (ev) => {
    const newValue = ev.target.value;
    dispatch(changeSearchValue(newValue));
  };

  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        p: '1px 4px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid lightgrey',
      }}
    >
      <IconButton
        data-test-id="input-search-btn"
        sx={{ p: '4px' }}
        aria-label="menu"
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        data-test-id="input-search"
        placeholder="Search restaurant"
        value={searchValue}
        onKeyDown={keyPressed}
        onChange={onChange}
      />
      {searchValue && (
        <IconButton
          data-test-id="input-close-btn"
          onClick={cleanFilter}
          sx={{ p: '4px' }}
          aria-label="close-btn"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
}

HeaderSearch.propTypes = {
  closeSearch: PropTypes.func,
};
HeaderSearch.defaultProps = {
  closeSearch: () => null,
};
export default HeaderSearch;
