import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Skeleton } from '@mui/material';
import Image from '../Image/Image';
import menuLinks from './menuLinks';
import RoleProtector from '../RoleProtector/RoleProtector';
import useClasses from '../../../hooks/legacy/useClasses';

import styles from './RestaurantLeftMenu.style';

function RestaurantLeftMenu({
  open,
  onClose,
  isAuthenticated,
  openSignUp,
  openLogin,
  logo,
}) {
  const classes = useClasses(styles);

  const dispatchLogin = () => {
    onClose();
    openLogin();
  };

  const dispatchSignUp = () => {
    onClose();
    openSignUp();
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      BackdropProps={{
        style: {
          opacity: 0.3,
        },
      }}
      onClose={onClose}
      data-test-id="sliding-menu"
    >
      <Box sx={{ p: 2, width: 300 }}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              {logo ? (
                <Image
                  alt="Logo"
                  width={45}
                  height={45}
                  src={logo}
                  data-test-id="menu-header-image"
                />
              ) : (
                <Skeleton variant="rectangular" width={45} height={45} />
              )}
            </Stack>
            <IconButton
              variant="text"
              data-test-id="close-button"
              onClick={onClose}
              sx={{ color: 'black' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          {!isAuthenticated && (
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                fullWidth
                data-test-id="sign-in-button"
                onClick={dispatchLogin}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <PersonIcon />
                  Log in
                </Stack>
              </Button>
              <Button
                data-test-id="sign-up-button"
                fullWidth
                variant="outlined"
                onClick={dispatchSignUp}
              >
                Sign Up
              </Button>
            </Stack>
          )}
          <Divider flexItem />
          {menuLinks
            .sort((a, b) => a.order - b.order)
            .map((route) => (
              <RoleProtector key={route.name} role={route?.role || 'none'}>
                <NavLink
                  to={route.path}
                  onClick={onClose}
                  data-test-id={`side-menu-${route.name}`}
                  className={(navData) =>
                    navData.isActive ? classes.activeRow : classes.routeRow
                  }
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <FontAwesomeIcon icon={route.icon} />
                    <Typography variant="body1">{route.title}</Typography>
                  </Stack>
                </NavLink>
              </RoleProtector>
            ))}
        </Stack>
      </Box>
    </Drawer>
  );
}

RestaurantLeftMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openLogin: PropTypes.func.isRequired,
  openSignUp: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logo: PropTypes.string,
};

RestaurantLeftMenu.defaultProps = {
  logo: '',
};

export default memo(RestaurantLeftMenu);
