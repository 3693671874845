/* eslint-disable no-nested-ternary */
import { lazy } from 'react';
import { faHome, faUser, faUtensils } from '@fortawesome/free-solid-svg-icons';

import WithSuspense from '../WithSuspense';
import { routeNames } from '../../../util/Constants';
import { isConsumerApp, isRestaurantApp } from '../../../util/Helpers';

const Home = lazy(() => import('../../page/Home'));
const RestaurantHome = lazy(() =>
  import('../../page/RestaurantLanding/RestaurantLanding')
);
const Terms = lazy(() => import('../../page/Terms'));
const Checkout = lazy(() => import('../../page/Checkout'));
const Restaurant = lazy(() => import('../../page/Restaurant'));
const TrackOrder = lazy(() => import('../../page/TrackOrder'));
const Restaurants = lazy(() => import('../../page/Restaurants'));
const NotFoundPage = lazy(() => import('../../page/NotFoundPage'));
const OrderSuccess = lazy(() => import('../../page/OrderSuccess'));
const ResetPassword = lazy(() => import('../../page/ResetPassword'));
const AccountDetails = lazy(() => import('../../page/AccountDetails'));

const isRestaurantWeb = isRestaurantApp();
const isConsumerWeb = isConsumerApp();

const routes = [
  {
    icon: faHome,
    title: 'Home',
    path: routeNames.home,
    component: isRestaurantWeb
      ? WithSuspense(RestaurantHome)
      : WithSuspense(Home),
  },
  {
    icon: faUtensils,
    title: 'Restaurants',
    path: routeNames.restaurants,
    component: isConsumerWeb
      ? WithSuspense(Restaurants)
      : WithSuspense(RestaurantHome),
  },
  {
    path: routeNames.restaurant,
    component: WithSuspense(Restaurant),
  },
  {
    path: routeNames.contact,
    redirect: routeNames.home,
  },
  {
    path: routeNames.aboutus,
    redirect: routeNames.home,
  },
  {
    path: routeNames.restaurantMenu,
    component: isRestaurantWeb
      ? WithSuspense(Restaurant)
      : WithSuspense(NotFoundPage),
  },
  {
    path: routeNames.restaurantOrder,
    component: isRestaurantWeb
      ? WithSuspense(Restaurant)
      : WithSuspense(NotFoundPage),
  },
  {
    path: routeNames.checkout,
    component: WithSuspense(Checkout),
  },
  {
    icon: faUser,
    role: 'protected',
    path: routeNames.accountDetails,
    component: WithSuspense(AccountDetails),
  },
  {
    role: 'protected',
    path: routeNames.trackOrder,
    component: WithSuspense(TrackOrder),
  },
  {
    role: 'protected',
    path: routeNames.orderSuccess,
    component: WithSuspense(OrderSuccess),
  },
  {
    role: 'none',
    path: routeNames.resetPassword,
    component: WithSuspense(ResetPassword),
  },
  {
    role: 'none',
    path: routeNames.termsAndConditions,
    component: WithSuspense(Terms),
  },
  {
    path: routeNames.notfound,
    component: WithSuspense(NotFoundPage),
  },
];

export default routes;
