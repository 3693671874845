import { addDays, addSeconds, differenceInSeconds } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import _ from 'lodash';
import _groupBy from 'lodash/groupBy';

/**
 * This function receives the restaurant schedules, date and timezone
 * and returns -1 as default, 0 if the restaurant is open,
 * #number_of_seconds to the next opening starting from the date
 */
const opensIn = ({ schedules, date = new Date(), timezone }) => {
  if (_.isNil(schedules)) {
    throw new Error('Schedules data is mandatory');
  }

  const now = utcToZonedTime(date, timezone);
  const nowDayOfWeek = now.getDay() === 0 ? 6 : now.getDay() - 1;
  const tomorrowDayOfWeek = nowDayOfWeek === 6 ? 0 : nowDayOfWeek + 1;
  const yesterdayDayOfWeek = nowDayOfWeek === 0 ? 6 : nowDayOfWeek - 1;

  const midnight = new Date(now).setHours(0, 0, 0, 0);
  const nowSecondsAfterMidnight = (now - midnight) / 1000;
  const restaurantHoursToday = schedules?.[nowDayOfWeek].openings;
  const restaurantHoursYesterday = schedules?.[yesterdayDayOfWeek].openings;

  const { open: openedYesterday, close: closedYesterday } =
    restaurantHoursYesterday[restaurantHoursYesterday.length - 1] || {};

  const yesterdayClosesToday =
    openedYesterday > closedYesterday ? closedYesterday : 0;

  // When the restaurant is closed for today (has no schedule) but for yesterday it closes today
  if (
    restaurantHoursToday?.length === 0 &&
    yesterdayClosesToday > 0 &&
    nowSecondsAfterMidnight < yesterdayClosesToday
  ) {
    return 0;
  }

  let openStatus = -1;
  for (let index = 0; index < restaurantHoursToday.length; index++) {
    const opening = restaurantHoursToday[index];

    if (
      nowSecondsAfterMidnight >= opening.open &&
      nowSecondsAfterMidnight < opening.close
    ) {
      openStatus = 0;
      break;
    } else {
      openStatus =
        openStatus > 0
          ? Math.min(openStatus, opening.open - nowSecondsAfterMidnight)
          : opening.open - nowSecondsAfterMidnight;
    }
  }

  function findNearestOpening(dayOfWeek) {
    if (dayOfWeek === nowDayOfWeek) return -1;

    const restaurantHoursNext = schedules?.[dayOfWeek].openings;

    const upcomingOpening = restaurantHoursNext?.find(
      ({ open }) => open >= 0
    )?.open;

    if (upcomingOpening >= 0) {
      const diff =
        dayOfWeek < nowDayOfWeek
          ? 7 - nowDayOfWeek + dayOfWeek
          : dayOfWeek - nowDayOfWeek;

      const upcomingDay = addDays(midnight, diff);
      const upcomingDayWithSeconds = addSeconds(upcomingDay, upcomingOpening);

      return differenceInSeconds(upcomingDayWithSeconds, now);
    }

    const nextDayOfWeek = dayOfWeek === 6 ? 0 : dayOfWeek + 1;

    return findNearestOpening(nextDayOfWeek);
  }

  const willOpenIn =
    openStatus >= 0 ? openStatus : findNearestOpening(tomorrowDayOfWeek);

  return willOpenIn;
};

const formatMedia = (item = {}) => {
  const media = {};

  (item?.media || []).forEach((_item) => {
    media[_item.name] = _item;
  });

  return media;
};

const formatPrices = (item) => {
  const prices = {};

  if (!item.prices) {
    return null;
  }

  item.prices.forEach((i) => {
    prices[i.service] = i.price;
  });

  return prices;
};

const sortTreeItems = (a, b) => {
  if ([a.order, b.order].includes(undefined)) {
    if (![a.sort, b.sort].includes(undefined)) {
      if (a.sort === b.sort) return a.text > b.text ? 1 : -1;
      return a.sort > b.sort ? 1 : -1;
    }
    return a.text > b.text ? 1 : -1;
  }

  if (a.order === b.order) return a.text > b.text ? 1 : -1;

  return a.order > b.order ? 1 : -1;
};

const getTimeParams = (time, noType = false) => {
  if (
    _.isNil(time) ||
    _.isNil(time.value.start) ||
    _.isNil(time.value.end) ||
    time.value.start >= time.value.end ||
    time?.type === 'asap'
  ) {
    if (noType) {
      return {};
    }
    return {
      type: 'asap',
    };
  }

  if (noType) {
    return {
      start: time.value.start,
      end: time.value.end,
    };
  }

  return {
    type: 'target',
    start: time.value.start,
    end: time.value.end,
  };
};

const getFormattedRestaurantMenu = (menu) => {
  const categories = {};
  const menuItems = {};
  const dynamicFeesValues = {};

  if (menu?.categories) {
    menu.categories.forEach((category) => {
      categories[category.id] = {
        ...category,
        media: formatMedia(category),
        items: [],
      };
    });
  }

  if (menu?.items) {
    menu.items.forEach((menuItem) => {
      menuItems[menuItem.id] = {
        ...menuItem,
        prices: formatPrices(menuItem),
      };
      categories[menuItem.category].items.push(menuItem.id);
    });
  }

  Object.values(categories).forEach((category) => {
    categories[category.id] = {
      ...category,
      items: category.items.sort(
        (a, b) => menuItems[a].sort > menuItems[b].sort
      ),
    };
  });

  if (menu?.fees) {
    menu.fees.forEach((fee) => {
      if (fee.adjustable && fee.percent) {
        dynamicFeesValues[fee.id] = fee.percent;
      }
    });
  }

  return { categories, menuItems, dynamicFeesValues };
};

const countMenuItemPrice = ({
  quantity,
  menuItem,
  orderType,
  selectedOptions = [],
}) => {
  if (!menuItem) {
    return 0;
  }

  const grouppedItems = _groupBy(selectedOptions, 'parent');

  let free = [];
  const extrasPrice = Object.values(grouppedItems).reduce((acc, options) => {
    const question = menuItem.questions?.find(
      ({ id }) => id === options[0].parent
    );
    const items = [];

    options.forEach((selectedOption) => {
      const price =
        selectedOption.prices?.find(
          ({ service }) => service === orderType.value
        )?.price || 0;

      items.push(...new Array(selectedOption?.quantity || 1).fill(price));
    });

    const itemsPrice = items
      .filter((i) => i)
      .sort((a, b) => (a > b ? 1 : -1))
      .reduce((acm, i) => acm + i, 0);

    free = items.filter((i) => i).slice(0, question?.num_free || 0);

    return acc + itemsPrice;
  }, 0);

  const menuItemPrice =
    menuItem.prices?.[orderType.value] ?? menuItem.price ?? 0;

  const singleItemPrice =
    menuItemPrice + extrasPrice - free.reduce((acc, i) => acc + i, 0);

  return singleItemPrice * quantity;
};

export {
  opensIn,
  formatMedia,
  formatPrices,
  sortTreeItems,
  getTimeParams,
  countMenuItemPrice,
  getFormattedRestaurantMenu,
};
