const styles = () => ({
  buttonIconColor: {
    backgroundColor: '#E0F2FE',
  },
  personIconColor: {
    color: '#026AA2',
  },
});

export default styles;
