import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { MenuItem, Skeleton, Menu as StyledMenu } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import { Link as ScrollLink } from 'react-scroll';
import Image from '../Image/Image';
import {
  toggleSignIn,
  toggleSignUp,
} from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import SignInForm from '../../input/SignInForm/SignInForm';
import SignUpForm from '../../input/SignUpForm/SignUpForm';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { routeNames } from '../../../util/Constants';
import { getConfig } from '../../../AppConfig';
import * as orderActions from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';

import { useUser } from '../../../hooks/useUser';
import useDesktopCheck from '../../../util/hooks/UseDesktopCheck';
import RestaurantLeftMenu from '../RestaurantLeftMenu';
import styles from './RestaurantNavbar.style';
import useClasses from '../../../hooks/legacy/useClasses';
import { clearSelectedRestaurant } from '../../../redux/legacy/reducers/ducks/legacy/RestaurantsDuck';
import { updatePhones } from '../../../redux/legacy/reducers/ducks/legacy/ProfileDuck';
import { logoutRequest } from '../../../redux/legacy/reducers/ducks/legacy/AuthorizationDuck';
import { useRestaurant } from '../../../hooks/useRestaurant';

const config = getConfig();

function RestaurantNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setSelectedRestaurant } = useRestaurant();
  const isDesktop = useDesktopCheck();
  const classes = useClasses(styles);

  const [signIn, setSignIn] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { isSignInOpen, isSignUpOpen, restaurant } = useSelector(
    ({
      main,
      order,
      address: selectedAddress,
      restaurant: selectedRestaurant,
    }) => ({
      orderType: order.type,
      userAddress: selectedAddress.selectedAddress,
      restaurant: selectedRestaurant.selectedRestaurantData,
      isSignInOpen: main.isSignInOpen,
      isAddressOpen: main.isAddressOpen,
      isSignUpOpen: main.isSignUpOpen,
    })
  );

  const { isLoggedIn } = useUser();

  const signOut = () => {
    dispatch(toggleSignIn(false));
    dispatch(orderActions.setIsCheckout(false));
    dispatch(orderActions.resetOrder());
    dispatch(clearSelectedRestaurant());
    dispatch(updatePhones([]));
    navigate(routeNames.home);
    dispatch(orderActions.setOrderAddress(null));
    dispatch(logoutRequest());
  };

  const handleSignOut = () => {
    signOut();
    setAnchorEl(null);
  };

  const toggleSignInModal = () => dispatch(toggleSignIn());
  const toggleSignUpModal = () => dispatch(toggleSignUp());

  const logo = useMemo(() => {
    let result = [];
    if (config?.images?.length) {
      result = config.images.filter(
        (img) => img.name === 'site_logo' && img.version === 1
      );
    }

    return result?.length && result[0]?.url ? result[0].url : '';
  }, [config.images]);

  useEffect(() => setSignIn(true), [isSignInOpen]);

  useEffect(() => {
    if (config.profileKey && !restaurant) {
      setSelectedRestaurant(config.profileKey);
    }
  }, [config.profileKey]);

  const toggleMenuModal = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <AppBar position="static" elevation={1} color="default">
        <Box sx={{ width: '100%' }}>
          <Toolbar
            sx={{
              justifyContent: 'space-between',
              padding: '8px',
              backgroundColor: 'white',
              height: '72px',
              borderRadius: 0,
            }}
            data-test-id="default-header"
          >
            {isDesktop ? (
              <Stack
                sx={{ mx: 1 }}
                direction="row"
                spacing={1.5}
                justifyContent="center"
                alignItems="center"
              >
                <Link to={routeNames.home}>
                  <Button
                    color="primary"
                    size="medium"
                    variant="text"
                    data-test-id="login-button"
                    aria-hidden="true"
                  >
                    Home
                  </Button>
                </Link>
                <Link to="/menu">
                  <Button
                    color="primary"
                    size="medium"
                    variant="text"
                    data-test-id="login-button"
                    aria-hidden="true"
                  >
                    Menu
                  </Button>
                </Link>
                <ScrollLink to="contact" smooth duration={500}>
                  <Button
                    color="primary"
                    size="medium"
                    variant="text"
                    data-test-id="login-button"
                    aria-hidden="true"
                  >
                    Contact
                  </Button>
                </ScrollLink>
              </Stack>
            ) : (
              <Stack
                sx={{ mx: 1 }}
                direction="row"
                spacing={1.5}
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  onClick={toggleMenuModal}
                  sx={{ color: 'black' }}
                  data-test-id="toggle-menu"
                  aria-hidden="true"
                >
                  <MenuIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <Link to={routeNames.home}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {logo ? (
                      <Image
                        alt="Logo"
                        src={logo}
                        width={45}
                        height={45}
                        data-test-id="default-header-image"
                      />
                    ) : (
                      <Skeleton variant="rectangular" width={45} height={45} />
                    )}
                  </Box>
                </Link>
              </Stack>
            )}

            <Stack
              sx={{ mx: 1 }}
              direction="row"
              spacing={1.5}
              justifyContent="center"
              alignItems="center"
            >
              {!isDesktop && (
                <RestaurantLeftMenu
                  open={menuOpen}
                  onClose={toggleMenuModal}
                  openLogin={toggleSignInModal}
                  openSignUp={toggleSignUpModal}
                  isAuthenticated={isLoggedIn}
                  logo={logo}
                />
              )}
              {isDesktop && !isLoggedIn && (
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={toggleSignInModal}
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  Log in
                </Button>
              )}
              {isDesktop && !isLoggedIn && (
                <Button
                  color="primary"
                  size="medium"
                  variant="text"
                  onClick={toggleSignUpModal}
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  Sign Up
                </Button>
              )}
              <Link to="/menu">
                <Button
                  color="primary"
                  variant="contained"
                  className="btn-noshadow btn-success"
                  size="medium"
                  data-test-id="login-button"
                  aria-hidden="true"
                >
                  Order
                </Button>
              </Link>
              {isLoggedIn && (
                <>
                  <Button
                    data-test-id="person-icon"
                    style={{
                      background: 'transparent',
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: '700',
                    }}
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                  >
                    <IconButton
                      className={classes.buttonIconColor}
                      data-test-id="person-icon-button"
                    >
                      <PersonIcon className={classes.personIconColor} />
                    </IconButton>
                  </Button>
                  <StyledMenu
                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <NavLink
                      data-test-id="header-menue-profile"
                      to="/account-details/account"
                    >
                      <MenuItem
                        onClick={handleClose}
                        data-test-id="go-to-profile"
                      >
                        Go to Profile
                      </MenuItem>
                    </NavLink>
                    <MenuItem
                      data-test-id="profileAction_signOut"
                      onClick={handleSignOut}
                    >
                      Log out
                    </MenuItem>
                  </StyledMenu>
                </>
              )}
            </Stack>
          </Toolbar>
        </Box>
      </AppBar>
      <Dialog open={isSignInOpen}>
        {signIn ? (
          <SignInForm
            onClose={toggleSignInModal}
            forgotPassword={() => setSignIn(false)}
          />
        ) : (
          <ForgotPassword
            onClose={toggleSignInModal}
            backToSignIn={() => setSignIn(true)}
          />
        )}
      </Dialog>
      <Dialog open={isSignUpOpen}>
        <SignUpForm onClose={toggleSignUpModal} />
      </Dialog>
    </>
  );
}

export default RestaurantNavbar;
