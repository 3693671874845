import { routeNames } from '../Constants';
import axios from './Axios';
import { getConfig } from '../../AppConfig';

const path = '/account';
const config = getConfig();

/**
 * Authorize user
 * @param {{
    username: string;
    password: string;
  }} data Request body
 * @returns {Promise<{
    refresh: {
      token: string;
      expires: number;
    };
    access: {
      token: string;
      expires: number;
    };
  }>}
 */
export const authorize = (data) => axios.post(`${path}/authorize`, data);

/**
 * Register user
 * @param {{
  firstname: string;
  lastname: string;
  password: string;
  email: string;
  phone: string;
}} data Request body
* @returns {Promise<object>}
*/
export const register = (data) =>
  axios.post(`${path}/register`, {
    ...data,
    username: data.email,
    source: 'web',
    profile: config.profileKey,
  });

/**
 * Get user profile
 * @returns {Promise<{
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    roles: {string};
  }>}
 */
export const getProfile = () => axios.get(`${path}/profile`);

export const signOut = (refreshToken) =>
  axios.post(
    `${path}/deauthorize`,
    {},
    { headers: { Authorization: `Basic ${refreshToken}` } }
  );

/**
 * @param {{
    oldpassword: string;
    newpassword: string;
    newconfirm: string;
 * }} data
 * @returns {Promise<{
    access: "access token removed";
    auth: "auth token removed";
    message: "password changed";
 *  }>}
 */
export const changePassword = (data) =>
  axios.post(`${path}/profile/changepassword`, data);

const addressPath = '/address';

/**
 * @returns {Promise<Array<{
    id: number;
    address: string;
    city: string;
    state: string;
    zip: number;
    primary: boolean;
    active: boolean;
    latitude: number;
    longitude: number;
    addition: {
      doorcode: string;
      suite: string;
      unit: string;
      apartment: string;
      room: string;
      building: string;
      floor: string;
      company: string;
      subdivision: string;
    }
  }>>}
 */
const getAddresses = () => axios.get(`${path}${addressPath}/all`);

/**
 * @param {{
    data: {
      address: string;
      city: string;
      state: boolean;
      zip: number;
      primary: true,
      latitude: number;
      longitude: number;
      addition: {
        doorcode: string;
        suite: string;
        unit: string;
        apartment: string;
        room: string;
        building: string;
        floor: string;
        company: string;
        subdivision: string;
        notes: string;
      };
    };
* }} data
 * @returns {Promise<{ address: { id: number; }; }>}
 */
const createAddress = (data) =>
  axios.put(`${path}${addressPath}/new`, { data });

/**
 * @param {number} id
 * @param {{
    address: number;
    data: {
      doorcode: string;
      suite: string;
      unit: string;
      apartment: string;
      room: string;
      building: string;
      floor: string;
      company: string;
      subdivision: string;
      notes: string;
    };
* }} data
* @returns {Promise<{ message: "address updated" }>}
*/
const updateAddress = (id, data) =>
  axios.post(`${path}${addressPath}/update`, { address: id, data });

/**
 * @param {number} id
 * @returns {Promise<{ message: "address is primary" }>}
 */
const setPrimaryAddress = (id) =>
  axios.post(`${path}${addressPath}/primary`, { address: id });

/**
 * @param {number} id
 * @returns {Promise<{ message: "address deleted" }>}
 */
const deleteAddress = (id) =>
  axios.delete(`${path}${addressPath}/delete`, {
    data: { address: id },
  });
// deleteAddress(1039)
const phonePath = '/phone';

/**
 * @returns {Promise<Array<{
    id: number;
    number: string;
    primary: boolean;
    active: boolean;
 * }>>}
 */
const getAllPhones = () => axios.get(`${path}${phonePath}/all`);

/**
 * @param {{
    number: string;
    primary: boolean;
 * }} data
 * @returns {Promise<{
    phone: {
      id: number;
    };
 * }>}
 */
const createPhone = (data) => axios.put(`${path}${phonePath}/new`, { data });

/**
 * @param {number} id
 * @returns {Promise<{
    message: "phone is primary"
* }>}
 */
const setPrimaryPhone = (id) =>
  axios.post(`${path}${phonePath}/primary`, { phone: id });

/**
 * @param {number} id
 * @returns {Promise<{
    message: "phone deleted"
  * }>}
 */
const deletePhone = (id) =>
  axios.delete(`${path}${phonePath}/delete`, { data: { phone: id } });

const resetPath = '/reset';

/**
 * @param {string} email
 */
const resetStart = (email) =>
  axios.post(`${path}${resetPath}/start`, {
    email,
    callback: `${window.location.origin}${routeNames.resetPassword}`,
  });

/**
 * @param {{
 *  email: string;
 *  resetCode: string;
 *  password: string;
 * }} data
 */
const resetEnd = ({ email, resetCode, password }) =>
  axios.post(`${path}${resetPath}/end`, {
    email,
    reset_code: resetCode,
    password,
  });

export default {
  authorize,
  register,
  getProfile,
  signOut,
  changePassword,
  address: {
    getAddresses,
    createAddress,
    updateAddress,
    setPrimaryAddress,
    deleteAddress,
  },
  phone: {
    getAllPhones,
    createPhone,
    setPrimaryPhone,
    deletePhone,
  },
  reset: {
    start: resetStart,
    end: resetEnd,
  },
};
