import { filterProperties } from '../Helpers';
import request from './Axios';

import { getConfig } from '../../AppConfig';

const path = '/maps/location';
const config = getConfig();

/**
 * @param {{
    address string;
    city string;
    state string;
    zip string;
    place_id: string;
 * }} params
 * @returns {Promise<{
    number: string;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    formatted_address: string;
    latitude: number;
    longitude: number;
 * }>}
 */
export const getAddressLocation = (params) =>
  request.get(path, {
    params: {
      ...filterProperties(params, [
        'address',
        'city',
        'state',
        'zip',
        'latitude',
        'longitude',
      ]),
      key: config.keys.map,
    },
  });

/**
 * @param {number} lat
 * @param {number} lng
 */
export const getAddressByCoordinates = (lat, lng) => {
  const url = new URL(`${config.googleMapApiUrl}/maps/api/geocode/json`);
  url.search = new URLSearchParams({
    latlng: `${lat},${lng}`,
    key: config.keys.googleAutocomplete,
  }).toString();

  return fetch(url);
};

export default {
  getAddressLocation,
  getAddressByCoordinates,
};
