import _ from 'lodash';

export const locationsDistanceInMiles = (locationA, locationB) => {
  const R = 6371;
  const pi = Math.PI / 180;

  const dLat = (locationB.latitude - locationA.latitude) * pi;
  const dLon = (locationB.longitude - locationA.longitude) * pi;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(locationA.latitude * pi) *
      Math.cos(locationB.latitude * pi) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  return d * 0.62137; // Distance in miles
};

const getLocationsDistance = (lat1, lon1, lat2, lon2) => {
  if (_.isNil(lat1) || _.isNil(lon1) || _.isNil(lat2) || _.isNil(lon2)) {
    return 0;
  }

  return locationsDistanceInMiles(
    { latitude: lat1, longitude: lon1 },
    { latitude: lat2, longitude: lon2 }
  );
};

export default getLocationsDistance;
