import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, Link, Typography } from '@mui/material';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Notification from '../../layout/Notification';
import { routeNames } from '../../../util/Constants';
import {
  toggleSignIn,
  toggleSignUp,
} from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import {
  resetAuthorization,
  registerUser,
  updateError,
} from '../../../redux/legacy/reducers/ducks/legacy/AuthorizationDuck';

import { getText } from '../../../util/Helpers';

import validationErrors from '../../../util/validation/ValidationErrors';
import { getConfig } from '../../../AppConfig';
import styles from './SignUpForm.style';
import useClasses from '../../../hooks/legacy/useClasses';
import errorMessages from '../../../constants/errorMessages';

const phoneRegExp = /^(\d{3})(\d{3})(\d{4})$/;
const phoneRegExpect = /^\(\d{3}\)\s\d{3}-\d{4}/;

const schema = yup.object().shape({
  firstname: yup.string().required(validationErrors.firstnameRequired),
  lastname: yup.string().required(validationErrors.lastnameRequired),
  phone: yup
    .string()
    .required(validationErrors.phoneRequired)
    .matches(phoneRegExpect, validationErrors.phoneInvalid),
  email: yup
    .string()
    .required(validationErrors.emailRequired)
    .email(validationErrors.emailInvalid),
  password: yup
    .string()
    .required(validationErrors.passwordRequired)
    .min(6, validationErrors.passwordTooShort)
    .max(50, validationErrors.passwordTooLong)
    .matches(/^(?!.*[\s])/, validationErrors.passwordInvalid),
});

const config = getConfig();

function SignUpForm({ onClose }) {
  const classes = useClasses(styles);

  const [isAgree, setAgreement] = useState(false);
  const [isAgreeError, setAgreementError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleArgeement = () => {
    setAgreementError(false);
    setAgreement(!isAgree);
  };

  const dispatch = useDispatch();
  const { isSuccess, isLoading, error, isSignUpOpen } = useSelector(
    ({ main, authorization, order: orderObj }) => ({
      isSuccess: authorization.isSuccess,
      isLoading: authorization.isLoading,
      error: authorization.error,
      isSignUpOpen: main.isSignUpOpen,
      order: orderObj,
    })
  );

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (error?.data) {
      const message = error.data?.message;

      if (/username or email exists/i.test(message)) {
        setError('email', { message });
      } else if (/Phone number not valid/i.test(message)) {
        setError('phone', { message });
      } else {
        setError('email', { message });
      }
      dispatch(updateError(null));
    }
  }, [error, dispatch, setError]);

  useEffect(() => {
    if (isSuccess && isSignUpOpen) {
      dispatch(toggleSignUp(false));
      dispatch(resetAuthorization());
    }
  }, [isSuccess, isSignUpOpen, dispatch]);

  const openSignIn = () => {
    dispatch(toggleSignUp(false));
    dispatch(toggleSignIn(true));
  };

  const onSubmit = (data) => {
    if (isLoading) {
      return;
    }

    if (!isAgree) {
      setAgreementError(true);
      return;
    }

    if (data.confirmPassword !== data.password) {
      Notification.error(errorMessages.PASSWORD_NOT_MATCH, {
        toastId: 'passwordError',
      });
    } else {
      const signUpData = {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.phone,
        username: data.username,
      };

      setAgreementError(false);

      dispatch(
        registerUser({
          ...signUpData,
          password: md5(data.password),
        })
      );
    }
  };

  const signUpText = `Sign Up ${getText(
    config,
    'consumer_toolbar_title',
    config.textsPlaceholder.consumer_toolbar_title
  )}`;

  const agreeConditionsText = 'I Agree to the ';

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit(onSubmit)} data-test-id="sign-up-form">
        <IconButton
          size="small"
          onClick={onClose}
          sx={{ position: 'absolute', right: 0, top: 0, margin: '16px' }}
          data-test-id="closeButton"
        >
          <FontAwesomeIcon icon={faTimes} className={classes.closeIcon} />
        </IconButton>

        <Stack spacing={2}>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            {signUpText}
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Controller
              control={control}
              name="firstname"
              render={({
                formState: { errors },
                field: { onChange, value },
              }) => (
                <TextField
                  onChange={onChange}
                  label="First Name"
                  value={value}
                  data-test-id="firstnameWrapper"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.firstname?.message)}
                  helperText={errors.firstname?.message}
                  inputProps={{ 'data-test-id': 'firstnameInput' }}
                />
              )}
            />
            <Controller
              control={control}
              name="lastname"
              render={({
                formState: { errors },
                field: { onChange, value },
              }) => (
                <TextField
                  onChange={onChange}
                  label="Last Name"
                  value={value}
                  className={classes.input}
                  data-test-id="lastnameWrapper"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.lastname?.message)}
                  helperText={errors.lastname?.message}
                  inputProps={{ 'data-test-id': 'lastnameInput' }}
                />
              )}
            />
          </Stack>

          <Controller
            control={control}
            name="phone"
            render={({ formState: { errors }, field: { onChange, value } }) => (
              <TextField
                onChange={(ev) => {
                  const customEvent = { ...ev };
                  // Applying phone mask
                  customEvent.target.value = ev.target.value
                    .replace(phoneRegExp, '($1) $2-$3')
                    .slice(0, 14);
                  onChange(customEvent);
                }}
                value={value}
                className={classes.input}
                placeholder="(999) 999-9999"
                data-test-id="phoneWrapper"
                variant="outlined"
                fullWidth
                error={Boolean(errors.phone?.message)}
                helperText={errors.phone?.message}
                inputProps={{ 'data-test-id': 'phoneInput' }}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ formState: { errors }, field: { onChange, value } }) => (
              <TextField
                label="Email"
                onChange={onChange}
                value={value}
                className={classes.input}
                data-test-id="emailWrapper"
                variant="outlined"
                fullWidth
                error={Boolean(errors.email?.message)}
                helperText={errors.email?.message}
                inputProps={{ 'data-test-id': 'emailInput' }}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            render={({ formState: { errors }, field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                label="Password"
                value={value}
                type={showPassword ? 'text' : 'password'}
                className={classes.input}
                data-test-id="passwordWrapper"
                variant="outlined"
                fullWidth
                error={Boolean(errors.password?.message)}
                helperText={errors.password?.message}
                InputProps={{
                  'data-test-id': 'passwordInput',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClick}
                        edge="end"
                        data-test-id="password-show-icon"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            render={({ formState: { errors }, field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                label="Confirm Password"
                value={value}
                type={showConfirmPassword ? 'text' : 'password'}
                className={classes.input}
                data-test-id="passwordWrapper"
                variant="outlined"
                fullWidth
                error={Boolean(errors.password?.message)}
                helperText={errors.password?.message}
                InputProps={{
                  'data-test-id': 'confirmPasswordInput',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleConfirmPasswordClick}
                        edge="end"
                        data-test-id="confirm-password-show-icon"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <div>
            <FormControlLabel
              data-test-id="agreeCheckbox"
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={isAgree}
                  onChange={toggleArgeement}
                  name="keepSignedIn"
                  size="small"
                  data-test-id="agree-tos-check"
                />
              }
              label={
                <>
                  {agreeConditionsText}
                  <Link
                    data-test-id="agree-conditions-text"
                    underline="always"
                    href={routeNames.termsAndConditions}
                    target="_blank"
                  >
                    Terms and conditions
                  </Link>
                </>
              }
            />
            {isAgreeError && (
              <FormHelperText error>
                You need to accept Terms and Conditions first
              </FormHelperText>
            )}
          </div>

          <Button
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            sx={{ fontSize: 16, height: 64 }}
            data-test-id="submitButton"
          >
            Sign Up
          </Button>

          <Stack
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <span style={{ color: '#666666' }}>Existing user?</span>{' '}
            <Button
              variant="text"
              size="small"
              onClick={openSignIn}
              data-test-id="signInButton"
            >
              Log In
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}

SignUpForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SignUpForm;
