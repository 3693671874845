import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isTokenValid } from './helpers/TokenHelper';

export function useUser() {
  const refreshToken = useSelector(
    (state) => state.authorization?.refreshToken
  );

  const userStoredData = useSelector((state) => state.main?.user);

  const isLoggedIn = useMemo(() => isTokenValid(refreshToken), [refreshToken]);

  const userData = useMemo(() => {
    if (isLoggedIn) {
      return userStoredData;
    }

    return null;
  }, [isLoggedIn, userStoredData]);

  return {
    isLoggedIn,
    userData,
  };
}

export default useUser;
