import theme from '../Theme';

const styles = () => ({
  restaurantBrand: {
    borderRadius: '0px 0px 24px 24px',
    backgroundColor: theme.colors.contrastText,
    width: '200px',
    height: '200px',
  },
  socialButton: {
    borderRadius: '30px',
    width: '45px',
    height: '45px',
    backgroundColor: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

export default styles;
