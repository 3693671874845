import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  accessToken: null,
  refreshToken: null,
  isLoading: false,
  error: null,
  isSuccess: false,
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: { ...INITIAL_STATE },
  reducers: {
    resetAll() {
      return { ...INITIAL_STATE };
    },
    resetAuthorization(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    },
    authorizeUser: (state) => state,
    registerUser: (state) => state,
    setAccessToken(state, { payload }) {
      return {
        ...state,
        accessToken: payload,
      };
    },
    setRefreshToken(state, { payload }) {
      return {
        ...state,
        refreshToken: payload,
      };
    },
    updateLoadingStatus(state, { payload }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    updateError(state, { payload }) {
      return {
        ...state,
        error: payload,
      };
    },
    updateSuccessStatus(state, { payload }) {
      return {
        ...state,
        isSuccess: payload,
      };
    },
    logoutRequest: (state) => state,
    logoutResponse: () => INITIAL_STATE,
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  resetAuthorization,
  authorizeUser,
  registerUser,
  updateLoadingStatus,
  updateError,
  updateSuccessStatus,
  logoutRequest,
  logoutResponse,
  resetAll,
} = authorizationSlice.actions;

export default authorizationSlice.reducer;
