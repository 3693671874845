import { useDispatch, useSelector } from 'react-redux';
import {
  setPageTitle as storePageTitle,
  setPageDescription as storePageDescription,
  setPageKeywords as storePageKeywords,
  setJsonItem as storeJsonItem,
  setCanonicalURL as storeCanonicalURL,
} from '../redux/legacy/reducers/ducks/new/Seo/SeoReducer';
import {
  selectPageTitle,
  selectPageDescription,
  selectPageKeywords,
  selectJsonItem,
  selectCanonicalUrl,
} from '../redux/legacy/reducers/ducks/new/Seo/SeoSelector';

export function useSeo() {
  const dispatch = useDispatch();

  const setPageTitle = (title: string | null) =>
    dispatch(storePageTitle(title));

  const setPageDescription = (description: string | null) =>
    dispatch(storePageDescription(description));

  const setPageKeywords = (description: string | null) =>
    dispatch(storePageKeywords(description));

  const setJsonItem = (item: any) => dispatch(storeJsonItem(item));

  const setCanonicalURL = (url: string) => dispatch(storeCanonicalURL(url));

  const reset = () => {
    dispatch(storePageTitle(null));
    dispatch(storePageDescription(null));
    dispatch(storePageKeywords(null));
    dispatch(storeJsonItem(null));
  };

  const pageTitle = useSelector(selectPageTitle);
  const pageDescription = useSelector(selectPageDescription);
  const pageKeywords = useSelector(selectPageKeywords);
  const jsonItem = useSelector(selectJsonItem);
  const canonicalURL = useSelector(selectCanonicalUrl);

  return {
    pageTitle,
    pageDescription,
    pageKeywords,
    jsonItem,
    canonicalURL,
    setPageTitle,
    setPageDescription,
    setPageKeywords,
    setJsonItem,
    setCanonicalURL,
    reset,
  };
}

export default useSeo;
