import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Portal from '@mui/material/Portal';
import CircularProgress from '@mui/material/CircularProgress';

import styles, { loaderSize } from './Loader.style';
import useClasses from '../../../hooks/legacy/useClasses';

function Loader({ show }) {
  const classes = useClasses(styles);

  if (!show) {
    return null;
  }

  return (
    <Portal>
      <CircularProgress
        className={classes.root}
        size={loaderSize}
        thickness={4.5}
        data-test-id="loader"
      />
    </Portal>
  );
}

Loader.propTypes = {
  show: PropTypes.bool,
};

Loader.defaultProps = {
  show: false,
};

export default memo(Loader);
