import theme from '../Theme';

const styles = () => ({
  cartIconContainer: {
    color: theme.colors.mainText,
    background: theme.colors.lightGray2,
    padding: '6px 10px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.font.size.sm,
    lineHeight: theme.font.lineHeight.sm,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cartIcon: {
    marginTop: '4px',
    marginRight: '10px',
    position: 'relative',
    cursor: 'pointer',
    '& svg': {
      color: theme.colors.darkGray,
    },
    '& div': {
      position: 'absolute',
      width: '14px',
      height: '14px',
      borderRadius: '6px',
      top: '-6px',
      right: '-8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.font.size.xs,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.contrastText,
      backgroundColor: theme.colors.success.main,
    },
  },
  cartPrice: {
    fontFamily: theme.font.family.secondary,
    fontWeight: theme.font.weight.bold,
    userSelect: 'none',
  },
});

export default styles;
