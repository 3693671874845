/* eslint-disable no-console, no-underscore-dangle */
import _ from 'lodash';
import { Base64 } from 'js-base64';

import locales from './mockData/locales.json';

const getRuntimeConfig = () => {
  if (_.isEmpty(window.__runtime_environment)) {
    if (_.isEmpty(process.env.REACT_APP_SERIALIZED_ENV)) {
      return {}; // Dev mode. Will get env values in the .env file
    }
  }

  const base64EncodedRuntime =
    window.__runtime_environment ?? process.env.REACT_APP_SERIALIZED_ENV;

  try {
    const jsonEncodedRuntime = Base64.decode(base64EncodedRuntime);

    if (!_.isEmpty(jsonEncodedRuntime)) {
      const decodedConfig = JSON.parse(jsonEncodedRuntime);
      if (!_.isNil(decodedConfig)) {
        return decodedConfig;
      }
    }

    return {};
  } catch (error) {
    return {};
  }
};

const readConfigVar = (varName, defaultValue) => {
  const runtimeConfig = getRuntimeConfig();

  if (
    !_.isNil(runtimeConfig[varName]) &&
    (!_.isEmpty(runtimeConfig[varName]) || _.isArray(runtimeConfig[varName]))
  ) {
    return runtimeConfig[varName];
  }
  if (
    !_.isNil(process.env[varName]) &&
    (!_.isEmpty(process.env[varName]) || _.isArray(process.env[varName]))
  ) {
    return process.env[varName];
  }

  if (!_.isNil(defaultValue)) {
    return defaultValue;
  }

  throw new Error(`Config key ${varName} not found`);
};

// Possible values: local, dev, staging, production
const getEnvType = () => {
  const forcedEnv = readConfigVar('REACT_APP_FORCE_ENV', 'unknown');
  if (forcedEnv && forcedEnv !== 'unknown') {
    return forcedEnv;
  }

  return readConfigVar('REACT_APP_EATZY_ENVIRONMENT');
};

export const createConfig = () => {
  const consumerSetup =
    readConfigVar('REACT_APP_CONFIG_SCOPE', 'business') === 'business'
      ? JSON.parse(readConfigVar('REACT_APP_CONSUMER_SETUP'))
      : null;
  const reactAppImages = readConfigVar('REACT_APP_IMAGES', []);
  const reactAppLocales = readConfigVar('REACT_APP_LOCALES', []);
  const reactAppDomains =
    readConfigVar('REACT_APP_DOMAINS', '') !== ''
      ? JSON.parse(readConfigVar('REACT_APP_DOMAINS'))
      : null;

  const envImages =
    typeof reactAppImages === 'string'
      ? JSON.parse(reactAppImages)
      : reactAppImages;

  const envLocales =
    typeof reactAppLocales === 'string'
      ? JSON.parse(reactAppLocales)
      : reactAppLocales;

  return {
    apiBaseUrl: readConfigVar('REACT_APP_API_URL'),
    profileKey: readConfigVar('REACT_APP_PROFILE_KEY'),
    bugsnagKey: readConfigVar('REACT_APP_BUGSNAG_API_KEY'),
    gaTrackingId: readConfigVar('REACT_APP_GA_TRACKING_ID'),
    zohoSalesIQTrackingId: readConfigVar(
      'REACT_APP_EATZY_ZOHO_SALESIQ_TRACKING_ID'
    ),
    keys: {
      map: readConfigVar('REACT_APP_MAP_KEY'),
      stripe: readConfigVar('REACT_APP_STRIPE_KEY'),
      googleAutocomplete: readConfigVar('REACT_APP_GOOGLE_AUTOCOMPLETE_KEY'),
    },
    timezone: readConfigVar('REACT_APP_BUSINESS_TIMEZONE'),
    googleMapApiUrl: 'https://maps.googleapis.com',
    images: envImages,
    locales: envLocales,
    domains: reactAppDomains,
    textsPlaceholder: locales,
    version: readConfigVar('REACT_APP_VERSION', '0.0.0'),
    runtimeProvider: readConfigVar('REACT_APP_RUNTIME_PROVIDER', 'lr'),
    hotjarId: readConfigVar('REACT_APP_HOTJAR_ID', '0000000'),
    hotjarSnippetVersion: readConfigVar(
      'REACT_APP_HOTJAR_SNIPPET_VERSION',
      '6'
    ),
    useFakeData: readConfigVar('REACT_APP_API_FAKE_MODE', 'false'),
    consumerSetup,
    zohoCode: readConfigVar('REACT_APP_ZOHO_CODE', ''),
    appType: readConfigVar('REACT_APP_CONFIG_SCOPE', 'business'),
  };
};

export const getConfig = () => {
  let appConfig;
  if (_.isEmpty(window.__cached_app_config)) {
    appConfig = createConfig();
    if (appConfig) {
      window.__cached_app_config = appConfig;
    }
  } else {
    appConfig = window.__cached_app_config;
  }

  return appConfig;
};

export const envTypes = {
  production: 'production',
  staging: 'staging',
  local: 'local',
  test: 'test',
  dev: 'development',
};

/**
 * Check env type
 * @param {string | string[]} env one of envTypes or array of envTypes values
 */
export const isEnvMatch = (env) => {
  const envType = getEnvType();
  const hasMultipleEnvs = envType.split(',').length > 1;
  if (hasMultipleEnvs) {
    return envType.includes(env);
  }

  return envType === env;
};

export default getConfig;
