import _ from 'lodash';

const formatReorderCartForApi = (items, missingItems) => {
  if (!items) {
    return [];
  }
  const formattedItems = items
    .filter((item) => missingItems.indexOf(item.item_id) < 0)
    .map((item, index) => ({
      sort: index + 1,
      quantity: item.quantity,
      menuItemId: item.item_id,
      ...(item.notes && { special_instructions: item.notes }),
      ...(item.options &&
        item.options.length > 0 && {
          answers: item.options.map((option, optionIdx) => ({
            id: optionIdx + 1,
            answer: option.answer_id,
            ...(option.notes && {
              special_instructions: option.notes,
            }),
            ...(option.quantity &&
              option.quantity > 1 && { amount: option.quantity }),
          })),
        }),
    }));
  const grouppedItems = _.groupBy(formattedItems, 'menuItemId');
  const cart = Object.values(grouppedItems).map((groupItems) => {
    const itemMap = groupItems.map(
      ({ menuItemId: _menuItemId, ...rest }) => rest
    );

    return {
      id: groupItems[0].menuItemId,
      items: itemMap,
    };
  });

  return cart;
};

export default formatReorderCartForApi;
