import { put, call, takeLeading } from 'redux-saga/effects';
import Bugsnag from '@bugsnag/js';

import {
  getPhones,
  updatePhones,
  submitPhoneForm,
  getOrdersHistory,
  submitAddressForm,
  deletePhoneRequest,
  updateOrdersHistory,
  deletePhoneResponse,
  getAddressesRequest,
  deleteAddressRequest,
  getAddressesResponse,
  resetPasswordRequest,
  resetPasswordResponse,
  forgotPasswordRequest,
  changePasswordRequest,
  updatePhoneFormStatus,
  deleteAddressResponse,
  forgotPasswordResponse,
  changePasswordResponse,
  updateAddressFormStatus,
  getTrackingOrderRequest,
  getTrackingOrderResponse,
  getAddressCoverageStatusRequest,
  getAddressCoverageStatusResponse,
  setFavoriteRequest,
  setOrderFavoriteResponse,
  setSaveFavoriteError,
  getRatingConfigItemsRequest,
  getRatingConfigItemsResponse,
  setOrderRateRequest,
  setOrderRateResponse,
  updateAddressRequest,
  updateAddressResponse,
} from '../../../reducers/ducks/legacy/ProfileDuck';
import { setSelectedAddress } from '../../../reducers/ducks/new/Address/AddressReducer';
import {
  updateUser,
  toggleGlobalLoaderQueue,
  toggleSignIn,
} from '../../../reducers/ducks/legacy/MainDuck';
import accountApi, {
  changePassword as accountApiChangePassword,
} from '../../../../../util/api/AccountApiClient';
import {
  setAccessToken,
  setRefreshToken,
} from '../../../reducers/ducks/legacy/AuthorizationDuck';
import {
  trackOrder,
  getAddressCoverageStatus,
} from '../../../../../util/api/RestaurantApiClient';
import { serializeItem } from '../../../../../util/Helpers';
import Notification from '../../../../../components/layout/Notification';
import { getAddressLocation } from '../../../../../util/api/MapApiClient';
import {
  getOrdersHistory as consumerApiGetOrdersHistory,
  setFavorite,
  getRateOrderConfig,
  setOrderRate,
} from '../../../../../util/api/ConsumerApiClient';
import {
  setFavoriteResponse,
  setMenuItemFavoriteResponse,
} from '../../../reducers/ducks/legacy/RestaurantsDuck';

export function* getAddressesSaga() {
  try {
    const addresses = yield call(accountApi.address.getAddresses);

    yield put(getAddressesResponse({ response: addresses }));
  } catch (error) {
    Bugsnag.notify(error);

    yield put(getAddressesResponse({ error }));
  }
}

export function* getPhonesSaga() {
  try {
    const phones = yield call(accountApi.phone.getAllPhones);

    yield put(updatePhones(phones));
  } catch (error) {
    yield put(updatePhones([]));
  }
}

export function* changePhoneSaga({ payload }) {
  try {
    yield put(toggleGlobalLoaderQueue({ name: 'changePhone', status: true }));

    yield put(
      updatePhoneFormStatus({
        isLoading: true,
      })
    );
    let message = '';
    if (payload.id && payload.primary) {
      message = 'Phone Number Updated Successfully.';
      yield call(accountApi.phone.setPrimaryPhone, payload.id);
    } else if (!payload.id) {
      message = 'Phone Number added Successfully.';
      yield call(accountApi.phone.createPhone, {
        number: payload.number,
        primary: payload.primary,
      });
    }

    yield put(
      updatePhoneFormStatus({
        isSuccess: true,
      })
    );

    Notification.success(message, { toastId: 'phone-number-added' });
  } catch (error) {
    Bugsnag.notify(error);

    yield put(
      updatePhoneFormStatus({
        error: serializeItem(error),
      })
    );
    const message = error.data?.message;
    if (error.status !== 200 && error.status !== 403) {
      Notification.error(message, { toastId: 'phone-number-creation-error' });
    }
  } finally {
    yield put(toggleGlobalLoaderQueue({ name: 'changePhone', status: false }));
  }
}
export function* deletePhoneSaga({ payload }) {
  try {
    const response = yield call(accountApi.phone.deletePhone, payload);

    yield put(deletePhoneResponse({ response }));
  } catch (error) {
    yield put(
      deletePhoneResponse({
        error,
      })
    );
  }
}

export function* changePasswordSaga({ payload }) {
  try {
    yield put(
      toggleGlobalLoaderQueue({ name: 'changePassword', status: true })
    );

    yield put(
      changePasswordResponse({
        isLoading: true,
      })
    );

    yield call(accountApiChangePassword, payload);

    yield put(updateUser(null));

    yield put(setAccessToken(null));
    yield put(setRefreshToken(null));

    // This should be removed and handled in UI
    yield put(toggleSignIn(true));
  } catch (error) {
    yield put(
      changePasswordResponse({
        error: serializeItem(error),
      })
    );
    const message = error.data?.message;
    if (error.status !== 200) {
      Notification.error(message, { toastId: 'password-error' });
    } else {
      Notification.success('New passwords are different');
    }
    if (message === 'password changed') {
      Notification.success('New passwords are different', {
        toastId: 'password-error',
      });
    }
  } finally {
    yield put(
      toggleGlobalLoaderQueue({ name: 'changePassword', status: false })
    );
  }
}

export function* resetPasswordSaga({ payload }) {
  try {
    const response = yield call(accountApi.reset.end, payload);

    yield put(resetPasswordResponse({ response }));
  } catch (error) {
    yield put(
      resetPasswordResponse({
        error,
      })
    );
  }
}

export function* forgotPasswordSaga({ payload }) {
  try {
    const response = yield call(accountApi.reset.start, payload);

    yield put(forgotPasswordResponse({ response }));
  } catch (error) {
    yield put(
      forgotPasswordResponse({
        error,
      })
    );
  }
}

/**
 * Try to create or update address
 */
export function* submitAddressFormSaga({ payload }) {
  try {
    yield put(
      toggleGlobalLoaderQueue({ name: 'sumbitAddressForm', status: true })
    );

    yield put(
      updateAddressFormStatus({
        isLoading: true,
      })
    );

    const {
      address,
      city,
      state,
      zip,
      primary,
      addition,
      addressToEdit,
      callback = null,
    } = payload;

    const { apartment, building, company, doorcode, notes } = addition || {};

    const additionBody = serializeItem({
      apartment: apartment || undefined,
      building: building || undefined,
      company: company || undefined,
      doorcode: doorcode || undefined,
      notes: notes || undefined,
    });

    const location = yield call(getAddressLocation, {
      address,
      city,
      state,
      zip,
    });
    const addressBody = {
      address,
      city,
      state: location.state,
      zip,
      latitude: location.latitude,
      longitude: location.longitude,
      primary,
    };
    if (Object.keys(additionBody).length) {
      addressBody.addition = additionBody;
    }

    if (addressToEdit?.id) {
      if (Object.keys(additionBody).length > 0) {
        yield call(
          accountApi.address.updateAddress,
          addressToEdit.id,
          additionBody
        );
      }

      if (!addressToEdit.primary && primary) {
        yield call(accountApi.address.setPrimaryAddress, addressToEdit.id);
      }

      addressBody.id = addressToEdit.id;
    } else {
      const response = yield call(
        accountApi.address.createAddress,
        addressBody
      );
      addressBody.id = response.address.id;
    }

    const updatedAddress = {
      entered_address: `${addressBody.address}, ${addressBody.city}, ${addressBody.state} ${addressBody.zip}, USA`,
      address: addressBody.address,
      city: addressBody.city,
      state: addressBody.state,
      zipcode: addressBody.zip,
      latitude: addressBody.latitude,
      longitude: addressBody.longitude,
      primary: addressBody.primary,
      address_id: addressBody.id,
    };

    yield put(setSelectedAddress(updatedAddress));

    yield put(
      updateAddressFormStatus({
        isSuccess: true,
      })
    );
    if (callback) {
      callback(addressBody);
    }
  } catch (error) {
    Bugsnag.notify(error);

    Notification.error(error.data.message, { toastId: 'zip-format-error' });
    yield put(
      updateAddressFormStatus({
        error: serializeItem(error),
      })
    );
  } finally {
    yield put(
      toggleGlobalLoaderQueue({ name: 'sumbitAddressForm', status: false })
    );
  }
}

export function* updateAddressSaga({ payload }) {
  try {
    const response = yield call(
      accountApi.address.updateAddress,
      payload.address,
      payload.data
    );

    yield put(updateAddressResponse({ response }));
  } catch (error) {
    yield put(
      updateAddressResponse({
        error,
      })
    );
  }
}

export function* deleteAddressSaga({ payload }) {
  try {
    const response = yield call(accountApi.address.deleteAddress, payload);

    yield put(deleteAddressResponse({ response }));
  } catch (error) {
    yield put(
      deleteAddressResponse({
        error,
      })
    );
  }
}

export function* getAddressCoverageStatusSaga({
  payload: { latitude, longitude, selectedRestaurant },
}) {
  try {
    const response = yield call(
      getAddressCoverageStatus,
      latitude,
      longitude,
      selectedRestaurant
    );

    yield put(getAddressCoverageStatusResponse({ response }));
  } catch (error) {
    yield put(getAddressCoverageStatusResponse({ error }));
  }
}

export function* getOrdersHistorySaga({ payload } = {}) {
  const {
    callback = () => null,
    page = 1,
    queryData = '',
    statusValue,
  } = payload || {};
  try {
    yield put(
      toggleGlobalLoaderQueue({ name: 'searchOrdersHistory', status: true })
    );

    const { results, total_pages: totalPages } = yield call(
      consumerApiGetOrdersHistory,
      {
        page,
        query: queryData,
        status: statusValue,
      }
    );

    yield put(updateOrdersHistory(results));
    callback(totalPages);
  } catch (error) {
    yield put(updateOrdersHistory([]));
  } finally {
    yield put(
      toggleGlobalLoaderQueue({ name: 'searchOrdersHistory', status: false })
    );
  }
}

export function* getTrackingOrderSaga({ payload }) {
  try {
    const response = yield call(trackOrder, payload);

    yield put(getTrackingOrderResponse({ response }));
  } catch (err) {
    yield put(getTrackingOrderResponse({ error: err?.data?.message }));
  }
}

export function* setFavoriteSaga(action) {
  try {
    const response = yield call(setFavorite, action.payload);
    if (response) {
      const newFavorite = response.favorite;
      if (action.payload.type === 'order') {
        yield put(
          setOrderFavoriteResponse({
            id: action.payload.id,
            favorite: newFavorite,
          })
        );
        yield put(setSaveFavoriteError(''));
      } else if (action.payload.type === 'menu_item') {
        yield put(
          setMenuItemFavoriteResponse({
            id: action.payload.id,
            favorite: newFavorite,
          })
        );
        yield put(setSaveFavoriteError(''));
      } else {
        yield put(
          setFavoriteResponse({
            id: action.payload.id,
            favorite: newFavorite,
          })
        );
        yield put(setSaveFavoriteError(''));
      }
    }
  } catch (error) {
    yield put(
      setSaveFavoriteError(error?.data?.message || 'Failed to set Favorite')
    );
  }
}

export function* getRatingConfigItemsSaga({ payload }) {
  try {
    yield put(
      toggleGlobalLoaderQueue({ name: 'getRatingConfigItems', status: true })
    );
    const response = yield call(getRateOrderConfig, payload);
    yield put(getRatingConfigItemsResponse({ response }));
  } catch (err) {
    yield put(
      getRatingConfigItemsResponse({
        error: err?.data?.message || 'Failed while getting rating items',
      })
    );
  } finally {
    yield put(
      toggleGlobalLoaderQueue({ name: 'getRatingConfigItems', status: false })
    );
  }
}

export function* setOrderRateSaga({ payload }) {
  try {
    yield put(
      toggleGlobalLoaderQueue({ name: 'setOrderRatingValue', status: true })
    );
    const response = yield call(setOrderRate, payload);
    yield put(setOrderRateResponse(response));
  } catch (err) {
    const message = err?.data?.message;
    yield put(
      setOrderRateResponse({
        error: message?.includes('already rated')
          ? 'Order was already rated.'
          : 'Failed to rate order.',
      })
    );
  } finally {
    yield put(
      toggleGlobalLoaderQueue({ name: 'setOrderRatingValue', status: false })
    );
  }
}

export function* watchProfileSagas() {
  yield takeLeading(
    getAddressCoverageStatusRequest.type,
    getAddressCoverageStatusSaga
  );
  yield takeLeading(getPhones.type, getPhonesSaga);
  yield takeLeading(submitPhoneForm.type, changePhoneSaga);
  yield takeLeading(deletePhoneRequest.type, deletePhoneSaga);
  yield takeLeading(getAddressesRequest.type, getAddressesSaga);
  yield takeLeading(getOrdersHistory.type, getOrdersHistorySaga);
  yield takeLeading(deleteAddressRequest.type, deleteAddressSaga);
  yield takeLeading(resetPasswordRequest.type, resetPasswordSaga);
  yield takeLeading(submitAddressForm.type, submitAddressFormSaga);
  yield takeLeading(forgotPasswordRequest.type, forgotPasswordSaga);
  yield takeLeading(changePasswordRequest.type, changePasswordSaga);
  yield takeLeading(getTrackingOrderRequest.type, getTrackingOrderSaga);
  yield takeLeading(setFavoriteRequest.type, setFavoriteSaga);
  yield takeLeading(getRatingConfigItemsRequest.type, getRatingConfigItemsSaga);
  yield takeLeading(setOrderRateRequest.type, setOrderRateSaga);
  yield takeLeading(updateAddressRequest.type, updateAddressSaga);
}
