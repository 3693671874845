import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { toggleGlobalLoaderQueue } from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import { forgotPasswordRequest } from '../../../redux/legacy/reducers/ducks/legacy/ProfileDuck';
import validationErrors from '../../../util/validation/ValidationErrors';
import styles from './ForgotPasswordForm.style';
import useClasses from '../../../hooks/legacy/useClasses';

const schema = yup.object().shape({
  email: yup
    .string()
    .email(validationErrors.emailInvalid)
    .required(validationErrors.emailRequired),
});

function ForgotPassword({ setSuccess }) {
  const classes = useClasses(styles);
  const dispatch = useDispatch();

  const forgotPasswordResponse = useSelector(
    ({ profile }) => profile?.forgotPasswordResponse
  );

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (forgotPasswordResponse?.response) {
      setSuccess(true);
    } else if (forgotPasswordResponse?.error) {
      setError('email', { message: 'Error sending the reset email.' });
    }

    toggleGlobalLoaderQueue({ name: 'forgotPassword', status: false });
  }, [forgotPasswordResponse, dispatch, setError, setSuccess]);

  const onSubmit = (data) => {
    toggleGlobalLoaderQueue({ name: 'forgotPassword', status: true });

    dispatch(forgotPasswordRequest(data.email));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test-id="forgotPasswordForm">
      <Controller
        control={control}
        name="email"
        render={({ formState: { errors }, field: { onChange, value } }) => (
          <TextField
            label="Email"
            onChange={onChange}
            value={value}
            className={classes.input}
            data-test-id="emailWrapper"
            variant="outlined"
            fullWidth
            autoFocus
            error={errors.email?.message}
            helperText={errors.email?.message}
            inputProps={{ 'data-test-id': 'emailInput' }}
          />
        )}
      />

      <Button
        fullWidth
        variant="contained"
        size="large"
        type="submit"
        disabled={isSubmitting}
        data-test-id="forgot-password-submit"
        aria-label="save"
      >
        Reset password
      </Button>
    </form>
  );
}

ForgotPassword.propTypes = {
  setSuccess: PropTypes.func.isRequired,
};

export default ForgotPassword;
