import { createSlice } from '@reduxjs/toolkit';
import { notAHuman } from '../../../../../util/Seo';

// TODO: all dependencies from GetOrderFilterOptions should be removed
import getOrderFilterOptions, {
  ORDER_TYPE_OPTIONS,
} from '../../../../../util/GetOrderFilterOptions';
import { DELIVERY_OPTIONS } from '../../../../../util/Constants';

const helloBot = notAHuman();

const { dateOptions, timeOptions, typeOptions } = getOrderFilterOptions();

const defaultTypeOption = helloBot ? typeOptions[0] : typeOptions[1];
const defaultTypeOptionName = defaultTypeOption.value;
const firstDateOption = helloBot ? dateOptions[1] : dateOptions[0];
const firstTimeOptionIndex = helloBot
  ? Math.round(
      (timeOptions[firstDateOption.key][defaultTypeOptionName].length - 1) *
        0.65
    )
  : 0;

const firstTimeOption = helloBot
  ? timeOptions[firstDateOption.key][defaultTypeOptionName][
      firstTimeOptionIndex
    ]
  : timeOptions[firstDateOption.key][defaultTypeOptionName][
      firstTimeOptionIndex
    ];

export const INITIAL_STATE = {
  cartItems: [] as any[],
  updatingCartItems: [] as any[],
  selectedMenuItem: null as any,
  fees: [] as any[],
  dynamicFeesValues: {} as any,
  minimums: {} as any,
  checkoutData: null as any,
  checkoutAdjustRequest: null as any,
  checkoutAdjustResponse: null as any,
  coupons: [] as any,
  activeCoupon: {} as any,
  cartRestaurant: null as any,
  isTimeSet: false as boolean,
  notes: '' as string,
  phone: '' as string,
  typeOptions: ORDER_TYPE_OPTIONS as any[],
  type: defaultTypeOption as any,
  date: firstDateOption as any,
  time: firstTimeOption as any,
  distance: null as any,
  address: null as any,
  locations: [] as any[],
  menuSearchText: '' as string,
  isCheckout: false as boolean,
  forceRedirectHome: false as boolean,
  existingAddress: null as any,
  newAddress: null as any,
  addressResponse: null as any,
  setCartResponse: null as any,
  cartStatus: false as boolean,
  isPhoneEditing: false as boolean,
  orderPhoneNumber: null as any,
  deliveryOptions: DELIVERY_OPTIONS[0] as any,
  isMinimumOrder: false as boolean,
  deliveryOptionsInstruction: null as any,
  reorder: {
    order: null,
    processing: false,
  },
};

const orderSlice = createSlice({
  name: 'order',
  initialState: INITIAL_STATE,
  reducers: {
    resetOrder(state) {
      return {
        ...state,
        cartItems: [],
        cartRestaurant: null,
        dynamicFeesValues: {},
        checkoutData: null,
        coupons: [],
        activeCoupon: {},
        notes: '',
        phone: '',
      };
    },
    setCartItems(state, { payload }) {
      return { ...state, cartItems: payload || [] };
    },
    addUpdatingCartItems(state, { payload }) {
      return {
        ...state,
        updatingCartItems: [...state.updatingCartItems, payload],
      };
    },
    removeUpdatingCartItems(state, { payload }) {
      const updatingCartItems = [...state.updatingCartItems, payload].filter(
        (i) => i !== payload
      );
      return { ...state, updatingCartItems };
    },
    setSelectedMenuItem(state, { payload }) {
      return { ...state, selectedMenuItem: payload };
    },
    setOrderFees(state, { payload }) {
      return { ...state, fees: payload };
    },
    setDynamicFeesValues(state, { payload }) {
      return { ...state, dynamicFeesValues: payload };
    },
    setOrderMinimums(state, { payload }) {
      return { ...state, minimums: payload };
    },
    setCheckoutData(state, { payload }) {
      return { ...state, checkoutData: payload };
    },
    setCoupons(state, { payload }) {
      return { ...state, coupons: payload };
    },
    setActiveCoupon(state, { payload }) {
      return { ...state, activeCoupon: payload };
    },
    setCartRestaurant(state, { payload }) {
      return { ...state, cartRestaurant: payload };
    },
    setNotes(state, { payload }) {
      return { ...state, notes: payload };
    },
    setDeliveryOptionsInstruction(state, { payload }) {
      return { ...state, deliveryOptionsInstruction: payload };
    },
    setDeliveryOptions(state, { payload }) {
      return { ...state, deliveryOptions: payload };
    },
    setPhone(state, { payload }) {
      return { ...state, phone: payload };
    },
    setOrderType(state, { payload }) {
      return {
        ...state,
        type: payload,
        checkoutData: null,
        coupons: [],
      };
    },
    setOrderDate(state, { payload }) {
      return {
        ...state,
        date: payload,
      };
    },
    setOrderDistance(state, { payload }) {
      return {
        ...state,
        distance: payload,
      };
    },
    setOrderTime(state, { payload }) {
      return {
        ...state,
        time: payload,
      };
    },
    checkTimeSet(state, { payload }) {
      return {
        ...state,
        isTimeSet: payload,
      };
    },

    setOrderAddress(state, { payload }) {
      return {
        ...state,
        address: payload,
      };
    },

    setMenuSearchText(state, { payload }) {
      return {
        ...state,
        menuSearchText: payload,
      };
    },

    setIsCheckout(state, { payload }) {
      return {
        ...state,
        isCheckout: payload,
      };
    },
    setExistingAddress(state, { payload }) {
      return {
        ...state,
        existingAddress: payload,
      };
    },
    setCartResponse(state, { payload }) {
      return {
        ...state,
        setCartResponse: payload,
      };
    },
    setNewAddress(state, { payload }) {
      return {
        ...state,
        newAddress: payload,
      };
    },
    setAddressResponse(state, { payload }) {
      return {
        ...state,
        addressResponse: payload,
      };
    },
    getCart: (state) => state,
    setCart: (state) => state,
    checkoutStart: (state) => state,
    setCheckoutAdjustRequest(state, { payload }) {
      return {
        ...state,
        checkoutAdjustRequest: payload,
        checkoutAdjustResponse: null,
      };
    },
    setCheckoutAdjustResponse(state, { payload }) {
      return {
        ...state,
        checkoutAdjustRequest: null,
        checkoutAdjustResponse: payload,
      };
    },
    checkoutEnd: (state) => state,
    placeOrder: (state) => state,
    getExistingAddress: (state) => state,
    getNewAddressCoordinates: (state) => state,
    getNewAddressResponse: (state) => state,
    createNewAddress: (state) => state,
    createOptionalAddress: (state) => state,
    forceRedirect(state, { payload }) {
      return {
        ...state,
        forceRedirectHome: payload,
      };
    },
    updateCartStatus(state, { payload }) {
      return {
        ...state,
        cartStatus: payload,
      };
    },
    updatePhoneEditing(state, { payload }) {
      return {
        ...state,
        isPhoneEditing: payload,
      };
    },
    createOrderPhoneNumber(state, { payload }) {
      return {
        ...state,
        orderPhoneNumber: payload,
      };
    },
    getLocations: (state) => state,
    addLocation(state, { payload }) {
      const alreadyAddedLocation = state.locations.some(
        (location) =>
          location.latitude === payload.latitude &&
          location.longitude === payload.longitude
      );
      if (alreadyAddedLocation) return state;
      return {
        ...state,
        locations: [...state.locations, payload],
      };
    },
    setMinimumOrder(state, { payload }) {
      return {
        ...state,
        isMinimumOrder: payload,
      };
    },
    reorder: (state) => state,
    checkReorderSchedule: (state, action) => ({
      ...state,
      reorder: {
        ...state.reorder,
        order: action.payload.order,
        processing: true,
      },
    }),
    endReorderProcessing: (state) => ({
      ...state,
      reorder: {
        ...state.reorder,
        processing: false,
      },
    }),
  },
});

export const {
  resetOrder,
  setCartItems,
  addUpdatingCartItems,
  removeUpdatingCartItems,
  setSelectedMenuItem,
  setOrderFees,
  setDynamicFeesValues,
  setOrderMinimums,
  setCheckoutData,
  setCoupons,
  setActiveCoupon,
  setCartRestaurant,
  setNotes,
  setPhone,
  setOrderType,
  setOrderDate,
  setOrderDistance,
  setOrderTime,
  setOrderAddress,
  setMenuSearchText,
  setIsCheckout,
  getCart,
  setCart,
  checkoutStart,
  setCheckoutAdjustRequest,
  setCheckoutAdjustResponse,
  checkoutEnd,
  placeOrder,
  forceRedirect,
  addLocation,
  getExistingAddress,
  getNewAddressCoordinates,
  getNewAddressResponse,
  setAddressResponse,
  setNewAddress,
  setExistingAddress,
  getLocations,
  setCartResponse,
  checkTimeSet,
  updateCartStatus,
  updatePhoneEditing,
  createOrderPhoneNumber,
  createOptionalAddress,
  setDeliveryOptions,
  setMinimumOrder,
  setDeliveryOptionsInstruction,
  reorder,
  checkReorderSchedule,
  endReorderProcessing,
  createNewAddress,
} = orderSlice.actions;

export default orderSlice.reducer;
