import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { Button, Box, IconButton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './ForgotPassword.style';
import useClasses from '../../../hooks/legacy/useClasses';
import ForgotPasswordForm from '../../input/ForgotPasswordForm';

function ForgotPassword({ onClose, backToSignIn }) {
  const classes = useClasses(styles);
  const [emailSent, setEmailSent] = useState(false);
  const tryAgainText = 'Didn\u0027t get the email? ';

  if (emailSent) {
    return (
      <div className={classes.container}>
        <Button
          size="small"
          variant="contained"
          data-test-id="closeButton"
          className={`btn-icon ${classes.closeBtn}`}
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} className={classes.closeIcon} />
        </Button>
        <Typography variant="h5" className={classes.title}>
          Email sent
        </Typography>
        <Typography mb={1}>
          Check your inbox for instructions on how to reset your password.
        </Typography>
        <Stack direction="row" spacing={1} mb={2} alignItems="center">
          <Typography>{tryAgainText}</Typography>
          <Button
            onClick={() => setEmailSent(false)}
            data-test-id="try-again-button"
            color="secondary"
          >
            Try again
          </Button>
        </Stack>

        <Button
          data-test-id="password-close-btn"
          fullWidth
          variant="contained"
          size="large"
          className={`btn-noshadow btn-success ${classes.okBtn}`}
          onClick={onClose}
        >
          OK
        </Button>
        <div className={classes.backLinkContainer}>
          <Button
            data-test-id="password-back-btn"
            className={classes.backLink}
            onClick={backToSignIn}
          >
            Back to sign in
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Box className={classes.container}>
      <IconButton
        size="small"
        variant="contained"
        data-test-id="closeButton"
        className={`btn-icon ${classes.closeBtn}`}
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faTimes} className={classes.closeIcon} />
      </IconButton>

      <Typography variant="h5" className={classes.title}>
        Forgot your password?
      </Typography>
      <Typography className={classes.subtitle}>
        To reset your password, we need to send you an email.
      </Typography>

      <ForgotPasswordForm setSuccess={setEmailSent} />
      <div className={classes.backLinkContainer}>
        <Button
          variant="text"
          onClick={backToSignIn}
          data-test-id="back-to-sign-in-button"
        >
          Back to sign in
        </Button>
      </div>
    </Box>
  );
}

ForgotPassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  backToSignIn: PropTypes.func.isRequired,
};

export default ForgotPassword;
